import { useMemo } from 'react';

import { getMessageList } from 'modules/domain/dialog/selectors';
import { parseDialogId } from 'modules/utils';
import { useUserField } from 'modules/domain/users/hooks';
import { messagesAdapter } from 'modules/domain/dialog/adapter';
import { useRootSelector } from 'modules/hooks/redux';

export const useDialogMessages = (dialogId: string) => {
  const { animatorId } = parseDialogId(dialogId);
  const animatorTimezone = useUserField(animatorId, 'timezone') || 0;

  const messagesDto = useRootSelector(state => getMessageList(state, dialogId));

  const dialogMessages = useMemo(
    () => messagesAdapter(messagesDto, animatorId, animatorTimezone),
    [messagesDto, animatorId, animatorTimezone],
  );

  return dialogMessages;
};
