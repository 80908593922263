import { UserExperimentTag, UserTag } from 'modules/api/dto';

import { User } from './types';

export const namespace = 'user';

export enum UserActions {
  Set = 'user.set',
  SetTags = 'user.set-tags',
  SetExperimentsTags = 'user.set-experiments-tags',
  SetInterests = 'user.set-interests',
  SetLifeStory = 'user.set-life-story',
  SetOnboarding = 'user.set-onboarding',
}

export type UserActionPayload<P> = {
  id: string;
} & P;

export type SetUserPayload = UserActionPayload<User>;
export type SetUserAction = { type: UserActions; payload: SetUserPayload };

export function setUser(user: User): SetUserAction {
  return { type: UserActions.Set, payload: user };
}

export type SetUserTagsPayload = UserActionPayload<{ input: UserTag[] }>;
export type SetUserTagsAction = { type: UserActions; payload: SetUserTagsPayload };

export function setUserTags(id: string, input: UserTag[]): SetUserTagsAction {
  return { type: UserActions.SetTags, payload: { id, input } };
}

export type SetUserExperimentsTagsPayload = UserActionPayload<{ input: UserExperimentTag[] }>;
export type SetUserExperimentsTagsAction = {
  type: UserActions;
  payload: SetUserExperimentsTagsPayload;
};

export function setUserExperimentsTags(
  id: string,
  input: UserExperimentTag[],
): SetUserExperimentsTagsAction {
  return { type: UserActions.SetExperimentsTags, payload: { id, input } };
}

export type SetUserInterestsPayload = UserActionPayload<{ input: string[] }>;
export type SetUserInterestsAction = { type: UserActions; payload: SetUserInterestsPayload };

export function setUserInterests(id: string, input: string[]): SetUserInterestsAction {
  return { type: UserActions.SetInterests, payload: { id, input } };
}

export type SetUserOnboardingPayload = UserActionPayload<{ newStatus: boolean }>;

export function setUserOnboardingStatus(id: string, newStatus: boolean) {
  return {
    type: UserActions.SetOnboarding,
    payload: {
      id,
      newStatus,
    },
  };
}

export type SetUserLifeStoryPayload = UserActionPayload<{ lifeStory: string | null }>;
export type SetUserLifeStoryAction = { type: UserActions; payload: SetUserLifeStoryPayload };

export function setUserLifeStory(id: string, lifeStory: string | null): SetUserLifeStoryAction {
  return { type: UserActions.SetLifeStory, payload: { id, lifeStory } };
}

export const actions = {
  setUser,
  setUserTags,
  setUserExperimentsTags,
  setUserInterests,
  setUserLifeStory,
  setUserOnboardingStatus,
};
