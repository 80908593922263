import { RootState } from 'modules/App/store/root-state';

import { UserMedia, MediaState, DialogMediaState, DialogMedia } from './types';
import { namespace } from './constants';

const getMediaModelState = (state: RootState): MediaState => state[namespace] || {};

const getDialogMediaState = (state: RootState, dialogId: string): Partial<DialogMediaState> =>
  getMediaModelState(state).dialogMedia.byId[dialogId] || {};

export const getUserPhotos = (state: RootState, userId: string): UserMedia[] | undefined => {
  return getMediaModelState(state).userPhotos.byId[userId];
};

export const getDialogAvailableMedia = (state: RootState, dialogId: string): DialogMedia[] => {
  return getDialogMediaState(state, dialogId).available || [];
};

export const getDialogSentMedia = (state: RootState, dialogId: string): DialogMedia[] => {
  return getDialogMediaState(state, dialogId).sent || [];
};

export const getDialogMediaEnabled = (state: RootState, dialogId: string): boolean | undefined => {
  const chatMediaUserState = getDialogMediaState(state, dialogId);

  return !!chatMediaUserState.enabled;
};

export const getDialogMediaVisible = (state: RootState, dialogId: string): boolean => {
  const { enabled, available: list } = getDialogMediaState(state, dialogId);

  return !!(enabled && list?.length);
};
