import { useEffect, useCallback } from 'react';

import { useDictionaryApi } from 'modules/domain/dictionaries/hooks';
import { useOperatorId } from 'modules/domain/auth';
import {
  useOnboarding,
  useUserApi,
  useUserInterestsApi,
  useUserLifeStoryApi,
  useUserTagsApi,
} from 'modules/domain/users/hooks';
import {
  useDialogApi,
  useDialogNotesApi,
  useDialogAnimatorApi,
  useCurrentDialogUsersIds,
} from 'modules/domain/dialog/hooks';
import { useUserMediaApi } from 'modules/domain/media/hooks';
import { UserChatRole } from 'modules/domain/users/types';
import { useOperatorApi } from 'modules/domain/operator/hooks';

type UserRole = UserChatRole | 'operator';

export const useDataPrefetch = () => {
  const operatorId = useOperatorId();
  const { loadAllDictionaries } = useDictionaryApi();
  const { loadDialogNotes } = useDialogNotesApi();
  const { loadAnimatorPreferences, loadAnimatorStoryTold } = useDialogAnimatorApi();
  const { fetchLifeStory } = useUserLifeStoryApi();
  const { fetchDialog } = useDialogApi();
  const { loadUserPhotos } = useUserMediaApi();
  const { fetchUserData } = useUserApi();
  const { fetchUserTags, fetchUserExperimentsTags } = useUserTagsApi();
  const { fetchUserInterests } = useUserInterestsApi();
  const { initializeOnboardingMode } = useOnboarding();
  const { loadOperatorCommissionInfo, loadOperatorInfo } = useOperatorApi();

  const { animatorId, attendeeId } = useCurrentDialogUsersIds();

  const fetchAllUserDataWithNested = useCallback(
    (userId: string, role: UserRole) => {
      return fetchUserData(userId).then(user => {
        if (role === 'animator') {
          fetchLifeStory(userId);
        }
        if (role !== 'operator') {
          fetchUserInterests(userId);
          fetchUserTags(userId);
        }
        fetchUserExperimentsTags(userId);

        return user;
      });
    },
    [fetchLifeStory, fetchUserData, fetchUserExperimentsTags, fetchUserInterests, fetchUserTags],
  );

  useEffect(() => {
    fetchDialog(operatorId);
    loadOperatorInfo(operatorId);
    loadOperatorCommissionInfo(operatorId);
    // TODO: fix this flow. If there's an error there shouldn't be empty operator
    fetchAllUserDataWithNested(operatorId, 'operator').then(operator => {
      initializeOnboardingMode();
      loadAllDictionaries(operator?.language || 'en');
    });
  }, [
    initializeOnboardingMode,
    fetchDialog,
    operatorId,
    fetchAllUserDataWithNested,
    loadAllDictionaries,
    loadOperatorInfo,
    loadOperatorCommissionInfo,
  ]);

  useEffect(() => {
    if (animatorId && attendeeId) {
      fetchAllUserDataWithNested(animatorId, 'animator');
      fetchAllUserDataWithNested(attendeeId, 'attendee');

      loadDialogNotes(animatorId, attendeeId);
      loadAnimatorPreferences(animatorId, attendeeId);
      loadAnimatorStoryTold(animatorId, attendeeId);

      loadUserPhotos(animatorId);
      loadUserPhotos(attendeeId);
    }
  }, [
    animatorId,
    attendeeId,
    loadAnimatorPreferences,
    loadDialogNotes,
    loadAnimatorStoryTold,
    loadUserPhotos,
    fetchAllUserDataWithNested,
  ]);
};
