import { EarningsInfo } from '../model';

import { calculateTotalBonusIncome } from './calculate-total-bonus-income';

export function calculateTotalIncome(earnings: EarningsInfo | undefined) {
  if (!earnings) {
    return 0;
  }

  // After applying the KPI earnings.value sometimes comes from the server not rounded
  const moneyForReplies = Math.floor(earnings.repliesPaymentWithKpi * 100) / 100 || 0;
  const moneyForBonuses = calculateTotalBonusIncome(earnings);
  const penalties = earnings.trapPenalties?.totalBonusValue || 0;

  return moneyForReplies + moneyForBonuses + penalties;
}
