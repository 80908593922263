import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addLogoutReducer } from 'modules/domain/auth/actions';

import { OperatorInfo, OperatorCommissionInfo, OperatorModelState } from './types';

const initialState: OperatorModelState = {
  info: null,
  commission: null,
};

export const operatorModel = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    setOperatorInfo: (state, { payload }: PayloadAction<OperatorInfo>) => {
      state.info = payload;
    },
    setOperatorCommissionInfo: (state, { payload }: PayloadAction<OperatorCommissionInfo>) => {
      state.commission = payload;
    },
  },
  extraReducers: addLogoutReducer(() => initialState),
  selectors: {
    getOperatorInfo: state => state.info,
    getOperatorCommissionInfo: state => state.commission,
  },
});
