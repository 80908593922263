import React, { memo } from 'react';
import Highlighter from 'react-highlight-words';
import classNames from 'classnames';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = {
  content: string;
  highlight?: string;
  isFocus?: boolean;
  a11yLabel?: string;
  outgoing?: boolean;
  error?: boolean;
  className?: string;
};

export const TextMessage = memo((props: Props) => {
  const { content, highlight, isFocus, outgoing, className, a11yLabel, error } = props;
  const testIdPrefix = a11yLabel ? `${a11yLabel}-` : '';

  const messageClassName = classNames(styles.chatTextMessage, className, {
    [styles.outgoing]: outgoing,
    [styles.error]: error,
  });

  return highlight ? (
    <Highlighter
      {...testId(`${testIdPrefix}highlighted-message-text`)}
      className={messageClassName}
      highlightClassName={
        isFocus ? styles.chatTextMessageActiveHighlight : styles.chatTextMessageHighlight
      }
      searchWords={[highlight]}
      textToHighlight={content}
    />
  ) : (
    <div {...testId(`${testIdPrefix}message-text`)} className={messageClassName}>
      {content}
    </div>
  );
});
