import { useEffect, useMemo } from 'react';

import { formatDate, utcDate } from 'modules/utils';
import { GetDialogDaysWithMessagesQueryParams } from 'modules/api/payload';

import { getCalendarStateId } from '../helpers';

import { useDaysWithMessagesState } from './selectors';
import { useCalendarApi } from './use-calendar-api';

export function useDaysWithMessages(dialogId: string) {
  const { loadDialogDaysWithMessages } = useCalendarApi();

  const requestParams = useMemo<GetDialogDaysWithMessagesQueryParams>(
    () => ({
      dateFrom: formatDate(utcDate().add(-1, 'year')),
      dateTo: formatDate(utcDate().add(1, 'day')),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialogId],
  );

  const daysWithMessagesState = useDaysWithMessagesState(
    getCalendarStateId(dialogId, requestParams),
  );

  useEffect(() => {
    if (!daysWithMessagesState) {
      loadDialogDaysWithMessages(dialogId, requestParams);
    }
  }, [dialogId, requestParams, daysWithMessagesState, loadDialogDaysWithMessages]);

  const daysWithMessages = useMemo(() => daysWithMessagesState || [], [daysWithMessagesState]);

  return daysWithMessages;
}
