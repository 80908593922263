import { IHttpTransport } from 'modules/api/transport';
import { chatLoHiId } from 'modules/api/utils';

import { IDialogsNotesDomain, PatchDialogNotesPayload } from './contracts';
import { DialogNotesDto, DialogNotesOldDto } from './dto';

export class DialogsNotesDomain implements IDialogsNotesDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getDialogNotesOld(animatorId: string, attendeeId: string): Promise<DialogNotesOldDto> {
    const loHiIds = chatLoHiId(animatorId, attendeeId);
    return this.transport
      .get<DialogNotesOldDto>(`multichat/notes/${loHiIds.lower}:${loHiIds.higher}`)
      .then(response => response.data);
  }

  patchDialogNotesOld(
    animatorId: string,
    attendeeId: string,
    payload: DialogNotesOldDto,
  ): Promise<void> {
    const loHiIds = chatLoHiId(animatorId, attendeeId);
    return this.transport
      .patch<void>(`multichat/notes/${loHiIds.lower}:${loHiIds.higher}`, payload)
      .then(response => response.data);
  }

  getDialogNotes(animatorId: string, attendeeId: string): Promise<DialogNotesDto> {
    return (
      this.transport
        // be aware that we strictly set url as {attendeeId}:{animatorId} and not {lowerId}:{higherId}
        .get<DialogNotesDto>(`/v3/multichat/notes/${attendeeId}:${animatorId}`)
        .then(response => response.data)
    );
  }

  patchDialogNotes(
    animatorId: string,
    attendeeId: string,
    payload: PatchDialogNotesPayload,
  ): Promise<void> {
    const loHiIds = chatLoHiId(animatorId, attendeeId);
    return this.transport
      .patch<void>(`/v2/multichat/notes/${loHiIds.lower}:${loHiIds.higher}`, payload)
      .then(response => response.data);
  }
}
