import { useCallback } from 'react';

import { useDialogMessagesApi } from 'modules/domain/dialog/hooks';
import { useModal } from 'modules/domain/modals/hooks';
import { parseDialogId } from 'modules/utils';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { useMediaAnalytics } from 'modules/domain/media/hooks';

export const useSendDialogMedia = (dialogId: string) => {
  const { sendPhotoMessage, messageSending } = useDialogMessagesApi(dialogId);
  const { trackAllLibraryMediaSent } = useMediaAnalytics();
  const { closeModal } = useModal();

  const sendDialogMedia = useCallback(
    async (baseUrl: string, lastMediaLength: number) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      try {
        await sendPhotoMessage(baseUrl);

        closeModal();

        if (lastMediaLength === 1) {
          trackAllLibraryMediaSent(animatorId, attendeeId, baseUrl);
        }
      } catch (error) {
        // the error is handled inside the sendPhotoMessage
        showChatErrorToast(
          'Failed to send a photo. Please check your connection and try again later',
        );
      }
    },
    [sendPhotoMessage, trackAllLibraryMediaSent, dialogId, closeModal],
  );

  return { sendDialogMedia, messageSending };
};
