import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Dialog } from 'modules/domain/dialog/types';

import { resetDialogGiftsState, setDialogGifts } from '../model';
import { checkGiftsAllowed } from '../helpers';

import { useGiftsApi } from './use-gifts-api';
import { useGiftsPriceLimitPoller } from './use-gifts-price-limit-poller';
import { useGiftsPriceLimitChecker } from './use-gifts-price-limit-checker';

export const useGiftsLoader = (currentDialog: Dialog) => {
  const { id: dialogId, attendeeId } = currentDialog;

  const giftsAllowed = checkGiftsAllowed(currentDialog);

  const dispatch = useDispatch();
  const { getGifts } = useGiftsApi(dialogId);

  useEffect(
    () => () => {
      dispatch(resetDialogGiftsState({ dialogId }));
    },
    [dispatch, dialogId],
  );

  useEffect(() => {
    if (!giftsAllowed) {
      return;
    }

    getGifts(attendeeId).then(gifts => {
      dispatch(setDialogGifts({ dialogId, gifts }));
    });
  }, [dispatch, getGifts, dialogId, attendeeId, giftsAllowed]);

  const checkPriceLimit = useGiftsPriceLimitChecker(dialogId);

  // We need to monitor actual gifts price limit to hide gifts when limit is reached
  useGiftsPriceLimitPoller(checkPriceLimit, giftsAllowed);
};
