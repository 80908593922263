import { useUserData } from 'modules/domain/users/hooks';
import { THUMBNAIL_IMAGE_SIZE } from 'modules/constants';

import { useGetUserPhotoUrl } from './use-get-user-photo-url';
import { useUserFallbackImage } from './use-user-fallback-image';

export const useUserThumbnailUrl = (userId: string) => {
  const getUserPhotoUrl = useGetUserPhotoUrl(userId);
  const { thumbnail, name } = useUserData(userId) || {};
  const fallback = useUserFallbackImage(userId);

  const url = thumbnail
    ? getUserPhotoUrl({ baseUrl: thumbnail, size: THUMBNAIL_IMAGE_SIZE })
    : null;

  return {
    url,
    fallback,
    alt: `${name}-thumbnail`,
  };
};
