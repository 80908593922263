import React, { memo } from 'react';

import { AppRoutes } from 'modules/navigation';
import { HeaderScale } from 'modules/components/header-scale';
import { calculateTotalIncome, getKPIEffect } from 'modules/domain/earnings/utils';
import { DEFAULT_DATES_RANGE } from 'modules/domain/earnings/constants';
import { useEarningsInfo, useEarningsPoller } from 'modules/domain/earnings/hooks';

import { IncomeValue } from './components/income-value';
import { KPIValue } from './components/kpi-value';

export const EarningsBadges = memo(() => {
  useEarningsPoller(DEFAULT_DATES_RANGE);

  const earnings = useEarningsInfo(DEFAULT_DATES_RANGE);

  const totalIncome = calculateTotalIncome(earnings);
  const kpiEffect = getKPIEffect(earnings);

  const loading = !earnings;

  return (
    <>
      <HeaderScale
        caption="Total income"
        url={AppRoutes.Income}
        loading={loading}
        testIdPostfix="income-scale-button"
        value={<IncomeValue value={totalIncome} />}
      />
      <HeaderScale
        caption="KPI Effect"
        url={AppRoutes.Kpi}
        value={<KPIValue value={kpiEffect} />}
        loading={loading}
        testIdPostfix="kpi-scale-button"
      />
    </>
  );
});
