export function getDialogId(animatorId: string, attendeeId: string) {
  return `${animatorId}:${attendeeId}`;
}

type Ids = {
  animatorId: string;
  attendeeId: string;
};

export function parseDialogId(id: string): Ids {
  const keys = id?.split(':') || [];

  if (!keys[0] || !keys[1]) {
    return {
      attendeeId: '',
      animatorId: '',
    };
  }

  return {
    attendeeId: keys[1],
    animatorId: keys[0],
  };
}
