import React from 'react';
import classNames from 'classnames';

import { testId } from 'modules/utils';

import optionStyles from '../option/styles.module.scss';

import styles from './styles.module.scss';

export const OptionSkeleton = () => (
  <div
    className={classNames(optionStyles.option, styles.optionSkeleton)}
    {...testId('copilot-option-skeleton')}
  />
);
