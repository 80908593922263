import { useMemo } from 'react';

import { useGetUserPhotoUrl } from 'modules/hooks';
import { useUserPhotos } from 'modules/domain/media/hooks';
import { OVERLAY_IMAGE_SIZE } from 'modules/constants';
import { useUserField } from 'modules/domain/users/hooks';

export const useUserPhotosOverlays = (userId: string) => {
  const getUserPhotoUrl = useGetUserPhotoUrl(userId);

  const userThumbnail = useUserField(userId, 'thumbnail');
  const userPhotos = useUserPhotos(userId);

  return useMemo(() => {
    if (!userPhotos?.length) {
      return [];
    }

    const urls: string[] = [];
    let avatarFullSizeUrl: string | undefined;

    userPhotos.forEach(photo => {
      const url = getUserPhotoUrl({ baseUrl: photo.basename, size: OVERLAY_IMAGE_SIZE });

      if (photo.basename === userThumbnail) {
        avatarFullSizeUrl = url;
      } else {
        urls.push(url);
      }
    });

    if (avatarFullSizeUrl) {
      urls.unshift(avatarFullSizeUrl);
    }

    return urls;
  }, [getUserPhotoUrl, userPhotos, userThumbnail]);
};
