import { useMemo } from 'react';

import { DialogMessage } from 'modules/domain/dialog/types';
import { useChatScrollerContext } from 'modules/components/chat/chat-scroller-context';

export const useFirstInvisibleMessageTimestamp = (messages: DialogMessage[]) => {
  const { firstInvisibleMessageId } = useChatScrollerContext();

  const firstInvisibleMessageTimestamp = useMemo(() => {
    const firstInvisibleMessage: DialogMessage | undefined = messages.find(
      message => message.id === firstInvisibleMessageId,
    );
    return firstInvisibleMessage?.timestamp || null;
  }, [messages, firstInvisibleMessageId]);

  return firstInvisibleMessageTimestamp;
};
