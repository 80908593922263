import { useEffect, useState } from 'react';

import { useCopilotState } from 'modules/domain/dialog/hooks';
import { AutoGeneratedAnswersUsage } from 'modules/api/dto';

export const useCopilotOptionClicked = (message: string) => {
  const [optionClicked, setOptionClicked] = useState(false);

  const { usage: copilotUsage } = useCopilotState();

  useEffect(() => {
    if (copilotUsage === AutoGeneratedAnswersUsage.Used) {
      setOptionClicked(true);
    }
  }, [
    copilotUsage,
    message, // we need to recheck optionClickedState on message change
  ]);

  useEffect(() => {
    if (optionClicked) {
      setOptionClicked(false);
    }
  }, [optionClicked]);

  return optionClicked;
};
