import React, { memo } from 'react';

import { useDialogsMetrics } from 'modules/domain/dialog/hooks';

import { ExtendedTimerView } from './view';

export const ExtendedTimer = memo(() => {
  const { unansweredCount } = useDialogsMetrics();

  const queueIsEmpty = !unansweredCount;

  if (queueIsEmpty) {
    return null;
  }

  return <ExtendedTimerView />;
});
