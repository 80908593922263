import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { ISpotifyDomain } from './contracts';
import { SpotifyMatchScoreDto, SpotifyTopContentDto, SpotifyTopContentResponseDto } from './dto';

export class SpotifyDomain implements ISpotifyDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getMatchScore(animatorId: string, attendeeId: string): Promise<SpotifyMatchScoreDto> {
    return this.transport
      .get<SpotifyMatchScoreDto>(`/exo/phyllo/${animatorId}/${attendeeId}/matchscore/spotify`)
      .then(response => response.data);
  }

  getTopContent(attendeeId: string): Promise<SpotifyTopContentDto> {
    return this.transport
      .get<SpotifyTopContentResponseDto>(`/exo/phyllo/top-content/${attendeeId}/spotify`)
      .then(response => response.data.top);
  }
}
