import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

import { DialogNotesList, DialogNotesTopic } from 'modules/domain/dialog/types';
import { generateDialogNoteValueId } from 'modules/domain/dialog/adapter';

import { CustomNotesList, makeCustomNotes, clearNotesBeforeSave } from './utils';
import { topicsWithTitlesList } from './constants';
import { NoteValue } from './note-value';
import styles from './index.module.scss';

type Props = {
  notes: DialogNotesList;
  onSave: (values: DialogNotesList) => void;
  maxNotesInTopic: number;
};

export default memo((props: Props) => {
  const { notes: notesProp, onSave, maxNotesInTopic } = props;

  const customNotes = useMemo(() => makeCustomNotes(notesProp), [notesProp]);

  const [notes, setNotes] = useState<CustomNotesList>(customNotes);

  useEffect(() => {
    setNotes(customNotes);
  }, [customNotes]);

  // we don't need to clear the timeout on unmount to make sure the notes will be saved anyway
  const saveTimeout = useRef<number | undefined>();

  const saveNotes = (notesToSave: CustomNotesList) => {
    clearTimeout(saveTimeout.current);

    // We use timeout here to guarantee that we save the data
    // only when the last value element is blurred.
    // If there's a new element added (e.g Enter pressed or topic title was clicked)
    // the timeout resets
    saveTimeout.current = window.setTimeout(() => {
      onSave(clearNotesBeforeSave(notesToSave));
    }, 100);
  };

  const addEmptyValue = (topic: DialogNotesTopic) => {
    if (notes[topic].length >= maxNotesInTopic) {
      return;
    }

    setNotes(prevNotes => ({
      ...prevNotes,
      [topic]: prevNotes[topic].concat({
        id: generateDialogNoteValueId(topic, prevNotes[topic].length + 1),
        text: '',
        isAutoChanged: false,
        justAdded: true,
      }),
    }));
  };

  const handleValueEnterPress = (topic: DialogNotesTopic) => (text: string) => {
    const trimmedText = text.trim();

    if (!trimmedText) {
      return;
    }

    addEmptyValue(topic);
  };

  const handleValueBlur = (topic: DialogNotesTopic, id: string) => (text: string) => {
    const trimmedText = text.trim();

    setNotes(prevNotes => {
      const newNotes = {
        ...prevNotes,
        [topic]: trimmedText
          ? prevNotes[topic].map(value =>
              value.id === id ? { ...value, text: trimmedText, isAutoChanged: false } : value,
            )
          : prevNotes[topic].filter(value => value.id !== id),
      };

      saveNotes(newNotes);

      return newNotes;
    });
  };

  const handleValueInitialFocus = (topic: DialogNotesTopic, id: string) => () => {
    // Reset the save timeout if the new value was just added
    clearTimeout(saveTimeout.current);

    setNotes(prevNotes => ({
      ...prevNotes,
      [topic]: prevNotes[topic].map(value =>
        value.id === id ? { ...value, justAdded: false } : value,
      ),
    }));
  };

  const onSectionHeaderClick = (topic: DialogNotesTopic) => () => {
    addEmptyValue(topic);
  };

  return (
    <div className={styles.container}>
      {topicsWithTitlesList.map(([topic, title]) => (
        <React.Fragment key={topic}>
          <div className={styles.editableTitle} onClick={onSectionHeaderClick(topic)}>
            {title}
          </div>
          <div className={styles.editableContainer} data-id={topic} tabIndex={0}>
            {notes[topic].map(({ id, text, isAutoChanged, justAdded }, index) => (
              <NoteValue
                // dataId is needed for QA. Form of the value is important
                dataId={`${topic}-${index}`}
                key={id}
                onInitialFocus={handleValueInitialFocus(topic, id)}
                onEnterPress={handleValueEnterPress(topic)}
                onBlur={handleValueBlur(topic, id)}
                text={text}
                isAutoChanged={isAutoChanged}
                justAdded={justAdded}
              />
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
});
