import { DialogNotesTopic } from 'modules/domain/dialog/types';

const topicsToTitlesMap: Record<DialogNotesTopic, string> = {
  currentNews: 'Current News:',
  previousRelationship: 'Previous Relationship:',
  nicknames: 'Nicknames:',
  favTopics: 'Fav Topics:',
  triggers: 'Triggers:',
  work: 'Work:',
  family: 'Family:',
  importantDates: 'Important Dates:',
  habitsLifestyle: 'Habits & Lifestyle:',
  hobbiesInterests: 'Hobbies & Interests:',
  travel: 'Travel:',
  sexting: 'Sexting:',
  other: 'Other:',
};

export const topicsWithTitlesList = Object.entries(topicsToTitlesMap) as [
  DialogNotesTopic,
  string,
][];
