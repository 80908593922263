import { useCallback, useRef } from 'react';

import { ChatScroller } from '../context';

import { useChatScrollHandler } from './use-chat-scroll-handler';
import { useScrollToMessage } from './use-scroll-to-message';
import { useFirstInvisibleMessageId } from './use-first-invisible-message-id';
import { useMessagesRefs } from './use-messages-refs';
import { useChatScrollContainer } from './use-chat-scroll-container';

/**
 * If you want to use the chat scroller, use chat scroller context instead. This hook is used for context setup only
 */
export const useChatScroller = (): ChatScroller => {
  const scrollToMessageActive = useRef(false);

  const handleScrollToMessageStateChange = useCallback((active: boolean) => {
    scrollToMessageActive.current = active;
  }, []);

  const { messagesRefs, setMessageRef } = useMessagesRefs();

  const scrollToMessage = useScrollToMessage(messagesRefs, handleScrollToMessageStateChange);

  const { firstInvisibleMessageId, calculateFirstInvisibleMessageId } =
    useFirstInvisibleMessageId(messagesRefs);
  const { scrollPosition, scrollHandler } = useChatScrollHandler();

  const handleScroll = useCallback(
    (e: Event) => {
      scrollHandler(e);
      calculateFirstInvisibleMessageId();
    },
    [calculateFirstInvisibleMessageId, scrollHandler],
  );

  const {
    scrollToBottom: scrollToBottomBase,
    setScrollContainerRef,
    setScrollContentRef,
  } = useChatScrollContainer(handleScroll);

  const scrollToBottom = useCallback(() => {
    if (scrollToMessageActive.current) {
      return;
    }
    scrollToBottomBase();
  }, [scrollToBottomBase]);

  return {
    // state
    firstInvisibleMessageId,
    scrollPosition,
    // actions
    scrollToBottom,
    scrollToMessage,
    // setters
    setScrollContainerRef,
    setScrollContentRef,
    setMessageRef,
  };
};
