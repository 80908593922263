import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { IAnimatorsDomain } from './contracts';
import { AnimatorPreferencesDto } from './dto';

export class AnimatorsDomain implements IAnimatorsDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getPreferences(animatorId: string): Promise<AnimatorPreferencesDto> {
    return this.transport
      .get<AnimatorPreferencesDto>(`/multichat/animators/${animatorId}/preferences`)
      .then(response => response.data);
  }
}
