import React, { memo, useMemo } from 'react';
import classNames from 'classnames';

import { ModalModel } from 'modules/domain/modals';
import { ModalNames } from 'modules/domain/modals/types';
import { FastAnswersModal } from 'modules/components/fast-answers/modal';
import { SendComplaintModal } from 'modules/components/dialogs/send-complaint';
import { MediaSelectorModal } from 'modules/components/chat/media-selector/modal';
import { WebcamMediaModal } from 'modules/components/chat/media-selector/webcam-media-modal';
import { AudioSelectorModal } from 'modules/components/chat/audio/selector/modal';
import { DialogSearchModal } from 'modules/components/dialog-search-modal';
import { CalendarModal } from 'modules/components/calendar-modal';
import { useClickOutside } from 'modules/domain/common/hooks';

import { CloseButton } from './close-button';
import styles from './index.module.scss';

type ModalData = {
  component: React.ComponentType;
  className?: string;
};

const modalNameToDataMap: Record<ModalNames, ModalData> = {
  [ModalNames.FastAnswers]: { component: FastAnswersModal, className: styles.containerExtended },
  [ModalNames.SendComplaint]: { component: SendComplaintModal },
  [ModalNames.MediaSelector]: {
    component: MediaSelectorModal,
    className: styles.containerEmptySize,
  },
  [ModalNames.WebcamMedia]: {
    component: WebcamMediaModal,
    className: styles.containerWebcamExtended,
  },
  [ModalNames.DialogSearch]: { component: DialogSearchModal, className: styles.containerSearch },
  [ModalNames.Calendar]: { component: CalendarModal },
  [ModalNames.AudioSelector]: {
    component: AudioSelectorModal,
    className: styles.containerAudioSelector,
  },
};

export const Modal = memo(() => {
  const { visibilityStatus, activeModalName } = ModalModel.hooks.useSelectors();
  const { closeModal } = ModalModel.hooks.useModal();

  const wrapperRef = useClickOutside<HTMLDivElement>({
    condition: visibilityStatus,
    action: () => closeModal(),
  });

  const { component: ActiveModal, className: containerClassName } = useMemo<ModalData>(
    () => (activeModalName ? modalNameToDataMap[activeModalName] : { component: () => null }),
    [activeModalName],
  );

  const closeButtonEnabled = activeModalName !== ModalNames.WebcamMedia;

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperVisible]: visibilityStatus,
      })}
    >
      <div className={styles.overlay} />
      <div className={classNames(styles.container, containerClassName)} ref={wrapperRef}>
        {closeButtonEnabled && (
          <CloseButton
            a11yLabel={
              typeof activeModalName === 'number' ? ModalNames[activeModalName] : undefined
            }
            onClick={closeModal}
          />
        )}
        <ActiveModal />
      </div>
    </div>
  );
});
