import { useCallback, useEffect, useState } from 'react';

import { useTimerRef } from 'modules/hooks';

import { MessagesRefs } from './types';

export function useScrollToMessage(
  messageRefs: MessagesRefs,
  onStateChange: (active: boolean) => void,
) {
  const [scrollMessageId, setScrollMessageId] = useState<number | null>(null);

  useEffect(() => {
    onStateChange(!!scrollMessageId);
  }, [scrollMessageId, onStateChange]);

  const timeout = useTimerRef();

  useEffect(() => {
    if (scrollMessageId && messageRefs[scrollMessageId]) {
      // workaround about chrome bug with scrollintoview
      // https://github.com/facebook/react/issues/23396
      // we want to make sure the current scroll animation has completed
      // ideally we should refactor the scroll, later
      window.setTimeout(() => {
        messageRefs[scrollMessageId].current?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });

        timeout.current = window.setTimeout(() => {
          setScrollMessageId(null);
        }, 300); // approximate animation time
      }, 0);
    }
  }, [messageRefs, scrollMessageId, timeout]);

  const scrollToMessage = useCallback((messageId: number) => setScrollMessageId(messageId), []);

  return scrollToMessage;
}
