import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addLogoutReducer } from 'modules/domain/auth/actions';

import { CalendarDayWithMessages, CalendarModelState } from './types';

type SetDaysWithMessagesAction = PayloadAction<{
  stateId: string;
  daysWithMessages: CalendarDayWithMessages[];
}>;

const initialState: CalendarModelState = {};

export const calendarModel = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setDaysWithMessages: (
      state,
      { payload: { stateId, daysWithMessages } }: SetDaysWithMessagesAction,
    ) => {
      state[stateId] = daysWithMessages;
    },
  },
  extraReducers: addLogoutReducer(() => initialState),
  selectors: {
    getDaysWithMessages: (state, stateId: string) => state[stateId],
  },
});
