import { IHttpTransport } from 'modules/api/transport';

import { IGiftsDomain } from './contracts';
import { GiftDto, GiftsAllowedDataDto, GiftsResponseDto } from './dto';

export class GiftsDomain implements IGiftsDomain {
  constructor(private readonly transport: IHttpTransport, private readonly realm: string) {}

  getGifts(attendeeId: string): Promise<GiftDto[]> {
    return this.transport
      .get<GiftsResponseDto>(`/gifts/config/${attendeeId}`)
      .then(response => response.data.Gifts);
  }

  getGiftsAllowedData(animatorId: string, attendeeId: string): Promise<GiftsAllowedDataDto> {
    return this.transport
      .get<GiftsAllowedDataDto>(`/multichat/dialogs/${animatorId}/${attendeeId}/gifts/allowed`, {
        params: { realm: this.realm },
      })
      .then(response => response.data);
  }
}
