import {
  DialogMessageMetaDto,
  MessageDeliveryStatus,
  SearchedDialogMessagesDto,
  AudioTranscriptionDto,
} from 'modules/api/dto';

export enum MessageType {
  TEXT = 'text',
  GIFT = 'cheer', // `cheer` is used on the api side
  PHOTO = 'photo',
  ICEBREAKER = 'icebreaker',
  AUDIO = 'audio',
}

export type FastAnswerInTypedMessage = {
  hotkey: string;
  phrase: string;
};

export type BasenameContent = {
  basename: string;
};

export type ReferenceContent = {
  reference?: string;
};

export type LocalPhotoContent = {
  localFilePath: string;
};

export type IcebreakerContent = {
  text: string;
  type: 'erotic' | 'general';
};

export type GiftMessageContent = BasenameContent & {
  isAnimated?: boolean;
};

export type AudioMessageContent = BasenameContent & {
  transcription?: AudioTranscriptionDto;
};

export type DialogMessageContent =
  | string
  | BasenameContent
  | LocalPhotoContent
  | IcebreakerContent
  | GiftMessageContent
  | AudioMessageContent;

export type DialogMessage = {
  id: number;
  meta: DialogMessageMetaDto;
  outgoing: boolean;
  read: boolean;
  recipientId: string;
  senderId: string;
  tag: string;
  timestamp: number;
  status?: MessageDeliveryStatus;
  firstMessageOfTheDay: boolean;
  attachOnboarding: boolean;
  type: MessageType;
  content: DialogMessageContent;
};

export type SendDialogMessageData = {
  text?: string;
  reference?: string;
  meta?: DialogMessageMetaDto;
};

export type SearchedDialogMessagesState = {
  searchedPhrase: string;
  currentSearchedMessageIndex: number;
} & SearchedDialogMessagesDto;

export type SearchMessagesDirection = 'up' | 'down';
