import React from 'react';

import styles from './index.module.css';

type Props = {
  onClick: () => void;
};
export const NextIcon = (props: Props) => (
  <div className={styles.arrow} onClick={props.onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.5154 11.9999L10.2077 17.3076L9.5 16.5999L14.1 11.9999L9.5 7.39992L10.2077 6.69222L15.5154 11.9999Z"
        fill="currentColor"
      />
    </svg>
  </div>
);
