import { useState } from 'react';

import { AuthStorage } from './types';
import { useApiAuthTokenActualizer } from './use-api-auth-token-actualizer';

const storageFieldName = 'auth';

const defaultValue: AuthStorage = { token: undefined, email: undefined, id: undefined };

const initialAuthStorage =
  parseJSON(sessionStorage.getItem(storageFieldName) || '') ||
  parseJSON(localStorage.getItem(storageFieldName) || '') ||
  defaultValue;

export function useAuthStorage() {
  const [authStorage, setAuthStorage] = useState<AuthStorage>(initialAuthStorage);

  useApiAuthTokenActualizer(authStorage.token);

  const setAuthInfo = (id: string, email: string, token: string, isPermanent = false) => {
    const storage = isPermanent ? localStorage : sessionStorage;

    const payload: AuthStorage = { id, email, token };

    storage.setItem(storageFieldName, JSON.stringify(payload));

    setAuthStorage(payload);
  };

  const clearAuthInfo = () => {
    [localStorage, sessionStorage].forEach(storage => storage.removeItem(storageFieldName));
    setAuthStorage(defaultValue);
  };

  return {
    auth: authStorage,
    setAuthInfo,
    clearAuthInfo,
  };
}

function parseJSON(json: string) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return false;
  }
}
