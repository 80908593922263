import React from 'react';
import classNames from 'classnames';

import { testId } from 'modules/utils';
import { Dialog } from 'modules/domain/dialog/types';
import { UserPanel } from 'modules/components/user/user-panel';
import { ChatHeader } from 'modules/components/chat';
import { ChatForm } from 'modules/components/chat-form';
import { useDialogMessages, useDialogSomeMessagesLoaded } from 'modules/domain/dialog/hooks';

import { useFirstInvisibleMessageTimestamp, useChatServices } from './hooks';
import { ChatLog } from './chat-log';
import styles from './styles.module.scss';

type Props = {
  currentDialog: Dialog;
};

export const ChatWrapper = (props: Props) => {
  const { currentDialog } = props;
  const { animatorId, attendeeId, id: currentDialogId, isExplicit } = currentDialog;

  useChatServices(currentDialog);

  const messages = useDialogMessages(currentDialogId);
  const someMessagesLoaded = useDialogSomeMessagesLoaded(currentDialogId);
  const firstInvisibleMessageTimestamp = useFirstInvisibleMessageTimestamp(messages);

  return (
    <div className={styles.chatWrapper} key={currentDialogId}>
      <div className={styles.chatMiddle}>
        <div className={styles.chatBlock}>
          <div
            {...testId('chat-interlocutor-left')}
            className={classNames(styles.chatInterlocutor, styles.left)}
          >
            <UserPanel type="attendee" animatorId={animatorId} attendeeId={attendeeId} />
          </div>
          <div className={styles.chatLogWrapper} {...testId('chat-log-wrapper')}>
            <ChatHeader
              animatorId={animatorId}
              attendeeId={attendeeId}
              chatIsExplicit={isExplicit}
              timestamp={firstInvisibleMessageTimestamp}
            />
            <ChatLog
              messages={messages}
              someMessagesLoaded={someMessagesLoaded}
              currentDialogId={currentDialogId}
              animatorId={animatorId}
              attendeeId={attendeeId}
            />

            <ChatForm
              animatorId={animatorId}
              attendeeId={attendeeId}
              someMessagesLoaded={someMessagesLoaded}
            />
          </div>
          <div
            {...testId('chat-interlocutor-right')}
            className={classNames(styles.chatInterlocutor, styles.right)}
          >
            <UserPanel type="animator" animatorId={animatorId} attendeeId={attendeeId} />
          </div>
        </div>
      </div>
    </div>
  );
};
