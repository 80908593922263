import React from 'react';

import { testId } from 'modules/utils/test-id';
import { Loader } from 'modules/components/loader';

import styles from './styles.module.scss';
import { OptionSkeleton } from './option-skeleton';

export const Skeleton = () => (
  <div className={styles.optionsList} {...testId('copilot-options-list-skeleton')}>
    <Loader />
    <OptionSkeleton />
    <OptionSkeleton />
    <OptionSkeleton />
  </div>
);
