import { DialogNotesList, DialogNotesTopic, DialogNoteValue } from 'modules/domain/dialog/types';

export type CustomNoteValue = DialogNoteValue & {
  justAdded: boolean;
};

export type CustomNotesList = Record<DialogNotesTopic, CustomNoteValue[]>;

export const makeCustomNotes = (notes: DialogNotesList): CustomNotesList =>
  Object.entries(notes).reduce((result, [topic, topicValues]) => {
    result[topic as DialogNotesTopic] = topicValues.map(value => ({ ...value, justAdded: false }));
    return result;
  }, {} as CustomNotesList);

export const clearNotesBeforeSave = (notes: CustomNotesList): DialogNotesList =>
  Object.entries(notes).reduce((result, [topic, topicValues]) => {
    result[topic as DialogNotesTopic] = topicValues
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ justAdded, ...value }) => value);
    return result;
  }, {} as DialogNotesList);
