import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DictionaryName } from 'modules/api/dto';

import { DictionariesList, Dictionary } from './types';

type SetDictionaryAction = PayloadAction<{
  dictionaryName: DictionaryName;
  dictionary: Dictionary;
}>;

const initialState: DictionariesList = {};

export const dictionariesModel = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    setDictionary: (state, { payload: { dictionaryName, dictionary } }: SetDictionaryAction) => {
      state[dictionaryName] = dictionary;
    },
  },
  selectors: {
    getDictionaries: state => state,
    getDictionary: (state, dictionaryName: DictionaryName) => state[dictionaryName],
  },
});
