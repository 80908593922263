import {
  BonusInfoDto,
  BonusProgramBonusInfoDto,
  BonusProgramDto,
  EarningsDto,
  FullKPIDto,
  SingleKPIDto,
} from 'modules/api/dto';

import {
  BonusInfo,
  BonusProgram,
  BonusProgramBonusInfo,
  EarningsInfo,
  KpiFullInfo,
  KpiInfo,
} from './types/earnings';

const mapBonusProgramBonusInfoDto = (dto: BonusProgramBonusInfoDto): BonusProgramBonusInfo => ({
  value: dto.BonusValue,
  isReached: dto.IsReached,
});

const mapBonusProgramDto = (dto: BonusProgramDto): BonusProgram => ({
  bonuses: dto.Bonuses.map(mapBonusProgramBonusInfoDto),
});

const mapBonusInfoDto = (dto?: BonusInfoDto): BonusInfo | undefined =>
  dto && {
    bonusesCount: dto.BonusesCount,
    totalBonusValue: dto.TotalBonusValue,
  };

export const mapEarningsDto = (dto: EarningsDto): EarningsInfo => {
  return {
    replies: dto.Replies,
    repliesPayment: dto.Value,
    repliesPaymentWithKpi: dto.ReplyValueWithKpi,
    bonusPrograms: dto.BonusPrograms?.map(mapBonusProgramDto) || [],
    firstPurchaseBonus: mapBonusInfoDto(dto.FirstPurchaseBonus),
    mediaViewBonus: mapBonusInfoDto(dto.MediaViewBonus),
    answeredFollowUpBonus: mapBonusInfoDto(dto.AnsweredFollowUpBonus),
    answeredUnpaidBonus: mapBonusInfoDto(dto.AnsweredUnpaidBonus),
    giftsReplyBonus: mapBonusInfoDto(dto.GiftsReplyBonus),
    trapPenalties: mapBonusInfoDto(dto.TrapPenalties),
    kpiEffect: dto.KpiEffect || 0,
  };
};

const mapSingleKpiDto = (dto: SingleKPIDto): KpiInfo => ({
  negative: dto.negative,
  positive: dto.positive,
  current: dto.current,
  indicator: dto.indicator,
});

export const mapKpiDto = (dto: FullKPIDto): KpiFullInfo => ({
  textSent: mapSingleKpiDto(dto.textSent),
  textReplies: mapSingleKpiDto(dto.textReplies),
  giftSent: mapSingleKpiDto(dto.giftSent),
  giftReplies: mapSingleKpiDto(dto.giftReplies),
  photoSent: mapSingleKpiDto(dto.photoSent),
  photoReplies: mapSingleKpiDto(dto.photoReplies),
  quickReplies: mapSingleKpiDto(dto.quickReplies),
});
