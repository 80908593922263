import { getDialogAnimatorStoryTold } from 'modules/domain/dialog/selectors';
import { useRootSelector } from 'modules/hooks/redux';

export const useDialogAnimatorStoryTold = (dialogId: string) => {
  const dialogAnimatorStoryTold = useRootSelector(state =>
    getDialogAnimatorStoryTold(state, dialogId),
  );

  return dialogAnimatorStoryTold;
};
