import { useMemo, useState } from 'react';

import { utcDate, addMonth, MomentDate } from 'modules/utils';

export function useCalendarNavigation(initialDate: MomentDate, firstAvailableDayDate?: string) {
  const [activeMonth, setActiveMonth] = useState<MomentDate>(() =>
    initialDate.clone().startOf('month'),
  );

  function slideMonth(direction: 'left' | 'right') {
    setActiveMonth(current => addMonth(current, direction === 'left' ? -1 : 1));
  }

  function slideMonthLeft() {
    slideMonth('left');
  }

  function slideMonthRight() {
    slideMonth('right');
  }

  const today = useMemo(() => utcDate(), []);

  const canSlideLeft = useMemo(() => {
    const earliestMessageMoment = firstAvailableDayDate ? utcDate(firstAvailableDayDate) : today;

    return activeMonth.isSameOrAfter(earliestMessageMoment);
  }, [today, firstAvailableDayDate, activeMonth]);

  const canSlideRight = useMemo(() => {
    const nextVisibleMonth = addMonth(activeMonth, 1);

    return today.isSameOrAfter(nextVisibleMonth, 'month');
  }, [today, activeMonth]);

  const activeMonthDate = useMemo(() => activeMonth.toDate(), [activeMonth]);
  const activeMonthFormatted = useMemo(() => activeMonth.format('MMMM YYYY'), [activeMonth]);

  return {
    slideMonthLeft,
    slideMonthRight,
    canSlideLeft,
    canSlideRight,
    activeMonth: activeMonthDate,
    activeMonthFormatted,
  };
}
