import React from 'react';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = {
  text: string;
};

export const AutoGeneratedMessageText = (props: Props) => {
  const { text } = props;
  return (
    <div className={styles.afterBotMessageWrapper}>
      <div {...testId('local-generated-message-text')} className={styles.afterBotMessage}>
        {text}
      </div>
    </div>
  );
};
