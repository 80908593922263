import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import classNames from 'classnames';

import { Image } from 'modules/components/image';
import { ImageOverlay } from 'modules/components/image-overlay';
import { testId } from 'modules/utils/test-id';
import { useDialogMediaMessageProgress } from 'modules/domain/dialog/hooks';

import styles from './index.module.scss';

type Props = {
  dialogId: string;
  localSourceUri: string;
  messageTag: string;
};

export const LocalImageMessage = (props: Props) => {
  const { localSourceUri, messageTag, dialogId } = props;

  const progress = useDialogMediaMessageProgress(dialogId, messageTag);

  const progressEnabled = progress !== null;

  return (
    <div className={styles.container}>
      <ImageOverlay
        className={classNames('chat-image-message', styles.overlayContainer)}
        src={localSourceUri}
      >
        <Image className={styles.image} a11yLabel="message-image" src={localSourceUri} alt={''} />
      </ImageOverlay>

      {progressEnabled && (
        <div className={styles.progressContainer}>
          <CircularProgressbar
            {...testId('message-image-progress')}
            value={Number(progress)}
            counterClockwise
            strokeWidth={3}
            styles={buildStyles({
              trailColor: 'transparent',
              pathColor: '#fff',
            })}
          />
        </div>
      )}
    </div>
  );
};
