import {
  AutoGeneratedAnswersUsage,
  AutomationProductName,
  DialogMessageMetaDto,
} from 'modules/api/dto';

export const checkAutoGeneratedMessageUsed = (meta: DialogMessageMetaDto) =>
  meta.automation?.product === AutomationProductName.Copilot &&
  (meta.automation?.usage === AutoGeneratedAnswersUsage.Used ||
    meta?.automation?.usage === AutoGeneratedAnswersUsage.Edited);
