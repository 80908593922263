import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { useOperatorId } from 'modules/domain/auth';
import { useLogger } from 'modules/utils';

import { setFastAnswers } from '../actions';
import { FastAnswersList } from '../types';
import { mapFastAnswersDtoToList, mapFastAnswersListToDto } from '../adapter';

import { useFastAnswersAnalytics } from './use-fast-answers-analytics';

export const useFastAnswersApi = () => {
  const dispatch = useDispatch();
  const { operatorsFastAnswers: operatorsFastAnswersApi } = useApi();
  const { logError } = useLogger('useFastAnswersApi');
  const operatorId = useOperatorId();
  const { trackFastAnswersChanged } = useFastAnswersAnalytics();

  const loadOperatorFastAnswers = useCallback(async () => {
    try {
      const payload = await operatorsFastAnswersApi.getFastAnswers(operatorId);
      dispatch(setFastAnswers(mapFastAnswersDtoToList(payload)));
    } catch (error) {
      logError('loadOperatorFastAnswers error', { error });
    }
  }, [operatorsFastAnswersApi, dispatch, operatorId, logError]);

  const updateOperatorFastAnswers = useCallback(
    async (fastAnswersList: FastAnswersList) => {
      const payload = mapFastAnswersListToDto(fastAnswersList);

      trackFastAnswersChanged(payload);

      try {
        await operatorsFastAnswersApi.updateFastAnswers(operatorId, payload);

        dispatch(setFastAnswers(fastAnswersList));
      } catch (error) {
        logError('updateOperatorFastAnswers error', { error, payload });

        throw error;
      }
    },
    [operatorsFastAnswersApi, dispatch, operatorId, trackFastAnswersChanged, logError],
  );

  return {
    loadOperatorFastAnswers,
    updateOperatorFastAnswers,
  };
};
