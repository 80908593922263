import { useCallback } from 'react';

import { useAnnalsApi } from 'modules/api';
import { FastAnswersDto } from 'modules/api/dto';

export const useFastAnswersAnalytics = () => {
  const { send } = useAnnalsApi();

  const trackFastAnswersChanged = useCallback(
    (fastAnswersPayload: FastAnswersDto) => {
      return send('multichat-fast-answers-changed', {
        content: fastAnswersPayload,
      });
    },
    [send],
  );

  return { trackFastAnswersChanged };
};
