import { MessageType } from 'modules/domain/dialog/types';

const BASE_SEPARATOR = ':';
const SEPARATOR = BASE_SEPARATOR + '///';

export const parseMessageReference = (reference: string) => {
  let parts = reference.split(SEPARATOR);
  if (parts.length === 1) {
    parts = reference.split(BASE_SEPARATOR);
  }
  const [type, basename] = parts;

  return { type: type as MessageType, basename };
};

export const createMessageReference = (
  type: MessageType,
  basename: string,
  useBaseSeparator?: boolean,
) => `${type}${useBaseSeparator ? BASE_SEPARATOR : SEPARATOR}${basename}`;
