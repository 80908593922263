import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { IUsersMediaDomain } from './contracts';
import { UserMediaDto, UserMediaResponseDto } from './dto';

export class UsersMediaDomain implements IUsersMediaDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getUserPhotosUrl(userId: string): string {
    return `/users/${userId}/photos`;
  }

  getUserPhotos(userId: string): Promise<UserMediaDto[]> {
    return this.transport
      .get<UserMediaResponseDto>(this.getUserPhotosUrl(userId), {
        headers: { accept: 'application/vnd.sdv.filetags+json' },
      })
      .then(response => response.data.files);
  }

  getUserChatPhotos(userId: string): Promise<string[]> {
    return this.transport
      .get<string[]>(this.getUserPhotosUrl(userId) + '/chat/list')
      .then(response => response.data);
  }
}
