import React, { memo } from 'react';

import { User } from 'modules/domain/users/types';

import { InfoBlock } from '../info-block';

type Props = Pick<Partial<User>, 'info' | 'occupation'>;

export const AdditionalInfoBlock = memo((props: Props) => {
  const { info: userInfo, occupation } = props;

  const phrases: JSX.Element[] = [];

  if (occupation) {
    phrases.push(<div key="wok-as">Work as - {occupation}</div>);
  }

  if (userInfo?.education) {
    phrases.push(<div key="education">Education - {userInfo.education}</div>);
  }

  if (userInfo?.languages?.length) {
    phrases.push(<div key="languages">Languages - {userInfo.languages.join(', ')}</div>);
  }

  if (userInfo?.relationship) {
    phrases.push(<div key="relationship">Relationship - {userInfo.relationship}</div>);
  }

  if (userInfo?.haveKids) {
    phrases.push(<div key="have-kids">Have kids - {userInfo.haveKids ? 'yes' : 'no'}</div>);
  }

  if (userInfo?.smoke) {
    phrases.push(<div key="smoke">Smoke - {userInfo.smoke}</div>);
  }

  if (userInfo?.drink) {
    phrases.push(<div key="drink">Drink - {userInfo.drink}</div>);
  }

  if (userInfo?.height) {
    phrases.push(<div key="height">Height - {userInfo.height}sm</div>);
  }

  if (userInfo?.bodytype) {
    phrases.push(<div key="body-type">Body type - {userInfo.bodytype}</div>);
  }

  if (userInfo?.eye) {
    phrases.push(<div key="eyes">Eyes - {userInfo.eye}</div>);
  }

  if (userInfo?.hair) {
    phrases.push(<div key="hairs">Hairs - {userInfo.hair}</div>);
  }

  if (!phrases.length) {
    return null;
  }

  return (
    <InfoBlock key="info-item" title="Info">
      {phrases}
    </InfoBlock>
  );
});
