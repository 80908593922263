import React, { memo, Ref, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

import closeIcon from 'images/icon-close-big.svg';
import giftIcon from 'images/gift-icon.svg';
import { Image } from 'modules/components/image';
import { useGetShardUri } from 'modules/hooks';
import { testId } from 'modules/utils';
import { useClickOutside } from 'modules/domain/common/hooks';
import { AnimatedGift } from 'modules/components/animated-gift';
import { useAvailableGifts, useSendGift } from 'modules/domain/gifts/hooks';
import { GiftDto } from 'modules/api/dto';
import { IconButton } from 'modules/components/common/button';
import { showChatErrorToast } from 'modules/components/chat/helpers';

import { CHAT_LOG_CLASS_NAME } from '../chat/wrapper/chat-log';

import styles from './styles.module.scss';

const SEND_GIFT_ERROR_TEXT =
  'Unfortunately this user is not able to receive Virtual Gifts at this moment. Please try again later.';

type Props = {
  dialogId: string;
  btnRef?: Ref<HTMLButtonElement>;
  btnClassName?: string;
};

export const GiftsPicker = memo((props: Props) => {
  const { btnRef, btnClassName, dialogId } = props;

  const [opened, setOpened] = useState(false);
  const [sending, setSending] = useState(false);

  const getShardUri = useGetShardUri();
  const gifts = useAvailableGifts(dialogId);
  const sendGift = useSendGift(dialogId);

  const closePicker = useCallback(() => setOpened(false), []);

  const wrapperRef = useClickOutside<HTMLDivElement>({
    condition: opened,
    action: closePicker,
  });

  const handleGiftSelect = async (gift: GiftDto) => {
    closePicker();
    setSending(true);

    const success = await sendGift(gift);
    if (!success) {
      showChatErrorToast(SEND_GIFT_ERROR_TEXT);
    }
    setSending(false);
  };

  if (!gifts.length) {
    return null;
  }

  return (
    <>
      <IconButton
        ref={btnRef}
        className={btnClassName}
        disabled={sending}
        {...testId('switch-gifts-popup-button')}
        onClick={() => setOpened(!opened)}
      >
        <img src={giftIcon} alt="gifts" />
      </IconButton>

      {opened &&
        createPortal(
          <div ref={wrapperRef} className={styles.picker}>
            <div
              {...testId('gifts-popup-close-button')}
              className={styles.closeButton}
              onClick={closePicker}
            >
              <img src={closeIcon} alt="close" />
            </div>
            <div className={styles.pickerContent}>
              {gifts.map(gift => {
                return (
                  <div
                    key={gift.giftId}
                    className={styles.pickerItem}
                    {...testId(`cheer-${gift.giftId}`)}
                  >
                    <div className={styles.pickerItemButton} onClick={() => handleGiftSelect(gift)}>
                      {gift.isAnimated ? (
                        <AnimatedGift animationPath={gift.imagePath} />
                      ) : (
                        <Image
                          className={styles.pickerItemImage}
                          src={getShardUri(gift.imagePath)}
                          alt="gift-image"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>,
          document.querySelector(`.${CHAT_LOG_CLASS_NAME}`)!,
        )}
    </>
  );
});
