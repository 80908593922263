import { DialogMessage } from 'modules/domain/dialog/types';

import { useDialogMessages } from './use-dialog-messages';

export const useDialogLatestMessage = (dialogId: string) => {
  const dialogMessages = useDialogMessages(dialogId);

  const dialogLatestMessage = dialogMessages[0] as DialogMessage | undefined;

  return dialogLatestMessage;
};
