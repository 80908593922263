export enum ApiEventsType {
  AnsweredMessage = 'message.answered',
  UnansweredMessage = 'message.unanswered',
  NewMessage = 'message:message:v2',
  TypingStarted = 'event.user.typing.started',
  TypingEnded = 'event.user.typing.ended',
  DialogOperatorLockUpdated = 'event.multichat.dialogs.lock.operator.updated',
}

export type EventDto = {
  label?: ApiEventsType;
  type?: ApiEventsType;
  payload?: unknown;
  details?: unknown;
  sync?: string;
};
