import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useUserHasTag } from 'modules/domain/users/hooks';
import { getDialogId } from 'modules/utils';
import { UserTag } from 'modules/api/dto';

import { setSpotifyMatchResult } from '../actions';

import { useSpotifyApi } from './use-spotify-api';
import { useSpotifyMatchResult } from './selectors';

export const useSpotifyMatch = (animatorId: string, attendeeId: string) => {
  const dialogId = getDialogId(animatorId, attendeeId);

  const dispatch = useDispatch();
  const { getSpotifyMatchResult } = useSpotifyApi();

  const hasSpotifyTag = useUserHasTag(attendeeId, UserTag.SpotifyConnected);

  useEffect(() => {
    if (!hasSpotifyTag) {
      return;
    }

    getSpotifyMatchResult(animatorId, attendeeId).then(result => {
      if (result) {
        dispatch(setSpotifyMatchResult(dialogId, result));
      }
    });
  }, [getSpotifyMatchResult, animatorId, attendeeId, dialogId, hasSpotifyTag, dispatch]);

  const spotifyMatchResult = useSpotifyMatchResult(dialogId);

  return spotifyMatchResult;
};
