import { IHttpTransport } from 'modules/api/transport';

import { IUsersTagsDomain } from './contracts';
import { UserExperimentTag, UserTag, UserTargetedTag } from './dto';

export class UsersTagsDomain implements IUsersTagsDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getUserTags(userId: string): Promise<UserTag[]> {
    return this.transport.get<UserTag[]>(`/users/${userId}/tags`).then(response => response.data);
  }

  getUserExperimentsTags(userId: string): Promise<UserExperimentTag[]> {
    return this.transport
      .get<UserExperimentTag[]>(`/users/${userId}/tags/experiments`)
      .then(response => response.data);
  }

  getUserTargetedTags(userId: string, targetId: string): Promise<UserTargetedTag[]> {
    return this.transport
      .get<UserTargetedTag[]>(`/users/targeted/${userId}/${targetId}/tags`)
      .then(response => response.data);
  }
}
