import { useMemo } from 'react';

import { useRootSelector } from 'modules/hooks/redux';
import { filterAvailableGifts } from 'modules/domain/gifts/helpers';

import { getDialogGiftsState } from '../model';

export const useAvailableGifts = (dialogId: string) => {
  const { gifts, priceLimit } =
    useRootSelector(state => getDialogGiftsState(state, dialogId)) || {};

  const availableGifts = useMemo(() => {
    if (!gifts) {
      return [];
    }

    return filterAvailableGifts(gifts, priceLimit || 0);
  }, [gifts, priceLimit]);

  return availableGifts;
};
