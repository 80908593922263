import { useCallback } from 'react';

import { useConfig } from 'modules/config';
import { useDialogLatestMessage } from 'modules/domain/dialog/hooks';

export function checkHasStopPhrases(message: string, stopPhrases: string[]) {
  const findMessage = message.toLowerCase();

  return stopPhrases.some(stopPhrase => findMessage.includes(stopPhrase));
}

export const useHasForbiddenMessageCheck = (dialogId: string) => {
  const { stopPhrases } = useConfig();
  const latestMessage = useDialogLatestMessage(dialogId);

  const checkHasForbiddenMessage = useCallback(
    (message: string) => {
      const isDuplicate = latestMessage?.content === message;
      const hasStopPhrases = checkHasStopPhrases(message, stopPhrases);

      return isDuplicate || hasStopPhrases;
    },
    [stopPhrases, latestMessage?.content],
  );

  return checkHasForbiddenMessage;
};
