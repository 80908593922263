import { createRef, useCallback, useMemo } from 'react';

import { useCurrentDialogId, useDialogMessages } from 'modules/domain/dialog/hooks';

import { MessagesRefs } from './types';

export const useMessagesRefs = () => {
  const currentDialogId = useCurrentDialogId();
  const messages = useDialogMessages(currentDialogId);

  const messagesRefs = useMemo(
    () =>
      messages.reduce((refs, message) => {
        refs[message.id] = createRef();
        return refs;
      }, {} as MessagesRefs),
    [messages],
  );

  const setMessageRef = useCallback(
    (messageId: number) => {
      return messagesRefs[messageId];
    },
    [messagesRefs],
  );

  return { messagesRefs, setMessageRef };
};
