import { EarningsInfo } from '../model';

import { calculateBonusProgramsTotalIncome } from './calculate-bonus-programs-total-income';

export function calculateTotalBonusIncome(earnings: EarningsInfo | undefined) {
  if (!earnings) {
    return 0;
  }

  const {
    firstPurchaseBonus,
    mediaViewBonus,
    answeredFollowUpBonus,
    answeredUnpaidBonus,
    giftsReplyBonus,
    bonusPrograms,
  } = earnings;

  const bonusProgramsTotalIncome = calculateBonusProgramsTotalIncome(bonusPrograms);

  const totalBonusIncome = [
    firstPurchaseBonus,
    mediaViewBonus,
    answeredUnpaidBonus,
    answeredFollowUpBonus,
    giftsReplyBonus,
  ].reduce((result, bonus) => result + (bonus?.totalBonusValue || 0), bonusProgramsTotalIncome);

  return totalBonusIncome;
}
