import React, { memo, useMemo } from 'react';
import classNames from 'classnames';

import { useHeaderTimer } from 'modules/components/multichat/timer';
import { useAverageTimer } from 'modules/components/extended-timer/use-average-timer';
import { HeaderScale } from 'modules/components/header-scale';
import { useConfig } from 'modules/config';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

export const ExtendedTimerView = memo(() => {
  const { averageTimePretty, outOfLimit, addTime } = useAverageTimer();
  const time = useHeaderTimer(addTime);

  const {
    timer: { responseLimitSeconds },
  } = useConfig();

  const timeLimitReached = useMemo(() => {
    const [mm, ss] = time.split(':');
    const timeInSeconds = parseInt(mm) * 60 + parseInt(ss);

    return timeInSeconds > responseLimitSeconds;
  }, [time, responseLimitSeconds]);

  return (
    <>
      <HeaderScale
        caption={'RESP. TIME'}
        url={null}
        value={
          <span
            {...testId('response-time-value')}
            className={classNames(styles.title, { [styles.danger]: timeLimitReached })}
            data-tooltip-id="timer-tooltip"
          >
            {time}
          </span>
        }
        testIdPostfix={'response-time-panel'}
        tooltip="The faster you reply, the more chances for live chats you have, the more replies you receive"
        tooltipId={'resp-time-tooltip'}
      />
      <HeaderScale
        caption={'AVG. RESP. TIME'}
        url={null}
        value={
          <span
            {...testId('avg-response-time-value')}
            className={classNames(styles.title, { [styles.danger]: outOfLimit })}
          >
            {averageTimePretty}
          </span>
        }
        testIdPostfix={'response-time-panel'}
        tooltip="The faster you reply, the more chances for live chats you have, the more replies you receive"
        tooltipId={'avg-resp-time-tooltip'}
      />
    </>
  );
});
