import { useCallback } from 'react';

import { useConfig } from 'modules/config';
import { usePoller } from 'modules/hooks';
import { useDialogMessagesApi } from 'modules/domain/dialog/hooks';

export const useMessagesPoller = (currentDialogId: string) => {
  const { fetchDialogMessages } = useDialogMessagesApi(currentDialogId);

  const { chatMessagesPollingInterval, chatPageSize } = useConfig();

  const messagesPoller = useCallback(() => {
    fetchDialogMessages(chatPageSize, 0);
  }, [fetchDialogMessages, chatPageSize]);

  usePoller(messagesPoller, chatMessagesPollingInterval);
};
