import { FastAnswersList } from './types';

export enum FastAnswersActions {
  SetFastAnswers = 'fast-answers.set-fast-answers',
  SetLoading = 'fast-answers.set-loading',
}

export type SetFastAnswersPayload = {
  data: FastAnswersList;
};

type FastAnswerSetAction = {
  type: FastAnswersActions;
  payload: SetFastAnswersPayload;
};

export const setFastAnswers = (data: FastAnswersList): FastAnswerSetAction => ({
  type: FastAnswersActions.SetFastAnswers,
  payload: { data },
});
