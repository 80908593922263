import React, { memo } from 'react';

import { useDialogSentMedia } from 'modules/domain/media/hooks';
import { ImageOverlay } from 'modules/components/image-overlay';
import { testId } from 'modules/utils';
import { Image } from 'modules/components/image';
import { useGetPhotoUrl } from 'modules/hooks';
import { TAB_MEDIA_SIZE } from 'modules/constants';
import { useCurrentDialogId } from 'modules/domain/dialog/hooks';

import styles from './index.module.scss';

type Props = {
  animatorId: string;
};

export const AccordionTabSentPhotos = memo((props: Props) => {
  const { animatorId } = props;

  const getPhotoUrl = useGetPhotoUrl();
  const dialogId = useCurrentDialogId();
  const sentMedia = useDialogSentMedia(dialogId);

  return (
    <div {...testId(`user-tab-sent-photos-${animatorId}`)} className={styles.container}>
      <div className={styles.containerInner}>
        <div className={styles.photosBlock}>
          <div className={styles.photosGrid}>
            {sentMedia.map(({ basename, baseUrl }, i) => {
              const overlaySrc = getPhotoUrl({ isPrivate: true, baseUrl: baseUrl });
              const thumbnail = getPhotoUrl({
                baseUrl: baseUrl,
                size: TAB_MEDIA_SIZE,
                isPrivate: true,
              });

              return (
                <ImageOverlay key={basename} selectedItem={i} src={overlaySrc}>
                  <Image
                    a11yLabel={`user-tab-sent-photo-${basename}`}
                    src={thumbnail}
                    alt={thumbnail}
                  />
                </ImageOverlay>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});
