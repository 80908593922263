import { reducer } from './reducer';
import * as actions from './actions';
import * as selectors from './selectors';
import * as hooks from './hooks';

export const ModalModel = {
  reducer,
  actions,
  selectors,
  hooks,
};
