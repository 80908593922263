import { useCallback } from 'react';

import { useApi } from 'modules/api';

import { useGetPhotoUrl, UseGetPhotoUrlParams } from '../use-get-photo-url';

export type UseGetUserPhotoUrlParams = UseGetPhotoUrlParams;

export const useGetUserPhotoUrl = (userId: string) => {
  const { usersMedia: usersMediaApi } = useApi();
  const getPhotoUrl = useGetPhotoUrl();

  const getUserPhotoUrl = useCallback(
    (params: UseGetUserPhotoUrlParams) => {
      const { isPrivate, baseUrl, ...rest } = params;

      let url = usersMediaApi.getUserPhotosUrl(userId);

      if (isPrivate) {
        url += '/private';
      }

      url += `/${baseUrl}`;

      return getPhotoUrl({ baseUrl: url, isPrivate, ...rest });
    },
    [getPhotoUrl, usersMediaApi, userId],
  );

  return getUserPhotoUrl;
};
