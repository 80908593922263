import React from 'react';

import { formatMoney, testId } from 'modules/utils';

import styles from './index.module.scss';

type Props = {
  value: number;
};

export const IncomeValue = (props: Props) => {
  const { value } = props;

  return (
    <span {...testId('income-scale-value')} className={value < 0 ? styles.badValue : ''}>
      {formatMoney(value)}
    </span>
  );
};
