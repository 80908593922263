import { getDialogMessagesSearchState } from 'modules/domain/dialog/selectors';
import { useRootSelector } from 'modules/hooks/redux';

export const useDialogMessagesSearchState = (dialogId: string) => {
  const dialogMessagesSearchState = useRootSelector(state =>
    getDialogMessagesSearchState(state, dialogId),
  );

  return dialogMessagesSearchState;
};
