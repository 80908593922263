import { DialogMessage } from 'modules/domain/dialog/types';
import { DialogMessageDto } from 'modules/api/dto';

import { messagesHaveTheSameDate } from './messages-have-the-same-date';
import { singleMessageAdapter } from './single-message-adapter';

export const messagesAdapter = (
  messagesDto: DialogMessageDto[],
  animatorId: string,
  animatorTimezone: number,
): DialogMessage[] => {
  let onboardingAttached = false;

  return messagesDto.map((messageDto, index) => {
    const previousMessageDto = messagesDto[index + 1];

    const firstMessageOfTheDay = !messagesHaveTheSameDate(
      messageDto,
      previousMessageDto,
      animatorTimezone,
    );
    const attachOnboarding = !onboardingAttached && firstMessageOfTheDay;

    if (attachOnboarding) {
      onboardingAttached = true;
    }

    return singleMessageAdapter({
      dto: messageDto,
      animatorId,
      firstMessageOfTheDay,
      attachOnboarding,
    });
  });
};
