import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';

import { mapSpotifyMatchDtoToResult } from '../adapter';

export const useSpotifyApi = () => {
  const { spotify: spotifyApi } = useApi();
  const { logError } = useLogger('useSpotifyApi');

  const getSpotifyMatchResult = useCallback(
    async (animatorId: string, attendeeId: string) => {
      try {
        const [scoreDto, topContentDto] = await Promise.all([
          spotifyApi.getMatchScore(animatorId, attendeeId),
          spotifyApi.getTopContent(attendeeId),
        ]);

        return mapSpotifyMatchDtoToResult(scoreDto, topContentDto);
      } catch (error) {
        logError('Get spotify match error', { error, animatorId, attendeeId });

        return null;
      }
    },
    [spotifyApi, logError],
  );

  return { getSpotifyMatchResult };
};
