import React from 'react';
import { Formik, Form, Field, ErrorMessage, FormikErrors } from 'formik';

import { Button } from 'modules/components/common/button/base';
import { RadioInput } from 'modules/components/common/radio-input';
import { Report, ClientComplaints, AnimatorComplaints } from 'modules/domain/complaint/types';
import { MessageInput } from 'modules/components/common/message-input';
import { useComplaint } from 'modules/domain/complaint/hooks';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = {
  onDismiss: () => void;
  reportType: React.MutableRefObject<Report>;
};

export type FormValues = {
  complaint: ClientComplaints | AnimatorComplaints | undefined;
  userText: string;
};

const validate = (values: Partial<FormValues>) => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.complaint) {
    errors.complaint = 'Field is required';
  }

  if (values?.complaint === 'Other' && !values.userText) {
    errors.userText = 'Field is required';
  }

  return errors;
};

const initialValues: FormValues = {
  complaint: undefined,
  userText: '',
};

export const SendComplaintForm = (props: Props) => {
  const { onDismiss, reportType } = props;
  const { sendReport } = useComplaint();

  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={values => sendReport(values)}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Field component="div" name="complaint">
              {(reportType.current.complaints as Array<ClientComplaints | AnimatorComplaints>).map(
                complaintType => (
                  <RadioInput
                    style={{ marginTop: 8 }}
                    key={complaintType}
                    id={complaintType}
                    name="complaint"
                    checked={complaintType === values.complaint}
                    value={complaintType}
                    label={complaintType}
                  />
                ),
              )}
              <ErrorMessage name="complaint">
                {msg => <span className={styles.required}>{msg}</span>}
              </ErrorMessage>
            </Field>
            {values.complaint === 'Other' && (
              <Field
                type="text"
                name="userText"
                placeholder="Type your report reason…"
                backgroundTheme="light"
                className={styles.input}
                component={MessageInput}
                rows={4}
              />
            )}
            <div className={styles.row}>
              <Button
                {...testId('report-message-cancel-button')}
                type="button"
                size="md"
                disabled={isSubmitting}
                onClick={onDismiss}
              >
                Cancel
              </Button>
              <Button
                {...testId('report-message-send-button')}
                type="submit"
                size="md"
                theme="raspberry"
                disabled={isSubmitting}
              >
                Report
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
