import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useOperatorId } from 'modules/domain/auth';

import { actions } from '../actions';

const STORAGE_PREFIX = 'onboarding_completed_for_';

export function useOnboarding() {
  const operatorId = useOperatorId();
  const storageKey = STORAGE_PREFIX + operatorId;
  const dispatch = useDispatch();

  const initializeOnboardingMode = useCallback(() => {
    const currentOnboardingRecord = localStorage.getItem(storageKey) !== 'ok';
    dispatch(actions.setUserOnboardingStatus(operatorId, currentOnboardingRecord));
    return currentOnboardingRecord;
  }, [storageKey, dispatch, operatorId]);

  const finishOnboarding = useCallback(() => {
    localStorage.setItem(storageKey, 'ok');
    dispatch(actions.setUserOnboardingStatus(operatorId, false));
  }, [storageKey, dispatch, operatorId]);

  return {
    initializeOnboardingMode,
    finishOnboarding,
  };
}
