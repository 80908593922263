import React, { ComponentProps, memo } from 'react';

import { useUserField } from 'modules/domain/users/hooks';
import { TextMessage } from 'modules/components/chat/text-message';

import styles from './index.module.scss';

type Props = Omit<ComponentProps<typeof TextMessage>, 'a11yLabel' | 'className'> & {
  attendeeId: string;
};

export const UnansweredUnpaidMessage = memo((props: Props) => {
  const { attendeeId, content, isFocus, outgoing } = props;

  const attendeeName = useUserField(attendeeId, 'name') ?? '';

  return (
    <div className={styles.textMessageContainer}>
      <p className={styles.titleText}>{`${attendeeName} failed to tell you:`}</p>
      <TextMessage
        a11yLabel="unanswered-unpaid-message"
        content={content}
        isFocus={isFocus}
        className={styles.textMessage}
        outgoing={outgoing}
      />
      <p className={styles.descriptionText}>
        Try to touch upon a similar topic to gain User’s attention!
      </p>
    </div>
  );
});
