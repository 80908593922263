import React, { useRef } from 'react';

import { DialogMessage } from 'modules/domain/dialog/types';
import { VipCaption, ChatDropzone } from 'modules/components/chat';
import { Loader } from 'modules/components/loader';

import { ScrollToBottomButton } from './scroll-to-bottom-button';
import { CopilotAnswerOptions } from './copilot-answer-options';
import { ChatScrollContainer } from './scroll-container';
import { ChatMessagesList } from './messages-list';
import styles from './styles.module.scss';

type Props = {
  messages: DialogMessage[];
  someMessagesLoaded: boolean;
  animatorId: string;
  attendeeId: string;
  currentDialogId: string;
};

export const CHAT_LOG_CLASS_NAME = styles.chatLog;

export const ChatLog = (props: Props) => {
  const { messages, someMessagesLoaded, currentDialogId, animatorId, attendeeId } = props;

  const chatLogContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={CHAT_LOG_CLASS_NAME} ref={chatLogContainerRef}>
      <ChatDropzone
        dialogId={currentDialogId}
        width={chatLogContainerRef.current?.clientWidth || 0}
        height={chatLogContainerRef.current?.clientHeight || 0}
      />
      <ChatScrollContainer>
        {!someMessagesLoaded && <Loader />}
        <ChatMessagesList messages={messages} currentDialogId={currentDialogId} />
        <VipCaption attendeeId={attendeeId} />
        <CopilotAnswerOptions
          attendeeId={attendeeId}
          animatorId={animatorId}
          lastMessage={messages[0]}
        />
        <ScrollToBottomButton />
      </ChatScrollContainer>
    </div>
  );
};
