import { BonusProgram } from '../model/types/earnings';

const calculateBonusProgramTotalIncome = ({ bonuses }: BonusProgram) =>
  bonuses.reduce((totalIncome, bonus) => totalIncome + (bonus.isReached ? bonus.value : 0), 0);

export const calculateBonusProgramsTotalIncome = (bonusPrograms: BonusProgram[] | undefined) =>
  (bonusPrograms || []).reduce(
    (totalIncome, bonusProgram) => totalIncome + calculateBonusProgramTotalIncome(bonusProgram),
    0,
  );
