import React, { memo } from 'react';

import { useCurrentDialog } from 'modules/domain/dialog/hooks';

import {
  useDataPrefetch,
  useOperatorActivitySender,
  useDialogAutoSwitcher,
  useNoNewChats,
} from './hooks';
import { ChatWithEmptyStates } from './chat-with-empty-states';
import './styles.scss';

export const MultichatWindow = memo(() => {
  // services and pollers
  useOperatorActivitySender();
  useDataPrefetch();
  useDialogAutoSwitcher();

  const dialog = useCurrentDialog();
  const noNewChats = useNoNewChats();

  return (
    <div className="multichat-window">
      <ChatWithEmptyStates noNewChats={noNewChats} dialog={dialog} />
    </div>
  );
});
