import { useSelector } from 'react-redux';

import { RootState } from 'modules/App/store/root-state';

import { getFastAnswers } from '../selectors';
import { FastAnswersList } from '../types';

export const useFastAnswersData = () => {
  return useSelector<RootState, FastAnswersList | null>(state => getFastAnswers(state));
};
