import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { IFeaturesDomain } from './contracts';
import { FeatureAvailableDto } from './dto';

export class FeaturesDomain implements IFeaturesDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getIcebreakersEnabled(attendeeId: string): Promise<FeatureAvailableDto> {
    return this.transport
      .get<FeatureAvailableDto>(`/users/${attendeeId}/features/icebreakers`)
      .then(response => response.data);
  }

  getVoiceMessagesEnabled(attendeeId: string): Promise<FeatureAvailableDto> {
    return this.transport
      .get<FeatureAvailableDto>(`/users/${attendeeId}/features/voice.messages`)
      .then(response => response.data);
  }
}
