export type MultichatEnv = 'debug' | `staging${number}${number}` | 'etalon' | 'production';

export const getEnv = (): MultichatEnv => {
  const { host } = window.location;

  if (process.env.NODE_ENV !== 'production' || host.includes('localhost')) {
    return 'debug';
  }

  const [, stand] = host.match(/multichat(\d{2})?./) || [];

  if (!stand) {
    return 'production';
  }
  if (stand === '05') {
    return 'etalon';
  }

  return ('staging.' + stand) as MultichatEnv;
};
