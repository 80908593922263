import React, { memo } from 'react';
import cn from 'classnames';

import {
  useCurrentDialogId,
  useDialogAnimatorApi,
  useDialogAnimatorStoryTold,
} from 'modules/domain/dialog/hooks';

import styles from './index.module.scss';

export const StorySectionOverlay = memo(() => {
  const dialogId = useCurrentDialogId();
  const { updateAnimatorStoryTold } = useDialogAnimatorApi();
  const animatorStoryTold = useDialogAnimatorStoryTold(dialogId);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateAnimatorStoryTold(dialogId, e.target.checked);
  };

  return (
    <div className={styles.container}>
      <label className={cn(styles.label, { [styles.labelChecked]: animatorStoryTold })}>
        <input
          type="checkbox"
          onChange={onChange}
          checked={animatorStoryTold}
          className={cn({ [styles.checked]: animatorStoryTold })}
        />
        <span>The story has been told</span>
      </label>
    </div>
  );
});
