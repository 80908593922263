import { RootState } from 'modules/App/store/root-state';

import { namespace } from './constants';
import { FastAnswersModelState } from './types';

const getFastAnswersModelState = (state: RootState): FastAnswersModelState => state[namespace];

export const getFastAnswers = (state: RootState) => {
  return getFastAnswersModelState(state).data;
};
