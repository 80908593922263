import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { IUsersDomain } from './contracts';
import { UserDto, UserInterestsDto, UserLifestyleResponseDto, UserLocationDto } from './dto';

export class UsersDomain implements IUsersDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getUserLocation(userId: string, holderId: string): Promise<UserLocationDto> {
    return this.transport
      .get<UserLocationDto>(`/users/${userId}/location/${holderId}`)
      .then(response => response.data);
  }

  getUserData(userId: string, isPrivate?: boolean): Promise<UserDto> {
    return this.transport
      .get<UserDto>(isPrivate ? `/users/private/${userId}` : `/users/${userId}`)
      .then(response => response.data);
  }

  getUserInterests(userId: string): Promise<UserInterestsDto> {
    return this.transport
      .get<UserInterestsDto>(`/users/${userId}/interests`, {
        headers: {
          'Client-Version': '2',
        },
      })
      .then(response => response.data);
  }

  getUserLifeStory(userId: string): Promise<string | null> {
    return this.transport
      .get<UserLifestyleResponseDto>(`/users/${userId}/lifestyle`)
      .then(response => response.data.story ?? null);
  }
}
