import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useDialogUnansweredUnpaidState } from 'modules/domain/dialog/hooks';
import { setUnansweredUnpaidMessageAnswered as setUnansweredUnpaidMessageAnsweredAction } from 'modules/domain/dialog/actions';

export const useUnansweredUnpaidMessageCheck = (dialogId: string) => {
  const dispatch = useDispatch();
  const { messageLoaded, messageAnswered } = useDialogUnansweredUnpaidState(dialogId);

  const shouldAnswerUnansweredUnpaidMessage = !!messageLoaded && !messageAnswered;

  const setUnansweredUnpaidMessageAnswered = useCallback(() => {
    dispatch(setUnansweredUnpaidMessageAnsweredAction(dialogId));
  }, [dialogId, dispatch]);

  return {
    shouldAnswerUnansweredUnpaidMessage,
    setUnansweredUnpaidMessageAnswered,
  };
};
