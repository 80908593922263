import { SpotifyMatchScoreDto, SpotifyTopContentDto } from 'modules/api/dto';

import { SpotifyMatchResult } from './types';

export const mapSpotifyMatchDtoToResult = (
  scoreDto: SpotifyMatchScoreDto,
  topContentDto: SpotifyTopContentDto,
): SpotifyMatchResult => ({
  score: scoreDto.score,
  level: scoreDto.level,
  artists: topContentDto.artists,
  genres: topContentDto.genres,
  tracks: topContentDto.tracks,
});
