import { DayWithMessagesDto } from 'modules/api/dto';

const mapCalendarDayWithMessagesDto = (dto: DayWithMessagesDto) => ({
  count: dto.count,
  date: dto.date,
  idMax: dto.idMax,
  idMin: dto.idMin,
});

export const mapCalendarDaysWithMessagesDto = (dto: DayWithMessagesDto[]) =>
  dto.map(mapCalendarDayWithMessagesDto);
