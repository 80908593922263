import { useCallback, useEffect, useRef, useState } from 'react';

import { MessagesRefs } from './types';

/**
 * size of the chat header
 */
const CHAT_HEADER_OFFSET_SIZE = 96;

export const useFirstInvisibleMessageId = (messagesRefs: MessagesRefs) => {
  const [firstInvisibleMessageId, setFirstInvisibleMessageId] = useState<number | null>(null);

  const prevFirstInvisibleMessageId = useRef(firstInvisibleMessageId);

  const calculateFirstInvisibleMessageId = useCallback(() => {
    const messagesIds = Object.keys(messagesRefs);

    const firstVisibleMessageIndex = messagesIds.findIndex(refKey => {
      const rect = messagesRefs[refKey].current?.getBoundingClientRect();
      return rect && rect.top > CHAT_HEADER_OFFSET_SIZE;
    });

    const firstInvisibleMessageId = Number(messagesIds[firstVisibleMessageIndex - 1]) || null;

    if (firstInvisibleMessageId !== prevFirstInvisibleMessageId.current) {
      setFirstInvisibleMessageId(firstInvisibleMessageId);

      prevFirstInvisibleMessageId.current = firstInvisibleMessageId;
    }
  }, [messagesRefs]);

  useEffect(() => {
    calculateFirstInvisibleMessageId();
  }, [calculateFirstInvisibleMessageId]);

  return {
    firstInvisibleMessageId,
    calculateFirstInvisibleMessageId,
  };
};
