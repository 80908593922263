import React from 'react';
import classnames from 'classnames';

import { OfflineBanner } from 'modules/components/offline/offline-banner';

import styles from './index.module.scss';

export function OfflinePage() {
  const classNames = classnames(styles.container, 'h-100');

  return (
    <div className={classNames}>
      <OfflineBanner />
    </div>
  );
}
