import React, { ButtonHTMLAttributes, memo, useMemo } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

type RoundButtonDefaultProps = ButtonHTMLAttributes<HTMLButtonElement>;

type Props = {
  caption?: string;
  size?: 'xs' | 'sm' | 'md' | 'default';
  theme?: 'default' | 'white' | 'darker' | 'transparent';
} & RoundButtonDefaultProps;

export const RoundButton = memo((props: Props) => {
  const {
    caption,
    size = 'default',
    theme = 'default',
    children,
    className: externalStyles,
    ...rest
  } = props;

  const containerClassName = useMemo(
    () => classNames(styles.container, externalStyles),
    [externalStyles],
  );

  const buttonClassName = useMemo(
    () =>
      classNames(
        styles.button,
        { [styles[theme]]: theme !== 'default' },
        { [styles[size]]: size !== 'default' },
      ),
    [size, theme],
  );

  const phrase = useMemo(
    () => caption?.split('\n').map((part, i) => <span key={i}>{part}</span>),
    [caption],
  );

  return (
    <div className={containerClassName}>
      <button {...rest} className={buttonClassName}>
        {children}
      </button>
      <span className={styles.caption}>{phrase}</span>
    </div>
  );
});
