import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = ButtonHTMLAttributes<HTMLButtonElement>;

export const FastAnswersBaseButton = (props: Props) => {
  const { className, ...btnProps } = props;

  return <button className={classNames(styles.button, className)} type="button" {...btnProps} />;
};
