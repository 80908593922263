import { dictionariesModel } from './model';

const { reducer, reducerPath, actions, selectors } = dictionariesModel;

export const { setDictionary } = actions;
export const { getDictionaries, getDictionary } = selectors;
export { reducer, reducerPath as namespace };

export type { DictionariesList, Dictionary } from './types';
export { DictionaryName } from './types';
