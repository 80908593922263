import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';

export const useShardsApi = () => {
  const { shards: shardsApi } = useApi();
  const { logError } = useLogger('useShardsApi');

  const getShards = useCallback(
    async (userId: string) => {
      try {
        const shards = await shardsApi.getShards(userId);

        return shards;
      } catch (error) {
        logError('Get shards error', { userId, error });

        throw error;
      }
    },
    [shardsApi, logError],
  );

  return { getShards };
};
