export enum ModalNames {
  FastAnswers = 'FastAnswers',
  SendComplaint = 'SendComplaint',
  MediaSelector = 'MediaSelector',
  WebcamMedia = 'WebcamMedia',
  DialogSearch = 'DialogSearch',
  Calendar = 'Calendar',
  AudioSelector = 'AudioSelector',
}

export type ModalData = any;

export type ModalModelState = {
  isOpen: boolean;
  activeModalName: ModalNames | null;
  data?: ModalData;
};
