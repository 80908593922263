import React from 'react';
import cn from 'classnames';

import { useOperatorTrapPenalty } from 'modules/domain/operator/hooks';
import { IconButton } from 'modules/components/common/button';

import { CloseIcon } from './close-icon';
import styles from './styles.module.scss';

type Props = {
  onClose: () => void;
  dockToRight?: boolean;
};

export const TrapModal = (props: Props) => {
  const { onClose, dockToRight } = props;

  const operatorTrapPenalty = useOperatorTrapPenalty();

  return (
    <div className={cn(styles.trapModal, dockToRight ? styles.dockToRight : styles.dockToLeft)}>
      <div>
        <div className={styles.fineAmount}>-${Math.abs(operatorTrapPenalty)}</div>
        <div>
          Got you! This answer is not appropriate, be more attentive. Your income has been reduced.
        </div>
      </div>
      <IconButton className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};
