import { useSelector } from 'react-redux';

import { RootState } from 'modules/App/store/root-state';
import { ModalNames } from 'modules/domain/modals/types';

import { getModalVisibilityStatus, getActiveModalName, getActiveModalData } from '../selectors';

export const useSelectors = <T>() => {
  const visibilityStatus = useSelector<RootState, boolean>(state =>
    getModalVisibilityStatus(state),
  );
  const activeModalName = useSelector<RootState, ModalNames | null>(state =>
    getActiveModalName(state),
  );
  const activeModalData = useSelector<RootState, T | null>(state => getActiveModalData(state));

  return {
    visibilityStatus,
    activeModalName,
    activeModalData,
  };
};
