import React, { memo } from 'react';
import cn from 'classnames';

import { useCanToggleExplicit } from 'modules/domain/dialog/hooks';
import { DayIndicator } from 'modules/components/day-indicator';

import { ExplicitBanner } from './explicit-banner';
import styles from './styles.module.scss';

type Props = {
  animatorId: string;
  attendeeId: string;
  chatIsExplicit: boolean;
  timestamp: number | null;
};

export const ChatHeader = memo((props: Props) => {
  const { animatorId, attendeeId, chatIsExplicit, timestamp } = props;

  const canToggleExplicit = useCanToggleExplicit();

  return (
    <div className={styles.header}>
      {!!timestamp && (
        <div className={cn(styles.item, styles.center)}>
          <DayIndicator timestamp={timestamp} onTop />
        </div>
      )}
      {canToggleExplicit && (
        <div className={cn(styles.item, styles.right)}>
          <ExplicitBanner status={chatIsExplicit} animatorId={animatorId} attendeeId={attendeeId} />
        </div>
      )}
    </div>
  );
});
