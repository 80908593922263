import { DialogMessageBaseDto, DialogMessageDto } from 'modules/api/dto';

import { DialogActions } from './common';

// Send message
export type ApiMessageSentPayload = {
  dialogId: string;
  message: DialogMessageBaseDto;
};
export type ApiMessageSentAction = {
  type: DialogActions.ApiMessageSent;
  payload: ApiMessageSentPayload;
};

export function setApiMessageSent(
  dialogId: string,
  message: DialogMessageBaseDto,
): ApiMessageSentAction {
  return {
    type: DialogActions.ApiMessageSent,
    payload: { dialogId, message },
  };
}

export type ApiSendMessageFailurePayload = {
  dialogId: string;
  messageTag: string;
  dialogForceLocked: boolean;
};
export type ApiSendMessageFailureAction = {
  type: DialogActions.ApiSendMessageFailure;
  payload: ApiSendMessageFailurePayload;
};

export function setApiSendMessageFailure(
  dialogId: string,
  messageTag: string,
  dialogForceLocked: boolean,
): ApiSendMessageFailureAction {
  return {
    type: DialogActions.ApiSendMessageFailure,
    payload: { dialogId, messageTag, dialogForceLocked },
  };
}

export type SetDialogMessagesPayload = { dialogId: string; messages: DialogMessageDto[] };
export type SetDialogMessagesAction = {
  type: DialogActions.SetMessages;
  payload: SetDialogMessagesPayload;
};

export function setDialogMessages(
  dialogId: string,
  messages: DialogMessageDto[],
): SetDialogMessagesAction {
  return { type: DialogActions.SetMessages, payload: { dialogId, messages } };
}

export type SetDialogAllMessagesLoadedPayload = { dialogId: string };
export type SetDialogAllMessagesLoadedAction = {
  type: DialogActions.SetAllMessagesLoaded;
  payload: SetDialogAllMessagesLoadedPayload;
};

export function setDialogAllMessagesLoaded(dialogId: string): SetDialogAllMessagesLoadedAction {
  return { type: DialogActions.SetAllMessagesLoaded, payload: { dialogId } };
}

export type SetUnansweredUnpaidMessageAnsweredPayload = {
  dialogId: string;
};
export type SetUnansweredUnpaidMessageAnsweredAction = {
  type: DialogActions.SetUnansweredUnpaidMessageAnswered;
  payload: SetUnansweredUnpaidMessageAnsweredPayload;
};

export function setUnansweredUnpaidMessageAnswered(
  dialogId: string,
): SetUnansweredUnpaidMessageAnsweredAction {
  return {
    type: DialogActions.SetUnansweredUnpaidMessageAnswered,
    payload: { dialogId },
  };
}

export type ApiUploadMediaStartPayload = {
  dialogId: string;
  message: DialogMessageBaseDto;
};
export type ApiUploadMediaStartAction = {
  type: DialogActions.ApiUploadMediaStart;
  payload: ApiUploadMediaStartPayload;
};

export function setApiUploadMediaStart(
  dialogId: string,
  message: DialogMessageBaseDto,
): ApiUploadMediaStartAction {
  return { type: DialogActions.ApiUploadMediaStart, payload: { dialogId, message } };
}

export type ApiUploadMediaSuccessPayload = {
  dialogId: string;
  messageTag: string;
};
export type ApiUploadMediaSuccessAction = {
  type: DialogActions.ApiUploadMediaSuccess;
  payload: ApiUploadMediaSuccessPayload;
};

export function setApiUploadMediaSuccess(
  dialogId: string,
  messageTag: string,
): ApiUploadMediaSuccessAction {
  return { type: DialogActions.ApiUploadMediaSuccess, payload: { dialogId, messageTag } };
}

export type ApiUploadMediaFailurePayload = { dialogId: string; messageTag: string };
export type ApiUploadMediaFailureAction = {
  type: DialogActions.ApiUploadMediaFailure;
  payload: ApiUploadMediaFailurePayload;
};

export function setApiUploadMediaFailure(
  dialogId: string,
  messageTag: string,
): ApiUploadMediaFailureAction {
  return { type: DialogActions.ApiUploadMediaFailure, payload: { dialogId, messageTag } };
}

export type ApiUploadMediaSetProgressPayload = {
  dialogId: string;
  messageTag: string;
  progress: number;
};
export type ApiUploadMediaSetProgressAction = {
  type: DialogActions.ApiUploadMediaSetProgress;
  payload: ApiUploadMediaSetProgressPayload;
};

export function setApiUploadMediaProgress(
  dialogId: string,
  messageTag: string,
  progress: number,
): ApiUploadMediaSetProgressAction {
  return {
    type: DialogActions.ApiUploadMediaSetProgress,
    payload: { dialogId, messageTag, progress },
  };
}
