export enum UserMediaTags {
  Private = 'hidden',
}

export type UserMediaDto = {
  basename: string;
  tags: string[];
  mediatype: string;
  created: string;
};

export type UserMediaResponseDto = {
  files: UserMediaDto[];
};
