import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { parseDialogId } from 'modules/utils';

import { setGiftsPriceLimit } from '../model';

import { useGiftsApi } from './use-gifts-api';

export const useGiftsPriceLimitChecker = (dialogId: string) => {
  const dispatch = useDispatch();
  const { getGiftsPriceLimit } = useGiftsApi(dialogId);

  const checkPriceLimit = useCallback(async () => {
    const { animatorId, attendeeId } = parseDialogId(dialogId);

    const priceLimit = await getGiftsPriceLimit(animatorId, attendeeId);

    dispatch(setGiftsPriceLimit({ dialogId, priceLimit }));

    return priceLimit;
  }, [dispatch, getGiftsPriceLimit, dialogId]);

  return checkPriceLimit;
};
