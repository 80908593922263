import { useEffect, useState } from 'react';

import { useApi } from 'modules/api';
import { getUserLocationString, useLogger } from 'modules/utils';

export function useAnimatorLocation(animatorId: string, attendeeId: string) {
  const [location, setLocation] = useState<string>('');
  const { users: usersApi } = useApi();
  const { logError } = useLogger('useAnimatorLocation');

  useEffect(() => {
    if (attendeeId && animatorId) {
      usersApi
        .getUserLocation(animatorId, attendeeId)
        .then(newLocation =>
          setLocation(getUserLocationString(newLocation.city, newLocation.country)),
        )
        .catch(error => {
          logError('Failed to get animator location', { error, animatorId, attendeeId });
          setLocation('');
        });
    } else {
      setLocation('');
    }
  }, [animatorId, attendeeId, usersApi, logError]);

  return location;
}
