import { range } from 'lodash';

import { FastAnswersDto } from 'modules/api/dto';

import { FastAnswer, FastAnswersList } from './types';

const defaultFastAnswers = range(1, 10)
  .concat([0])
  .map(number => ({ shortcut: `ctrl${number}`, phrase: '', shortcutDigit: number } as FastAnswer));

export const mapFastAnswersDtoToList = (dto: FastAnswersDto): FastAnswersList => {
  return defaultFastAnswers.map(({ phrase: emptyPhrase, shortcut, shortcutDigit }) => ({
    shortcut,
    shortcutDigit,
    phrase: dto[shortcut] ?? emptyPhrase,
  }));
};

export const mapFastAnswersListToDto = (list: FastAnswersList): FastAnswersDto => {
  return list.reduce((result, fastAnswer) => {
    result[fastAnswer.shortcut as keyof FastAnswersDto] = fastAnswer.phrase;

    return result;
  }, {} as FastAnswersDto);
};
