import { useMemo } from 'react';

import { useUserPhotos } from 'modules/domain/media/hooks';
import { useGetUserPhotoUrl } from 'modules/hooks';
import { OVERLAY_IMAGE_SIZE, TAB_MEDIA_SIZE } from 'modules/constants';

export type UserTabPhoto = {
  id: string;
  url: string;
  isPrivate: boolean;
};

export const useUserTabPhotos = (userId: string) => {
  const getUserPhotoUrl = useGetUserPhotoUrl(userId);

  const userPhotos = useUserPhotos(userId);

  return useMemo(() => {
    const privatePhotos: UserTabPhoto[] = [];
    const privateOverlays: string[] = [];

    const publicPhotos: UserTabPhoto[] = [];
    const publicOverlays: string[] = [];

    (userPhotos || []).forEach(({ basename, isPrivate }) => {
      const getPhotoUrl = (size: number) => getUserPhotoUrl({ baseUrl: basename, size, isPrivate });

      const photo: UserTabPhoto = {
        id: basename,
        url: getPhotoUrl(TAB_MEDIA_SIZE),
        isPrivate,
      };
      const overlayUrl = getPhotoUrl(OVERLAY_IMAGE_SIZE);

      if (isPrivate) {
        privatePhotos.push(photo);
        privateOverlays.push(overlayUrl);
      } else {
        publicPhotos.push(photo);
        publicOverlays.push(overlayUrl);
      }
    });

    return { privatePhotos, privateOverlays, publicPhotos, publicOverlays };
  }, [getUserPhotoUrl, userPhotos]);
};
