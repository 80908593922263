import { DialogMessageDto } from 'modules/api/dto';
import { utcDate } from 'modules/utils';

export function messagesHaveTheSameDate(
  message1: DialogMessageDto,
  message2: DialogMessageDto | null,
  timezone: number,
) {
  if (!message2) {
    return false;
  }
  const date1 = utcDate(message1.timestamp).utcOffset(timezone);
  const date2 = utcDate(message2.timestamp).utcOffset(timezone);

  return date1.isSame(date2, 'day');
}
