import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useOperatorId } from 'modules/domain/auth';

import { CopilotState } from '../types';

export const useCopilotAnalytics = (animatorId: string, attendeeId: string) => {
  const operatorId = useOperatorId();
  const { annals } = useApi();

  const trackCopilotUsage = useCallback(
    (message: string, copilot: CopilotState) => {
      const payload = {
        userId: attendeeId,
        animatorId,
        operatorId,
        options: (copilot.options || []).map(option => option.text),
        message,
        botName: copilot.botName,
      };

      return annals.send(operatorId, 'copilot-data', payload);
    },
    [annals, operatorId, animatorId, attendeeId],
  );

  return { trackCopilotUsage };
};
