import { UserDto } from 'modules/api/dto';
import { getUserAge, getUserLocationString } from 'modules/utils';

import { User } from './types';

export const apiUserToUser = (userDto: UserDto, email?: string, token?: string): User => {
  const user: User = {
    id: userDto.id,
    name: userDto.name,
    age: getUserAge(userDto.birthday),
    birthday: userDto.birthday,
    location: getUserLocationString(userDto.city, userDto.country),
    thumbnail: userDto.thumbnail,
    tags: userDto.tags, // merge with data from /users/:userId/tag request in redux store
    experiments: [], // override by /users/:userId/tags/experiments request in redux store
    occupation: userDto.occupation,
    timezone: userDto.timezone,
    gender: userDto.gender,
    email,
    token,
    language: userDto.languages[0],

    info: {
      aboutMe: userDto.about,
      interests: [], // override by /users/:userId/interests request in redux store
      lifeStory: null, // override by /users/:userId/life-style request in redux store
      eye: userDto.eye,
      hair: userDto.hair,
      drink: userDto.drink,
      smoke: userDto.smoke,
      education: userDto.education,
      relationship: userDto.relationship,
      haveKids: userDto.haveKids,
      bodytype: userDto.bodytype,
      languages: userDto.languages,
      height: userDto.height,
      communicationPurpose: userDto['communication-purpose'],
    },
  };

  return user;
};
