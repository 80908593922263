import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';

import { actions } from '../actions';

export const useUserLifeStoryApi = () => {
  const dispatch = useDispatch();
  const { users: usersApi } = useApi();
  const { logError } = useLogger('useUserStoryApi');

  const fetchLifeStory = useCallback(
    async (userId: string) => {
      try {
        const lifeStory = await usersApi.getUserLifeStory(userId);
        dispatch(actions.setUserLifeStory(userId, lifeStory));
      } catch (error) {
        logError('fetchLifeStory error', { animatorId: userId, error });
      }
    },
    [dispatch, usersApi, logError],
  );

  return {
    fetchLifeStory,
  };
};
