import React, { memo, useCallback, useMemo } from 'react';

import { useOperatorFastAnswers } from 'modules/domain/fast-answers/hooks';
import { FastAnswer } from 'modules/domain/fast-answers/types';
import { useShortcutListener } from 'modules/components/common/hooks';
import { useCurrentDialogUsersIds, useDialogTypedMessage } from 'modules/domain/dialog/hooks';
import { useUserField } from 'modules/domain/users/hooks';
import { ModalNames } from 'modules/domain/modals/types';
import { useModal } from 'modules/domain/modals/hooks';
import { replaceByMask } from 'modules/utils';

import { FastAnswersAddButton } from './add-button';
import { FastAnswersSlider } from './slider';
import styles from './index.module.scss';

export const FastAnswers = memo(() => {
  const { fastAnswers } = useOperatorFastAnswers();
  const { attendeeId } = useCurrentDialogUsersIds();
  const attendeeName = useUserField(attendeeId, 'name');
  const { setAdditionalMessagePart } = useDialogTypedMessage();
  const { openModal } = useModal();

  const sendMessage = useCallback(
    (fastAnswer: FastAnswer) => {
      const message = replaceByMask(fastAnswer.phrase, '%name%', attendeeName || '');
      const payload = { phrase: fastAnswer.phrase, hotkey: fastAnswer.shortcut };
      setAdditionalMessagePart(message, payload);
    },
    [setAdditionalMessagePart, attendeeName],
  );

  const handleShortcutPress = useCallback(
    (keyNumber: number) => {
      const foundFastAnswer = fastAnswers?.find(item => keyNumber === item.shortcutDigit);

      if (foundFastAnswer) {
        sendMessage(foundFastAnswer);
      }
    },
    [fastAnswers, sendMessage],
  );

  useShortcutListener(handleShortcutPress, 'ctrlKey');

  const handleAddBtnClick = () => {
    openModal(ModalNames.FastAnswers);
  };

  const filledFastAnswers = useMemo(() => {
    return fastAnswers?.filter(fastAnswer => fastAnswer.phrase.length);
  }, [fastAnswers]);

  const hasFastAnswers = !!filledFastAnswers?.length;

  return (
    <div className={styles.fastAnswers}>
      <FastAnswersAddButton
        variant={hasFastAnswers ? 'default' : 'extended'}
        onClick={handleAddBtnClick}
      />
      {hasFastAnswers && (
        <FastAnswersSlider fastAnswers={filledFastAnswers} onFastAnswerClick={sendMessage} />
      )}
    </div>
  );
});
