import React from 'react';

import { UserChatRole } from 'modules/domain/users/types';

import { AnimatorTabs } from './components/animator-tabs';
import { AttendeeTabs } from './components/attendee-tabs';

type Props = {
  type: UserChatRole;
  attendeeId: string;
  animatorId: string;
};

export const AccordionTabs = (props: Props) => {
  const { type, attendeeId, animatorId } = props;

  switch (type) {
    case 'animator':
      return <AnimatorTabs animatorId={animatorId} attendeeId={attendeeId} />;

    default:
      return <AttendeeTabs animatorId={animatorId} attendeeId={attendeeId} />;
  }
};
