import { useDialog, useDialogMessagesApi } from 'modules/domain/dialog/hooks';
import { utcDate } from 'modules/utils';
import { useChatScrollerActions } from 'modules/components/chat/chat-scroller-context';

import { CalendarDayWithMessages } from '../model';
import { findDayWithMessagesByDate } from '../helpers';

export function useScrollToMessagesByDate(
  daysWithMessages: CalendarDayWithMessages[],
  dialogId: string,
) {
  const dialogMessages = useDialog(dialogId)?.messages;
  const { scrollToMessage } = useChatScrollerActions();
  const { fetchDialogMessages } = useDialogMessagesApi(dialogId);

  async function scrollToMessagesByDate(date: Date) {
    const dayToScroll = findDayWithMessagesByDate(daysWithMessages, date);

    if (!dayToScroll) {
      return;
    }

    const availableMessagesCount = dialogMessages?.length || 0;
    const requiredMessagesCount = daysWithMessages.reduce((count, day) => {
      if (utcDate(day.date).isSameOrAfter(dayToScroll.date)) {
        return count + day.count;
      }
      return count;
    }, 0);

    const messagesToFetchCount = requiredMessagesCount - availableMessagesCount;

    if (messagesToFetchCount > 0) {
      await fetchDialogMessages(messagesToFetchCount, availableMessagesCount);
    }

    scrollToMessage(dayToScroll.idMin);
  }

  return scrollToMessagesByDate;
}
