import cn from 'classnames';
import React, { memo } from 'react';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';

type Props = {
  total: number;
  kpiEffect: number;
};

export const TotalKPI = memo((props: Props) => {
  const { total, kpiEffect } = props;
  return (
    <div className={styles.totalKPI}>
      <span className={styles.total}>
        Total KPI:{' '}
        <span
          className={cn(styles.kpiEffectValue, total <= 0 ? styles.badValue : styles.goodValue)}
          {...testId(`kpi-page-total-kpi`)}
        >
          {total}
        </span>
      </span>
      <span className={styles.kpiEffect}>
        KPI Effect:{' '}
        <span
          className={cn(styles.kpiEffectValue, kpiEffect <= 0 ? styles.badValue : styles.goodValue)}
          {...testId(`kpi-page-kpi-effect`)}
        >
          {kpiEffect}%
        </span>
      </span>
    </div>
  );
});
