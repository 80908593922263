import { isCorrectEmail } from 'modules/utils';

import { AuthFormValues } from './types';

export const initialFormState: AuthFormValues = {
  email: '',
  password: '',
  remember: false,
};

export const validate = (values: AuthFormValues) => {
  const errors: Partial<AuthFormValues> = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!isCorrectEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 4) {
    errors.password = 'Required min 4 symbols';
  }

  return errors;
};
