import { useCallback } from 'react';

import { useConfig } from 'modules/config';
import { usePoller, PollerFn } from 'modules/hooks';

export const useGiftsPriceLimitPoller = (
  checkPriceLimitFn: () => Promise<number>,
  giftsAllowed: boolean,
) => {
  const { giftsAllowedDataPollingInterval } = useConfig();

  const checkPriceLimitPollerFn = useCallback<PollerFn>(
    stopPoller => {
      if (giftsAllowed) {
        return checkPriceLimitFn();
      }

      stopPoller();
    },
    [giftsAllowed, checkPriceLimitFn],
  );

  usePoller(checkPriceLimitPollerFn, giftsAllowedDataPollingInterval);
};
