import { useEffect } from 'react';

import { TypingSource, useTypingTracker } from 'modules/domain/typing-tracker';
import { useCurrentDialogId } from 'modules/domain/dialog/hooks';

export const useTypingIndicatorActive = () => {
  const dialogId = useCurrentDialogId();
  const typingTracker = useTypingTracker();

  useEffect(() => {
    typingTracker.setTypingStart(dialogId, TypingSource.AudioPicker);

    return () => {
      typingTracker.setTypingEnd(dialogId, TypingSource.AudioPicker);
    };
  }, [typingTracker, dialogId]);
};
