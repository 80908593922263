import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';
import { isEqual, unionBy } from 'lodash';

import { EntityMap, MultiChatReducer } from 'modules/domain/common/types';
import { AuthActions } from 'modules/domain/auth/actions';
import { findUsualLastMessage, parseDialogId } from 'modules/utils';
import { DialogMessageDto, AutoGeneratedAnswersUsage } from 'modules/api/dto';

import { DialogActions } from './actions/common';
import { Dialog, DialogMediaMessages, DialogModelState } from './types';
import { namespace } from './common';
import { SwitchDialogPayload, SetFoundDialogPayload } from './actions/entity-actions';
import { ApiRequestSuccessDialogListPayload } from './actions/api-dialog-actions';
import {
  ApiSendMessageFailurePayload,
  ApiMessageSentPayload,
  ApiUploadMediaFailurePayload,
  ApiUploadMediaSetProgressPayload,
  ApiUploadMediaStartPayload,
  ApiUploadMediaSuccessPayload,
  SetUnansweredUnpaidMessageAnsweredPayload,
  SetDialogMessagesPayload,
  SetDialogAllMessagesLoadedPayload,
} from './actions/api-dialog-message-actions';
import {
  SetAudioMessagesPayload,
  CopilotLoadSucceedPayload,
  RemoveMessageByTagPayload,
  ResetAudioMessagesPayload,
  SetDialogExplicitStatusPayload,
  SetDialogNotesPayload,
  SetAnimatorStoryToldPayload,
  SetAnimatorPreferencesPayload,
  SetDialogsMetricsPayload,
} from './actions';
import {
  ApiSearchMessagesSuccessPayload,
  ClearSearchedMessagesPayload,
  SwitchSearchedMessageIndexPayload,
} from './actions/search-messages-actions';
import {
  SetAdditionalMessagePartPayload,
  SetDialogInputIsFocusedPayload,
  SetTypedMessagePayload,
} from './actions/message-typing';
import { mapAutoGeneratedAnswersDtoToCopilotState } from './adapter/copilot';

type CommonReducerPayload = SwitchDialogPayload &
  ApiRequestSuccessDialogListPayload &
  SetDialogMessagesPayload &
  ApiMessageSentPayload &
  ApiSendMessageFailurePayload &
  ApiUploadMediaStartPayload &
  ApiUploadMediaSuccessPayload &
  ApiUploadMediaFailurePayload &
  ApiUploadMediaSetProgressPayload &
  SetDialogAllMessagesLoadedPayload &
  SetDialogNotesPayload &
  SetAnimatorStoryToldPayload &
  SetAnimatorPreferencesPayload &
  SetDialogsMetricsPayload &
  ApiSearchMessagesSuccessPayload &
  ClearSearchedMessagesPayload &
  SwitchSearchedMessageIndexPayload &
  SetDialogExplicitStatusPayload &
  SetTypedMessagePayload &
  SetAdditionalMessagePartPayload &
  SetDialogInputIsFocusedPayload &
  SetUnansweredUnpaidMessageAnsweredPayload &
  RemoveMessageByTagPayload &
  CopilotLoadSucceedPayload &
  SetAudioMessagesPayload &
  ResetAudioMessagesPayload;

type DialogReducerMapValue<P> = ReducerMapValue<DialogModelState, P>;

interface CustomReducerMap extends ReducerMap<DialogModelState, CommonReducerPayload> {
  [DialogActions.SwitchDialog]: DialogReducerMapValue<SwitchDialogPayload>;
  [DialogActions.SetFoundDialog]: DialogReducerMapValue<SetFoundDialogPayload>;
  [DialogActions.ApiRequestListSuccess]: DialogReducerMapValue<ApiRequestSuccessDialogListPayload>;
  [DialogActions.SetMessages]: DialogReducerMapValue<SetDialogMessagesPayload>;
  [DialogActions.SetAllMessagesLoaded]: DialogReducerMapValue<SetDialogAllMessagesLoadedPayload>;
  [DialogActions.ApiMessageSent]: DialogReducerMapValue<ApiMessageSentPayload>;
  [DialogActions.ApiSendMessageFailure]: DialogReducerMapValue<ApiSendMessageFailurePayload>;
  [DialogActions.ApiUploadMediaStart]: DialogReducerMapValue<ApiUploadMediaStartPayload>;
  [DialogActions.ApiUploadMediaSuccess]: DialogReducerMapValue<ApiUploadMediaSuccessPayload>;
  [DialogActions.ApiUploadMediaFailure]: DialogReducerMapValue<ApiUploadMediaFailurePayload>;
  [DialogActions.ApiUploadMediaSetProgress]: DialogReducerMapValue<ApiUploadMediaSetProgressPayload>;
  [DialogActions.SetNotes]: DialogReducerMapValue<SetDialogNotesPayload>;
  [DialogActions.SetAnimatorPreferences]: DialogReducerMapValue<SetAnimatorPreferencesPayload>;
  [DialogActions.SetMetrics]: DialogReducerMapValue<SetDialogsMetricsPayload>;
  [DialogActions.ApiSearchMessagesSuccess]: DialogReducerMapValue<ApiSearchMessagesSuccessPayload>;
  [DialogActions.ClearSearchedMessages]: DialogReducerMapValue<ClearSearchedMessagesPayload>;
  [DialogActions.SwitchSearchedMessageIndex]: DialogReducerMapValue<SwitchSearchedMessageIndexPayload>;
  [DialogActions.SetDialogExplicitStatus]: DialogReducerMapValue<SetDialogExplicitStatusPayload>;
  [DialogActions.SetTypedMessage]: DialogReducerMapValue<SetTypedMessagePayload>;
  [DialogActions.SetAdditionalMessagePart]: DialogReducerMapValue<SetAdditionalMessagePartPayload>;
  [DialogActions.SetDialogInputIsFocused]: DialogReducerMapValue<SetDialogInputIsFocusedPayload>;
  [DialogActions.SetUnansweredUnpaidMessageAnswered]: DialogReducerMapValue<SetUnansweredUnpaidMessageAnsweredPayload>;
  [DialogActions.RemoveMessageByTag]: DialogReducerMapValue<RemoveMessageByTagPayload>;
  [DialogActions.CopilotLoadSucceed]: DialogReducerMapValue<CopilotLoadSucceedPayload>;
  [DialogActions.SetAudioMessages]: DialogReducerMapValue<SetAudioMessagesPayload>;
  [DialogActions.ResetAudioMessages]: DialogReducerMapValue<ResetAudioMessagesPayload>;
}

const initialState: DialogModelState = {
  currentDialogId: '',
  foundDialogId: null,
  dialogsQueueIsEmpty: false,
  canToggleExplicit: false,
  metrics: {
    unansweredCount: 0,
    urgentUnansweredCount: 0,
    urgentUnansweredPeriod: 0,
  },
  entities: {
    byId: {},
  },
  typedTextMessage: {
    content: '',
    fastAnswer: null,
  },
  copilot: {},
  dialogInputIsFocused: false,
  currentDialogForceLocked: false,
};

const reducerMapping: CustomReducerMap = {
  [AuthActions.Logout]: state => {
    return update(state, { $set: initialState });
  },
  [DialogActions.ApiRequestListFailure]: state => {
    return update(state, {
      currentDialogForceLocked: { $set: false },
    });
  },
  [DialogActions.ApiRequestListSuccess]: (state, { payload }) => {
    return update(state, {
      dialogsQueueIsEmpty: { $set: payload.searchMode ? state.dialogsQueueIsEmpty : false },
      canToggleExplicit: { $set: false },
      currentDialogForceLocked: { $set: false },
      entities: {
        byId: {
          $apply: (map: EntityMap<Dialog>) => {
            const dialog = payload.dialogList[0];
            const dialogId = dialog.id;

            const isDialogAlreadyExist = !!map[dialogId] as boolean;

            if (isDialogAlreadyExist) {
              return {
                [dialogId]: {
                  ...map[dialogId],
                  ...dialog,
                },
              };
            }

            if (payload.searchMode) {
              return {
                ...map,
                [dialogId]: {
                  ...dialog,
                  searchMode: payload.searchMode,
                },
              };
            }

            return { [dialogId]: dialog };
          },
        },
      },
    });
  },
  [DialogActions.SwitchDialog]: (state, { payload }) =>
    update(state, {
      currentDialogId: { $set: payload.id },
    }),
  [DialogActions.SetFoundDialog]: (state, { payload }) =>
    update(state, {
      foundDialogId: { $set: payload.id },
    }),
  [DialogActions.ResetFoundDialog]: state =>
    update(state, {
      foundDialogId: { $set: null },
      entities: {
        byId: {
          $apply: (map: EntityMap<Dialog>) => {
            const dialogIds = Object.keys(map);

            const updatedMap = dialogIds.reduce<EntityMap<Dialog>>((acc, dialogId) => {
              const dialog = map[dialogId];
              return !dialog.searchMode ? { ...acc, [dialogId]: dialog } : acc;
            }, {});

            return updatedMap;
          },
        },
      },
    }),
  [DialogActions.ApiListEmpty]: state => {
    return update(state, {
      currentDialogId: { $set: initialState.currentDialogId },
      canToggleExplicit: { $set: false },
      dialogsQueueIsEmpty: { $set: true },
      metrics: { $set: initialState.metrics },
      foundDialogId: { $set: initialState.foundDialogId },
      entities: { $set: initialState.entities },
    });
  },
  [DialogActions.SetMessages]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              const updatedDialog: Dialog = { ...dialog, someMessagesLoaded: true };

              const newMessages = payload.messages.filter(message => !message.meta.sensitive);

              if (!newMessages.length) {
                if (dialog.someMessagesLoaded) {
                  return dialog;
                }
                return updatedDialog;
              }

              if (newMessages.some(m => m.meta.unansweredUnpaid)) {
                updatedDialog.unansweredUnpaid = {
                  ...updatedDialog.unansweredUnpaid,
                  messageLoaded: true,
                };
              }

              updatedDialog.messages = unionBy(newMessages, updatedDialog.messages, 'tag');
              updatedDialog.messages.sort((a, b) => b.timestamp - a.timestamp);

              const lastMessage = findUsualLastMessage(updatedDialog.messages);

              const { animatorId } = parseDialogId(payload.dialogId);

              if (lastMessage) {
                updatedDialog.answered = lastMessage.sender !== animatorId;
                updatedDialog.timestamp = lastMessage.timestamp;
              }

              if (!isEqual(dialog, updatedDialog)) {
                return updatedDialog;
              }

              return dialog;
            },
          },
        },
      },
    }),
  [DialogActions.SetAllMessagesLoaded]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              if (!dialog || dialog.allMessagesLoaded) {
                return dialog;
              }

              return { ...dialog, allMessagesLoaded: true };
            },
          },
        },
      },
    }),
  [DialogActions.ApiMessageSent]: (state, { payload }) =>
    update(state, {
      canToggleExplicit: { $set: true },
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              const message = payload.message as DialogMessageDto;
              const messagesCopy = dialog.messages.slice();
              const messageIndex = dialog.messages.findIndex(m => m.tag === message.tag);

              if (messageIndex === -1) {
                messagesCopy.unshift(message);
              } else {
                messagesCopy[messageIndex] = message;
              }

              return { ...dialog, messages: messagesCopy, answered: false, timestamp: Date.now() };
            },
          },
        },
      },
      typedTextMessage: {
        fastAnswer: { $set: initialState.typedTextMessage.fastAnswer },
        content: { $set: '' },
      },
    }),
  [DialogActions.ApiSendMessageFailure]: (state, { payload }) =>
    update(state, {
      currentDialogForceLocked: {
        $set: payload.dialogForceLocked || state.currentDialogForceLocked,
      },
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              const newMessagesList = dialog.messages.filter(m => m.tag !== payload.messageTag);

              return { ...dialog, messages: newMessagesList };
            },
          },
        },
      },
    }),
  [DialogActions.ApiUploadMediaStart]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              const { message } = payload;
              const messages = [payload.message as DialogMessageDto, ...dialog.messages];

              const mediaMessages = {
                ...dialog.mediaMessages,
                [message.tag]: { pending: true, progress: 0 },
              };
              return {
                ...dialog,
                messages,
                mediaMessages,
              };
            },
          },
        },
      },
    }),
  [DialogActions.ApiUploadMediaSuccess]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              const { messageTag } = payload;
              const mediaMessages: DialogMediaMessages = {
                ...dialog.mediaMessages,
                [messageTag]: {
                  ...dialog.mediaMessages?.[messageTag],
                  pending: false,
                },
              };
              return {
                ...dialog,
                mediaMessages,
              };
            },
          },
        },
      },
    }),
  [DialogActions.ApiUploadMediaFailure]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              const { messageTag } = payload;

              const mediaMessages: DialogMediaMessages = {
                ...dialog.mediaMessages,
              };

              delete mediaMessages[messageTag];

              return {
                ...dialog,
                mediaMessages,
              };
            },
          },
        },
      },
    }),
  [DialogActions.ApiUploadMediaSetProgress]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              const { messageTag, progress } = payload;
              const mediaMessages: DialogMediaMessages = {
                ...dialog.mediaMessages,
                [messageTag]: {
                  ...dialog.mediaMessages?.[messageTag],
                  pending: true,
                  progress,
                },
              };
              return {
                ...dialog,
                mediaMessages,
              };
            },
          },
        },
      },
    }),
  [DialogActions.SetNotes]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              return { ...dialog, notes: payload.input };
            },
          },
        },
      },
    }),
  [DialogActions.SetAnimatorStoryTold]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              return { ...dialog, animatorStoryTold: payload.storyTold };
            },
          },
        },
      },
    }),
  [DialogActions.SetAnimatorPreferences]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              return { ...dialog, animatorPreferences: payload.input };
            },
          },
        },
      },
    }),
  [DialogActions.SetMetrics]: (state, { payload }) =>
    update(state, {
      metrics: { $set: payload.metrics },
    }),
  [DialogActions.ApiSearchMessagesSuccess]: (
    state,
    { payload: { dialogId, searchedPhrase, result } },
  ) =>
    update(state, {
      entities: {
        byId: {
          [dialogId]: {
            $apply: (dialog: Dialog) => {
              return {
                ...dialog,
                searchedMessages: {
                  ...result,
                  searchedPhrase,
                  currentSearchedMessageIndex: 0,
                },
              };
            },
          },
        },
      },
    }),
  [DialogActions.ClearSearchedMessages]: (state, { payload: { dialogId } }) =>
    update(state, {
      entities: {
        byId: {
          [dialogId]: {
            $apply: (dialog: Dialog) => {
              return {
                ...dialog,
                searchedMessages: undefined,
              };
            },
          },
        },
      },
    }),
  [DialogActions.SwitchSearchedMessageIndex]: (
    state,
    { payload: { dialogId, searchedMessageIndex } },
  ) =>
    update(state, {
      entities: {
        byId: {
          [dialogId]: {
            searchedMessages: {
              $apply: searchedMessages => {
                if (!searchedMessages) {
                  return undefined;
                }

                return {
                  ...searchedMessages,
                  currentSearchedMessageIndex: searchedMessageIndex,
                };
              },
            },
          },
        },
      },
    }),
  [DialogActions.SetDialogExplicitStatus]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.id]: {
            $apply: (dialog: Dialog) => {
              return { ...dialog, isExplicit: payload.status };
            },
          },
        },
      },
    }),
  [DialogActions.SetTypedMessage]: (state, { payload }) => {
    return update(state, {
      typedTextMessage: {
        content: { $set: payload.message },
      },
      copilot: {
        usage: {
          $set:
            state.copilot.usage === AutoGeneratedAnswersUsage.Used
              ? AutoGeneratedAnswersUsage.Edited
              : state.copilot.usage,
        },
      },
    });
  },
  [DialogActions.SetAdditionalMessagePart]: (state, { payload }) =>
    update(state, {
      typedTextMessage: {
        content: { $apply: message => `${message} ${payload.message}`.trim() },
        fastAnswer: { $set: payload.fastAnswer || null },
      },
    }),
  [DialogActions.SetDialogInputIsFocused]: (state, { payload }) => {
    return update(state, {
      dialogInputIsFocused: { $set: payload.isFocused },
    });
  },
  [DialogActions.SetUnansweredUnpaidMessageAnswered]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              return {
                ...dialog,
                unansweredUnpaid: {
                  ...dialog.unansweredUnpaid,
                  messageAnswered: true,
                },
              };
            },
          },
        },
      },
    }),
  [DialogActions.RemoveMessageByTag]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              const messages = dialog.messages.filter(message => message.tag !== payload.tag);

              return { ...dialog, messages };
            },
          },
        },
      },
    }),
  [DialogActions.ResetCopilotState]: state =>
    update(state, {
      copilot: {
        $set: {},
      },
    }),
  [DialogActions.CopilotLoadSucceed]: (state, { payload }) =>
    update(state, {
      copilot: {
        $set: {
          ...mapAutoGeneratedAnswersDtoToCopilotState(payload),
          // this is important to keep previous usage for the situation when copilot option was used
          // but at the same time another message came from client and new copilot options have loaded
          usage: state.copilot.usage || AutoGeneratedAnswersUsage.NotUsed,
        },
      },
    }),
  [DialogActions.CopilotOptionClicked]: (state, { payload }) =>
    update(state, {
      copilot: {
        usage: {
          $set: AutoGeneratedAnswersUsage.Used,
        },
      },
      typedTextMessage: {
        content: { $set: payload.message },
      },
    }),
  [DialogActions.SetAudioMessages]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              return { ...dialog, audioMessages: payload.audioMessages };
            },
          },
        },
      },
    }),
  [DialogActions.ResetAudioMessages]: (state, { payload }) =>
    update(state, {
      entities: {
        byId: {
          [payload.dialogId]: {
            $apply: (dialog: Dialog) => {
              return { ...dialog, audioMessages: undefined };
            },
          },
        },
      },
    }),
};

export const reducer: MultiChatReducer<DialogModelState, CommonReducerPayload> = {
  [namespace]: handleActions(reducerMapping, initialState),
};
