import React, { memo, useMemo } from 'react';
import { FieldAttributes, FormikState } from 'formik';
import classNames from 'classnames';

import styles from './index.module.scss';

type FormValues = { [key: string]: any };

type Props = {
  field?: FieldAttributes<any>;
  form: FormikState<FormValues>;
  backgroundTheme?: 'light' | 'dark';
  className: string;
};

export const TextInput = memo((props: Props) => {
  const {
    field,
    form: { errors, touched },
    backgroundTheme = 'dark',
    className: externalClassName,
    ...rest
  } = props;
  const { name: fieldName } = field;

  const className = useMemo(() => {
    return classNames(
      styles.container,
      { [styles.containerDark]: backgroundTheme === 'dark' },
      { [styles.required]: errors[fieldName] && touched[fieldName] },
      externalClassName,
    );
  }, [backgroundTheme, errors, fieldName, touched, externalClassName]);

  return <input className={className} {...field} {...rest} />;
});
