import { Buffer } from 'buffer';

import { IHttpTransport, Response } from 'modules/api/transport/http.contracts';

import { IIdentityDomain } from './contracts';
import { IdentityDto } from './dto';

export class IdentityDomain implements IIdentityDomain {
  constructor(private readonly transport: IHttpTransport) {}

  signIn(email: string, password: string): Promise<IdentityDto> {
    return this.transport
      .get<IdentityDto>('/identity', {
        headers: {
          Authorization: this.getBasicAuthHeader(email, password),
        },
      })
      .then(this.parseIdentityResponse);
  }

  private getBasicAuthHeader = (email: string, password: string) => {
    const value = Buffer.from(`${email}:${password}`).toString('base64');

    return `Basic ${value}`;
  };

  private parseIdentityResponse(response: Response<IdentityDto>) {
    const token = response.headers['x-token'];

    if (!token) {
      throw new Error('Token is missing in headers');
    }

    return {
      ...response.data,
      token,
    };
  }
}
