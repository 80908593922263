import { useMemo } from 'react';

import { parseDialogId } from 'modules/utils';

import { useCurrentDialogId } from './use-current-dialog-id';

export const useCurrentDialogUsersIds = () => {
  const currentDialogId = useCurrentDialogId();

  // TODO: fix the parseDialogId return types. It shouldn't return the empty string ids
  const ids = useMemo(() => parseDialogId(currentDialogId), [currentDialogId]);

  return ids;
};
