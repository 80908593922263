import { getDialogAllMessagesLoaded } from 'modules/domain/dialog/selectors';
import { useRootSelector } from 'modules/hooks/redux';

export const useDialogAllMessagesLoaded = (dialogId: string) => {
  const dialogAllMessagesLoaded = useRootSelector(state =>
    getDialogAllMessagesLoaded(state, dialogId),
  );

  return dialogAllMessagesLoaded;
};
