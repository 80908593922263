import { useNavigate } from 'react-router-dom';
import React, { memo } from 'react';

import closeIcon from 'images/icon-close-big.svg';
import { testId } from 'modules/utils/test-id';
import { AppRoutes } from 'modules/navigation';

import styles from './index.module.css';

export const CloseButton = memo(() => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(AppRoutes.Chat)}
      className={styles.closeButton}
      {...testId('earnings-page-header-close-button')}
    >
      <img src={closeIcon} alt="close" />
    </div>
  );
});
