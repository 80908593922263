import React from 'react';

type Props = {
  className: string;
  onClick: () => void;
};

export const CloseIcon = (props: Props) => {
  const { onClick, className } = props;
  return (
    <svg
      className={className}
      onClick={onClick}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 3L3 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 9L3 3" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
