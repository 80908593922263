export enum AutoGeneratedAnswersUsage {
  Used = 'used',
  Edited = 'edited',
  NotUsed = 'not-used',
}

export enum AutoGeneratedAnswerOptionType {
  Valid = 'Valid',
  Trap = 'Trap',
  Gift = 'Gift',
  Photo = 'Photo',
  Icebreaker = 'PurPur',
}

export type AutoGeneratedAnswerOptionDto = {
  name: string;
  type: AutoGeneratedAnswerOptionType;
};

export type AutoGeneratedAnswersDto = {
  options: AutoGeneratedAnswerOptionDto[];
  botName: string;
};
