import React from 'react';
import classNames from 'classnames';

import { AuthForm } from 'modules/components/auth/auth-form';

import styles from './styles.module.scss';
import logo from './logo.gif';

export const AuthPage = React.memo(() => {
  return (
    <div className={classNames(styles.authPage, 'h-100')}>
      <img className={styles.logo} src={logo} alt="logo" />
      <h1 className={styles.title}>Sign In to Multichat</h1>
      <AuthForm />
    </div>
  );
});

AuthPage.displayName = 'AuthPage';
