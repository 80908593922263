import React, { useRef, useLayoutEffect, useState } from 'react';
import cn from 'classnames';

import { testId } from 'modules/utils';

import { TrapModal } from './trap-modal';
import styles from './styles.module.scss';

type Prop = {
  text: string;
  secondShortcutPart: number;
  onClick: () => void;
  trapModalVisible: boolean;
  onCloseTrapModal: () => void;
};

const OPTIONAL_MODAL_SIZE = 360;

export const CopilotOption = (prop: Prop) => {
  const { text, secondShortcutPart, onClick, trapModalVisible, onCloseTrapModal } = prop;

  const [potentialOverflow, setPotentialOverflow] = useState<boolean>(false);

  const optionRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const optionElement = optionRef.current;
    if (!optionElement) {
      return;
    }

    const containerElement = optionElement?.parentElement;

    if (!containerElement) {
      return;
    }

    const optionRect = optionElement.getBoundingClientRect();
    const containerRect = containerElement.getBoundingClientRect();

    const distance = containerRect.right - optionRect.left;

    if (distance < OPTIONAL_MODAL_SIZE) {
      setPotentialOverflow(true);
    } else {
      setPotentialOverflow(false);
    }
  }, []);

  return (
    <div className={styles.optionContainer} ref={optionRef}>
      <div
        className={cn(styles.option, { [styles.activeTrap]: trapModalVisible })}
        onClick={onClick}
        {...testId(`copilot-option-${secondShortcutPart}`)}
      >
        <div {...testId('copilot-option-text')} className={styles.optionText}>
          {text}
        </div>
        <div className={styles.hotkey} {...testId('copilot-option-hotkey')}>
          Alt + {secondShortcutPart}
        </div>
      </div>
      {trapModalVisible && <TrapModal onClose={onCloseTrapModal} dockToRight={potentialOverflow} />}
    </div>
  );
};
