import React, { memo } from 'react';

import { AnimatorHeader, AttendeeHeader } from 'modules/components/user-header';
import { useCurrentDialogUsersIds } from 'modules/domain/dialog/hooks';
import { BadgesAndSearch } from 'modules/components/badges-and-search';

import styles from './index.module.css';

export const MultichatHeader = memo(() => {
  const { animatorId, attendeeId } = useCurrentDialogUsersIds();

  return (
    <div className={styles.container}>
      <AttendeeHeader animatorId={animatorId} attendeeId={attendeeId} />
      <BadgesAndSearch />
      <AnimatorHeader animatorId={animatorId} attendeeId={attendeeId} />
    </div>
  );
});
