import { SpotifyMatchResult } from './types';

export enum SpotifyMatchActions {
  SetMatchResult = 'spotify-match.set-match-result',
}

export type SetMatchResultPayload = { result: SpotifyMatchResult; dialogId: string };
type SetMatchResultAction = { type: SpotifyMatchActions; payload: SetMatchResultPayload };

export const setSpotifyMatchResult = (
  dialogId: string,
  result: SpotifyMatchResult,
): SetMatchResultAction => ({
  type: SpotifyMatchActions.SetMatchResult,
  payload: { dialogId, result },
});
