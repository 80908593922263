import { memo, useEffect } from 'react';
import { useLocation } from 'react-router';

import { AppRoutes } from 'modules/navigation';

import { ResponseTimeStorage } from './storage';

const routes = [AppRoutes.Login, AppRoutes.Offline];

export const ExtendedTimerService = memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (routes.some(route => pathname.startsWith(route))) {
      ResponseTimeStorage.clear();
    }
  }, [pathname]);

  return null;
});
