import { DialogMessageDto, MessageDeliveryStatus } from 'modules/api/dto';

const isUnansweredUnpaidTextMessage = (message: DialogMessageDto, attendeeId: string) =>
  message.status === MessageDeliveryStatus.UNKNOWN &&
  !message.meta.reference &&
  message.sender === attendeeId;

export function findFirstUnansweredUnpaidTextMessage(
  animatorId: string,
  attendeeId: string,
  attendeeMessagesWithUnpaid: DialogMessageDto[],
): DialogMessageDto | null {
  // backend returns messages sorted ascending (oldest first)
  // so doing the reverse we make it sorted with the newest first
  attendeeMessagesWithUnpaid.reverse();

  const lastAnimatorMessageIndex = attendeeMessagesWithUnpaid.findIndex(
    m => m.sender === animatorId,
  );

  if (lastAnimatorMessageIndex > 0) {
    const lastAttendeeMessages = attendeeMessagesWithUnpaid.slice(0, lastAnimatorMessageIndex);

    // reverse last attendee messages so it is sorted with the oldest first again
    lastAttendeeMessages.reverse();

    const firstUnansweredUnpaidTextMessage = lastAttendeeMessages.find(message =>
      isUnansweredUnpaidTextMessage(message, attendeeId),
    );

    if (firstUnansweredUnpaidTextMessage) {
      return {
        ...firstUnansweredUnpaidTextMessage,
        meta: { ...firstUnansweredUnpaidTextMessage.meta, unansweredUnpaid: true },
      };
    }
  }

  return null;
}
