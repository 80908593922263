import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { authLogout } from './actions';

export const useAuthActions = () => {
  const dispatch = useDispatch();

  const logout = useCallback(() => dispatch(authLogout()), [dispatch]);

  return {
    logout,
  };
};
