export enum DialogSentMediaType {
  chat = 'chat',
  custom = 'custom',
}

export type DialogSentMediaDto = {
  baseName: string;
  type: DialogSentMediaType;
};

export type DialogMediaStateDto = {
  /**
   * Whether media allowed or not in the dialog
   */
  result: boolean;
  sent: DialogSentMediaDto[];
};

export type UploadDialogMediaResponse =
  | string[]
  | {
      basename: string;
    }
  | { basenames: string[] };
