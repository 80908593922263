import { FullKPIDto } from 'modules/api/dto';

export type KPIRecord = {
  kpiValue: number;
  kpiEffect: string;
};

// ideally I would get this data by api request, however it is just hardcoded on the client for now
export const KPI_WEIGHTS_MAP: Record<keyof FullKPIDto, number> = {
  textSent: 1,
  textReplies: 2,
  giftSent: 0.5,
  giftReplies: 1.5,
  photoSent: 0.5,
  photoReplies: 1.5,
  quickReplies: 1,
};

// ideally I would get this data by api request, however it is just hardcoded on the client for now
export const KPI_VALUE_EFFECTS: KPIRecord[] = [
  {
    kpiValue: -8,
    kpiEffect: '-22%',
  },
  {
    kpiValue: -6,
    kpiEffect: '-15%',
  },
  {
    kpiValue: -4,
    kpiEffect: '-8%',
  },
  {
    kpiValue: -2,
    kpiEffect: '-3%',
  },
  {
    kpiValue: 0,
    kpiEffect: '0%',
  },
  {
    kpiValue: 2,
    kpiEffect: '+3%',
  },
  {
    kpiValue: 4,
    kpiEffect: '+8%',
  },
  {
    kpiValue: 6,
    kpiEffect: '+15%',
  },
  {
    kpiValue: 8,
    kpiEffect: '+22%',
  },
];
