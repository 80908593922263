import React, { memo } from 'react';

import { ExtendedTimerService } from '../extended-timer/service';

export const Services = memo(() => {
  return (
    <>
      <ExtendedTimerService />
    </>
  );
});
