import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useTimerRef } from 'modules/hooks';

export const useChatScrollContainer = (onScroll: (e: Event) => void) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollContentRef = useRef<HTMLDivElement | null>(null);

  const scrollActive = useRef(false);
  const timeout = useTimerRef();

  const setScrollContainerRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref !== scrollContainerRef.current) {
        scrollContainerRef.current = ref;

        if (scrollContainerRef.current) {
          scrollContainerRef.current.onscroll = e => {
            clearTimeout(timeout.current);

            scrollActive.current = true;

            onScroll(e);

            timeout.current = window.setTimeout(() => {
              scrollActive.current = false;
            }, 100);
          };
        }
      }
    },
    [onScroll, timeout],
  );

  const previousScrollHeight = useRef(0);
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(() => {
        if (scrollContainerRef.current && scrollContentRef.current) {
          const heightDelta =
            scrollContainerRef.current.scrollHeight - previousScrollHeight.current;

          if (heightDelta > 0 && !scrollActive.current) {
            scrollContainerRef.current.scrollTop =
              scrollContainerRef.current.scrollTop + heightDelta;
          }
          previousScrollHeight.current = scrollContainerRef.current.scrollHeight;
        }
      }),
    [],
  );

  useEffect(
    () => () => {
      resizeObserver.disconnect();
    },
    [resizeObserver],
  );

  const setScrollContentRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref !== scrollContentRef.current) {
        previousScrollHeight.current = scrollContainerRef.current?.scrollHeight || 0;

        if (scrollContentRef.current) {
          resizeObserver.unobserve(scrollContentRef.current);
        }

        scrollContentRef.current = ref;

        if (scrollContentRef.current) {
          resizeObserver.observe(scrollContentRef.current);
        }
      }
    },
    [resizeObserver],
  );

  const scrollToBottom = useCallback(() => {
    if (scrollActive.current) {
      return;
    }

    scrollContainerRef.current?.scroll({
      top: scrollContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  return { setScrollContainerRef, setScrollContentRef, scrollToBottom };
};
