import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'modules/domain/dialog/actions';
import { getDialogId, parseDialogId, useLogger } from 'modules/utils';
import { useApi } from 'modules/api';
import { DialogNotesOldDto } from 'modules/api/dto';

import { useDialogAnalytics } from './use-dialog-analytics';

export const useDialogAnimatorApi = () => {
  const dispatch = useDispatch();
  const { logError } = useLogger('useDialogAnimatorApi');
  const { dialogsNotes: dialogsNotesApi, animators: animatorsApi } = useApi();
  const { trackAnimatorStoryTold } = useDialogAnalytics();

  const loadAnimatorPreferences = useCallback(
    async (animatorId: string, attendeeId: string) => {
      const dialogId = getDialogId(animatorId, attendeeId);

      try {
        const preferences = await animatorsApi.getPreferences(animatorId);
        dispatch(actions.setAnimatorPreferences(dialogId, preferences));
      } catch (error) {
        logError('fetchAnimatorPreferences error', { dialogId, animatorId, error });
      }
    },
    [dispatch, animatorsApi, logError],
  );

  const loadAnimatorStoryTold = useCallback(
    async (animatorId: string, attendeeId: string) => {
      const dialogId = getDialogId(animatorId, attendeeId);

      try {
        const notesOld = await dialogsNotesApi.getDialogNotesOld(animatorId, attendeeId);

        const animatorStoryTold = notesOld.storyTold
          ? (JSON.parse(notesOld.storyTold) as boolean)
          : false;

        dispatch(actions.setAnimatorStoryTold(dialogId, animatorStoryTold));
      } catch (error) {
        logError('loadAnimatorStoryTold error', { dialogId, error });
      }
    },
    [dispatch, dialogsNotesApi, logError],
  );

  const updateAnimatorStoryTold = useCallback(
    async (dialogId: string, animatorStoryTold: boolean) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      const payload: DialogNotesOldDto = { storyTold: String(animatorStoryTold) };
      try {
        await dialogsNotesApi.patchDialogNotesOld(animatorId, attendeeId, payload);

        if (animatorStoryTold) {
          trackAnimatorStoryTold(animatorId, attendeeId);
        }

        dispatch(actions.setAnimatorStoryTold(dialogId, animatorStoryTold));
      } catch (error) {
        logError('saveAnimatorStoryTold error', { dialogId, payload, error });
      }
    },
    [dispatch, dialogsNotesApi, logError, trackAnimatorStoryTold],
  );

  return {
    loadAnimatorPreferences,
    loadAnimatorStoryTold,
    updateAnimatorStoryTold,
  };
};
