import { IPlayerControls, PlayEndedCb } from './contracts';

export class PlayerControls implements IPlayerControls {
  private audioEl: HTMLAudioElement | null = null;

  constructor() {}

  play(src: string, onEnded: PlayEndedCb): void {
    if (!this.audioEl) {
      this.audioEl = new Audio();
    }
    if (src !== this.audioEl.src) {
      if (!this.audioEl.paused) {
        this.audioEl.pause();
      }
      this.audioEl.src = src;
      this.audioEl.load();
    }
    if (this.audioEl.paused) {
      this.audioEl.play();
      this.audioEl.onended = () => onEnded();
      this.audioEl.onerror = err => onEnded(err);
    }
  }

  pause(src: string): void {
    if (this.audioEl && src === this.audioEl.src) {
      this.audioEl.pause();
    }
  }

  stop(src: string): void {
    if (this.audioEl && src === this.audioEl.src) {
      this.audioEl.pause();
      this.audioEl.src = '';
      this.audioEl.onended = null;
      this.audioEl.onerror = null;
    }
  }
}
