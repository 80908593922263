export enum SpotifyScoreLevel {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
}

export type SpotifyMatchScoreDto = {
  level: SpotifyScoreLevel;
  score: number;
};

export type SpotifyTopContentDto = {
  artists: string[];
  genres: string[];
  tracks: string[];
};

export type SpotifyTopContentResponseDto = {
  top: SpotifyTopContentDto;
};
