import { EarningsInfo } from '../model';
import { BonusProgram } from '../model/types';

const countAllBonusesInBonusProgram = ({ bonuses }: BonusProgram) =>
  bonuses.reduce((total, bonus) => (bonus.isReached ? total + 1 : total), 0);

const countAllBonusesInBonusPrograms = (bonusPrograms: BonusProgram[] | undefined) => {
  if (!bonusPrograms) {
    return 0;
  }

  return bonusPrograms.reduce(
    (total, bonusProgram) => total + countAllBonusesInBonusProgram(bonusProgram),
    0,
  );
};

export const countAllBonuses = (earnings: EarningsInfo | undefined) => {
  if (!earnings) {
    return 0;
  }

  const {
    firstPurchaseBonus,
    mediaViewBonus,
    answeredFollowUpBonus,
    answeredUnpaidBonus,
    giftsReplyBonus,
    bonusPrograms,
  } = earnings;

  const bonusesCountInBonusPrograms = countAllBonusesInBonusPrograms(bonusPrograms);

  const bonusesCount = [
    firstPurchaseBonus,
    mediaViewBonus,
    answeredUnpaidBonus,
    answeredFollowUpBonus,
    giftsReplyBonus,
  ].reduce((acc, bonus) => acc + (bonus?.bonusesCount || 0), bonusesCountInBonusPrograms);

  return bonusesCount;
};
