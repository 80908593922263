import { reducer } from './reducer';
import { actions } from './actions';
import { selectors } from './selectors';
import * as hooks from './hooks';

export const UserModel = {
  reducer,
  actions,
  selectors,
  hooks,
};
