import { SpotifyScoreLevel } from 'modules/api/dto';

export { SpotifyScoreLevel };

export type SpotifyMatchResult = {
  score: number;
  level: SpotifyScoreLevel;
  artists: string[];
  genres: string[];
  tracks: string[];
};

export const namespace = 'spotifyMatches' as const;

export type SpotifyMatchState = Record<string, SpotifyMatchResult>;
