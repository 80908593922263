import React, { memo } from 'react';

import { useAnimatorLocation } from 'modules/domain/users/hooks';

import { UserHeaderBase } from './base';

type Props = {
  attendeeId: string;
  animatorId: string;
};

export const AnimatorHeader = memo((props: Props) => {
  const { animatorId, attendeeId } = props;

  const location = useAnimatorLocation(animatorId, attendeeId);

  return (
    <UserHeaderBase
      type="animator"
      animatorId={animatorId}
      attendeeId={attendeeId}
      location={location}
    />
  );
});
