import { OperatorCommissionDto, OperatorDto } from 'modules/api/dto';

import { OperatorCommissionInfo, OperatorInfo } from './model';

export const mapOperatorDto = (operatorDto: OperatorDto): OperatorInfo => ({
  isReadyForExplicit: operatorDto.isReadyForExplicit,
  operatorId: operatorDto.operatorId,
});

export const mapOperatorCommissionDto = (
  commissionDto: OperatorCommissionDto,
): OperatorCommissionInfo => ({
  commission: commissionDto.Commission,
  trapPenalty: commissionDto.TrapPenalty ?? 0,
});
