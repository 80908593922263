import { configureStore } from '@reduxjs/toolkit';

import { reducer as fastAnswersReducer } from 'modules/domain/fast-answers/reducer';
import { UserModel } from 'modules/domain/users';
import { DialogModel } from 'modules/domain/dialog';
import { reducer as mediaReducer } from 'modules/domain/media/reducer';
import {
  reducer as dictionariesReducer,
  namespace as dictionariesNamespace,
} from 'modules/domain/dictionaries/model';
import { ModalModel } from 'modules/domain/modals';
import { reducer as spotifyMatchReducer } from 'modules/domain/spotify-match/reducer';
import {
  reducer as earningsReducer,
  namespace as earningsNamespace,
} from 'modules/domain/earnings/model';
import { reducer as giftsReducer, namespace as giftsNamespace } from 'modules/domain/gifts/model';
import {
  namespace as operatorNamespace,
  reducer as operatorReducer,
} from 'modules/domain/operator/model';
import {
  reducer as calendarReducer,
  namespace as calendarNamespace,
} from 'modules/domain/calendar/model';

export const configureReduxStore = () => {
  const store = configureStore({
    reducer: {
      ...UserModel.reducer,
      ...DialogModel.reducer,
      ...mediaReducer,
      [dictionariesNamespace]: dictionariesReducer,
      ...ModalModel.reducer,
      ...fastAnswersReducer,
      ...spotifyMatchReducer,
      [earningsNamespace]: earningsReducer,
      [giftsNamespace]: giftsReducer,
      [operatorNamespace]: operatorReducer,
      [calendarNamespace]: calendarReducer,
    },
  });

  return store;
};
