import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';

export const useDispatchAction = <A extends (...args: any[]) => UnknownAction>(action: A) => {
  const dispatch = useDispatch();

  const dispatchAction = useCallback(
    (...args: Parameters<A>) => {
      dispatch(action(...args));
    },
    [dispatch, action],
  );

  return dispatchAction;
};
