import { useCallback } from 'react';

import { useDispatchAction } from 'modules/hooks/redux';
import { useApi } from 'modules/api';
import { GetDialogDaysWithMessagesQueryParams } from 'modules/api/payload';
import { parseDialogId, useLogger } from 'modules/utils';

import { mapCalendarDaysWithMessagesDto } from '../model/adapter';
import { setDaysWithMessages as setDaysWithMessagesAction } from '../model';
import { getCalendarStateId } from '../helpers';

export const useCalendarApi = () => {
  const { dialogs: dialogsApi } = useApi();
  const { logError } = useLogger('useDaysWithMessages');
  const setDaysWithMessages = useDispatchAction(setDaysWithMessagesAction);

  const loadDialogDaysWithMessages = useCallback(
    async (dialogId: string, params: GetDialogDaysWithMessagesQueryParams) => {
      try {
        const { animatorId, attendeeId } = parseDialogId(dialogId);
        const dto = await dialogsApi.getDialogDaysWithMessages(animatorId, attendeeId, params);
        const daysWithMessages = mapCalendarDaysWithMessagesDto(dto);

        setDaysWithMessages({ stateId: getCalendarStateId(dialogId, params), daysWithMessages });
      } catch (error) {
        logError('Failed to fetch days with messages', {
          error,
          dialogId,
          params,
        });
      }
    },
    [dialogsApi, logError, setDaysWithMessages],
  );

  return { loadDialogDaysWithMessages };
};
