import { useSelector } from 'react-redux';

import { RootState } from 'modules/App/store/root-state';

import { getMediaMessage } from '../selectors';

export const useDialogMediaMessage = (dialogId: string, messageTag: string) => {
  return useSelector((state: RootState) => getMediaMessage(state, dialogId, messageTag));
};

export const useDialogMediaMessageProgress = (dialogId: string, messageTag: string) => {
  return useDialogMediaMessage(dialogId, messageTag)?.progress ?? null;
};
