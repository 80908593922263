import EventEmitter from 'eventemitter3';

import { EventListener, Unsubscribe } from 'modules/domain/common/types';

import {
  AudioEvent,
  AudioEventPayload,
  AudioFileStatus,
  IAudioFile,
  IPlayerControls,
  LoadFileFn,
} from './contracts';

type AudioFileStatusInternal =
  | AudioFileStatus
  // loading and play means that sound playing will start automatically after the loading
  | 'loadingAndPlay';

export class AudioFile implements IAudioFile {
  private eventEmitter = new EventEmitter<AudioEvent, AudioEventPayload[AudioEvent]>();
  private src = '';

  private statusInternal: AudioFileStatusInternal = AudioFileStatus.pending;
  get status(): AudioFileStatus {
    if (this.statusInternal === 'loadingAndPlay') {
      return AudioFileStatus.loading;
    }
    return this.statusInternal;
  }

  constructor(
    readonly basename: string,
    private readonly loadFile: LoadFileFn,
    private readonly playerControls: IPlayerControls,
  ) {}

  addEventListener<T extends AudioEvent>(
    type: T,
    listener: EventListener<AudioEventPayload[T]>,
  ): Unsubscribe {
    this.eventEmitter.addListener(type, listener);

    return () => {
      this.eventEmitter.removeListener(type, listener);
    };
  }

  play(): void {
    if (this.internalStatusEqualsTo(AudioFileStatus.playing)) {
      return;
    }

    this.eventEmitter.emit(AudioEvent.PlayPressed);

    if (this.internalStatusEqualsTo([AudioFileStatus.pause, AudioFileStatus.ready])) {
      this._play();
      return;
    }

    this.loadAndPlay();
  }

  pause(): void {
    this.playerControls.pause(this.src);
    this.setInternalStatus(AudioFileStatus.pause);
  }

  stop(): void {
    this.playerControls.stop(this.src);

    if (this.internalStatusEqualsTo('loadingAndPlay')) {
      this.setInternalStatus(AudioFileStatus.loading);
    } else {
      this.setInternalStatus(AudioFileStatus.ready);
    }
  }

  private async loadAndPlay() {
    if (this.internalStatusEqualsTo('loadingAndPlay')) {
      return;
    }

    if (this.internalStatusEqualsTo(AudioFileStatus.loading)) {
      // if the file was still loading and user want's to listen it again
      this.setInternalStatus('loadingAndPlay');

      return;
    }

    this.setInternalStatus('loadingAndPlay');

    try {
      const src = await this.loadFile();
      this.src = src;

      if (this.internalStatusEqualsTo('loadingAndPlay')) {
        this._play();
      } else {
        this.setInternalStatus(AudioFileStatus.ready);
      }
    } catch (error) {
      // the error is handled inside the loadFile func
      this.setInternalStatus(AudioFileStatus.error);
    }
  }

  private _play = () => {
    this.setInternalStatus(AudioFileStatus.playing);

    this.playerControls.play(this.src, err => {
      this.setInternalStatus(err ? AudioFileStatus.error : AudioFileStatus.ready);
    });

    return;
  };

  private setInternalStatus = (status: AudioFileStatusInternal) => {
    this.statusInternal = status;

    this.eventEmitter.emit(AudioEvent.StatusChange, {
      status: this.status, // it's important to use public status here
    });
  };

  private internalStatusEqualsTo = (
    statuses: AudioFileStatusInternal | AudioFileStatusInternal[],
  ) =>
    Array.isArray(statuses)
      ? statuses.includes(this.statusInternal)
      : statuses === this.statusInternal;
}
