import { useCallback, useRef, useState } from 'react';

import { ScrollPosition } from '../context';

const EPSILON = 1;
const TOP_OFFSET = 75;

export const useChatScrollHandler = () => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>('bottom');

  const scrollPositionPrev = useRef(scrollPosition);

  const scrollHandler = useCallback((event: Event) => {
    const { scrollHeight, clientHeight, scrollTop } = event.currentTarget as HTMLDivElement;

    let currentScrollPosition: ScrollPosition = 'middle';

    // the pixels for clientHeight/scrollTop/scrollHeight are rounded. We should adjust for rounding errors
    if (Math.abs(clientHeight + scrollTop - scrollHeight) < EPSILON) {
      currentScrollPosition = 'bottom';
    } else if (scrollHeight > clientHeight && scrollTop <= TOP_OFFSET) {
      currentScrollPosition = 'top';
    }

    if (currentScrollPosition !== scrollPositionPrev.current) {
      setScrollPosition(currentScrollPosition);
      scrollPositionPrev.current = currentScrollPosition;
    }
  }, []);

  return {
    scrollHandler,
    scrollPosition,
  };
};
