import { getDialogUnansweredUnpaidState } from 'modules/domain/dialog/selectors';
import { useRootSelector } from 'modules/hooks/redux';

export const useDialogUnansweredUnpaidState = (dialogId: string) => {
  const dialogUnansweredUnpaidState = useRootSelector(state =>
    getDialogUnansweredUnpaidState(state, dialogId),
  );

  return dialogUnansweredUnpaidState;
};
