import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';

import { EntityMap, MultiChatReducer } from 'modules/domain/common/types';
import { AuthActions } from 'modules/domain/auth/actions';

import {
  SetUserPhotosPayload,
  MediaActions,
  SetDialogMediaStatePayload,
  ResetDialogMediaStatePayload,
} from './actions';
import { DialogMediaState, MediaState } from './types';
import { namespace } from './constants';

type CommonMediaPayload = SetUserPhotosPayload &
  SetDialogMediaStatePayload &
  ResetDialogMediaStatePayload;

type MediaReducerMapValue<Payload> = ReducerMapValue<MediaState, Payload>;

interface CustomReducerMap extends ReducerMap<MediaState, CommonMediaPayload> {
  [MediaActions.SetUserPhotos]: MediaReducerMapValue<SetUserPhotosPayload>;
  [MediaActions.SetDialogMediaState]: MediaReducerMapValue<SetDialogMediaStatePayload>;
  [MediaActions.ResetDialogMediaState]: MediaReducerMapValue<ResetDialogMediaStatePayload>;
}

const initialState: MediaState = {
  userPhotos: { byId: {} },
  dialogMedia: { byId: {} },
};

const reducerMapping: CustomReducerMap = {
  [AuthActions.Logout]: state => update(state, { $set: initialState }),
  [MediaActions.SetUserPhotos]: (state, { payload: { userId, photos } }) =>
    update(state, {
      userPhotos: {
        byId: {
          [userId]: {
            $set: photos,
          },
        },
      },
    }),
  [MediaActions.SetDialogMediaState]: (state, { payload: { dialogId, input } }) =>
    update(state, {
      dialogMedia: {
        byId: {
          [dialogId]: {
            $set: input,
          },
        },
      },
    }),
  [MediaActions.ResetDialogMediaState]: (state, { payload: { dialogId } }) =>
    update(state, {
      dialogMedia: {
        byId: {
          $apply: (entities: EntityMap<DialogMediaState>) => {
            const newEntities = { ...entities };

            delete newEntities[dialogId];

            return newEntities;
          },
        },
      },
    }),
};

export const reducer: MultiChatReducer<MediaState, CommonMediaPayload> = {
  [namespace]: handleActions(reducerMapping, initialState),
};
