import url from 'url';

import { AppConfig } from '../config';

import crc32 from './crc32';

/**
 * Шардирование url
 */
export function getShardUri(path: string, shardingConfig: AppConfig['sharding']) {
  const uri = url.parse(path);
  const shard = parseInt(crc32(path), 16) % shardingConfig.shards;
  const prefix = shardingConfig.prefix || '';
  const base = shardingConfig.host;

  uri.host = prefix + shard + '.' + base;

  uri.slashes = true;

  return url.format(uri);
}
