import { earningsModel } from './model';

const { reducer, reducerPath, actions, selectors } = earningsModel;

export const { setEarnings, setKpi } = actions;
export const { getEarnings, getKpi } = selectors;
export { reducer, reducerPath as namespace };

export type { EarningsInfo, KpiFullInfo, EarningsModelState } from './types';
export { DatesRange } from './types';
export { mapEarningsDto, mapKpiDto } from './adapter';
