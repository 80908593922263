import { useCallback } from 'react';

import { useCurrentDialogId, useDialogMessagesApi } from 'modules/domain/dialog/hooks';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { ModalModel } from 'modules/domain/modals';
import { createMessageReference } from 'modules/utils';
import { MessageType } from 'modules/domain/dialog/types';
import { NetworkErrorStatus, HttpError } from 'modules/api';

import { AudioMessageTemplate } from '../types';

import { useAudioMessagesTemplates } from './use-audio-messages-templates';

export const useSendAudioMessageTemplate = () => {
  const { loadAudioMessagesTemplates } = useAudioMessagesTemplates();
  const dialogId = useCurrentDialogId();
  const { closeModal } = ModalModel.hooks.useModal();

  const { sendMessage } = useDialogMessagesApi(dialogId);

  const sendAudioMessageTemplate = useCallback(
    async (template: AudioMessageTemplate) => {
      closeModal();

      try {
        await sendMessage({
          reference: createMessageReference(MessageType.AUDIO, template.basename),
          meta: {
            audioMessageId: template.id,
            transcription: { text: template.text },
          },
        });
      } catch (error) {
        const errorMessage =
          (error as HttpError).status === NetworkErrorStatus.Conflict
            ? "You can't send audio messages to this user at the moment. Please try again later."
            : 'Failed to send an audio message. Please check your connection and try again later';

        showChatErrorToast(errorMessage);
      }
      loadAudioMessagesTemplates();
    },
    [sendMessage, closeModal, loadAudioMessagesTemplates],
  );

  return sendAudioMessageTemplate;
};
