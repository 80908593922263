import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useOperatorId } from 'modules/domain/auth';
import { NetworkErrorStatus, HttpError, useApi } from 'modules/api';
import { parseDialogId, getMessageTypeFromFile, useLogger } from 'modules/utils';
import { DialogMessageBaseDto } from 'modules/api/dto';
import { SendMessagePayload } from 'modules/api/payload';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { MessageType, SendDialogMessageData } from 'modules/domain/dialog/types';
import {
  setApiMessageSent,
  setApiSendMessageFailure,
  setApiUploadMediaFailure,
  setApiUploadMediaProgress,
  setApiUploadMediaStart,
  setApiUploadMediaSuccess,
  setDialogMessages,
  setDialogAllMessagesLoaded,
} from 'modules/domain/dialog/actions';
import { resetDialogMediaState } from 'modules/domain/media/actions';

import { useDialogAllMessagesLoaded } from '../use-dialog-selectors';
import { useDialogTypedMessage } from '../use-dialog-typed-message';
import { useDialogAnalytics } from '../use-dialog-analytics';

import {
  createBaseMessageDraft,
  findFirstUnansweredUnpaidTextMessage,
  getPhotoReference,
} from './helpers';

const UNANSWERED_UNPAID_PAGE_SIZE = 30;

export const useDialogMessagesApi = (dialogId: string) => {
  // Hooks
  const dispatch = useDispatch();
  const operatorId = useOperatorId();
  const { dialogsMessages: dialogsMessagesApi, dialogsMedia: dialogsMediaApi } = useApi();
  const { fastAnswerInTypedMessage } = useDialogTypedMessage();
  const { trackDialogMessageSendingWithFastAnswer } = useDialogAnalytics();
  const { logError } = useLogger('useDialogMessagesApi');

  const allMessagesLoaded = useDialogAllMessagesLoaded(dialogId);

  // States
  const [messageSending, setMessageSending] = useState(false);

  const { animatorId, attendeeId } = parseDialogId(dialogId);

  const fetchDialogMessages = useCallback(
    async (count: number, skip: number) => {
      if (allMessagesLoaded && skip !== 0) {
        return;
      }

      try {
        const messages = await dialogsMessagesApi.getDialogMessages(animatorId, attendeeId, {
          select: count,
          omit: skip,
        });

        if (messages.length < count) {
          dispatch(setDialogAllMessagesLoaded(dialogId));
        }

        dispatch(setDialogMessages(dialogId, messages));
      } catch (error) {
        logError(`Failed to fetch dialog messages. DialogId: ${dialogId}`, { error });
      }
    },
    [allMessagesLoaded, attendeeId, animatorId, dialogsMessagesApi, dispatch, dialogId, logError],
  );

  const sendMessageBase = useCallback(
    async (data: SendDialogMessageData, draft?: DialogMessageBaseDto) => {
      const messageDraft: DialogMessageBaseDto = {
        ...createBaseMessageDraft(animatorId, attendeeId, data.text),
        ...draft,
        meta: {
          ...draft?.meta,
          ...data.meta,
        },
      };

      if (data.reference) {
        messageDraft.meta.reference = data.reference;
      }

      try {
        setMessageSending(true);

        dispatch(setApiMessageSent(dialogId, messageDraft));

        const messageBody: SendMessagePayload = {
          tag: messageDraft.tag,
          text: messageDraft.text,
          reference: data.reference,
          instant: 1,
          operator: operatorId,
          meta: data.meta,
        };

        await dialogsMessagesApi.sendMessage(animatorId, attendeeId, messageBody);

        if (fastAnswerInTypedMessage) {
          trackDialogMessageSendingWithFastAnswer(
            attendeeId,
            messageBody.text,
            fastAnswerInTypedMessage,
          );
        }
      } catch (error) {
        logError('Failed to send a message', { error, message: messageDraft, dialogId });

        dispatch(
          setApiSendMessageFailure(
            dialogId,
            messageDraft.tag,
            (error as HttpError).status === NetworkErrorStatus.Conflict,
          ),
        );

        throw error;
      } finally {
        setMessageSending(false);
      }
    },
    [
      dialogsMessagesApi,
      animatorId,
      dialogId,
      dispatch,
      fastAnswerInTypedMessage,
      attendeeId,
      operatorId,
      trackDialogMessageSendingWithFastAnswer,
      logError,
    ],
  );

  const sendPhotoMessageBase = useCallback(
    async (basename: string, draft?: DialogMessageBaseDto) => {
      const reference = getPhotoReference(basename);

      await sendMessageBase({ reference }, draft);

      // TODO: once again relying on a timer:(
      // it takes some time at the backend side to update media state after message sent
      // replace it with the WS event listener once we have Websocket implemented
      setTimeout(() => {
        dispatch(resetDialogMediaState(dialogId));
      }, 500);
    },
    [sendMessageBase, dispatch, dialogId],
  );

  const sendMessage = useCallback(
    (data: SendDialogMessageData) => sendMessageBase(data),
    [sendMessageBase],
  );

  const sendPhotoMessage = useCallback(
    (basename: string) => sendPhotoMessageBase(basename),
    [sendPhotoMessageBase],
  );

  const sendPhotoMessageWithFileUpload = useCallback(
    async (file: File) => {
      const mediaUrl = URL.createObjectURL(file);
      const mediaType = getMessageTypeFromFile(file);

      if (mediaType !== MessageType.PHOTO) return;

      const draftMessage: DialogMessageBaseDto = {
        ...createBaseMessageDraft(animatorId, attendeeId),
        meta: {
          localPhotoUri: mediaUrl,
        },
      };

      dispatch(setApiUploadMediaStart(dialogId, draftMessage));

      try {
        const basename = await dialogsMediaApi.uploadDialogMedia(
          animatorId,
          attendeeId,
          file,
          progress => {
            dispatch(setApiUploadMediaProgress(dialogId, draftMessage.tag, progress));
          },
        );

        dispatch(setApiUploadMediaSuccess(dialogId, draftMessage.tag));

        await sendPhotoMessageBase(basename, draftMessage);
      } catch (error) {
        logError('Failed to send a photo message with file upload', {
          error,
          message: draftMessage,
          dialogId,
        });

        dispatch(setApiUploadMediaFailure(dialogId, draftMessage.tag));

        showChatErrorToast(
          'Failed to send a photo. Please check your connection and try again later',
        );
      }
    },
    [animatorId, attendeeId, dispatch, dialogsMediaApi, sendPhotoMessageBase, dialogId, logError],
  );

  const fetchUnansweredUnpaidDialogMessage = useCallback(async () => {
    try {
      // we switch attendeeId and animatorId here, so that attendeeId is a sender in this case
      const messages = await dialogsMessagesApi.getDialogMessages(attendeeId, animatorId, {
        select: UNANSWERED_UNPAID_PAGE_SIZE,
        omit: 0,
        withUnsent: true,
      });

      const firstUnansweredUnpaidMessage = findFirstUnansweredUnpaidTextMessage(
        animatorId,
        attendeeId,
        messages,
      );

      if (firstUnansweredUnpaidMessage) {
        dispatch(setDialogMessages(dialogId, [firstUnansweredUnpaidMessage]));
      }
    } catch (error) {
      logError('Failed to fetchUnansweredUnpaidDialogMessage', { error });
    }
  }, [attendeeId, animatorId, dialogsMessagesApi, dispatch, dialogId, logError]);

  return {
    sendMessage,
    sendPhotoMessage,
    sendPhotoMessageWithFileUpload,
    fetchDialogMessages,
    fetchUnansweredUnpaidDialogMessage,
    messageSending,
  };
};
