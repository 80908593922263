import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { parseDialogId, useLogger } from 'modules/utils';
import { SearchedDialogMessageDto } from 'modules/api/dto';
import { SearchMessagesDirection } from 'modules/domain/dialog/types';
import { actions } from 'modules/domain/dialog/actions';
import { useChatScrollerActions } from 'modules/components/chat/chat-scroller-context';

import { useDialogMessages, useDialogMessagesSearchState } from './use-dialog-selectors';
import { useDialogMessagesApi } from './use-dialog-messages-api';

const MIN_SEARCH_PHRASE_LENGTH = 3;

export const useDialogMessagesSearch = (dialogId: string) => {
  const dispatch = useDispatch();
  const { fetchDialogMessages } = useDialogMessagesApi(dialogId);
  const { dialogsMessages: dialogsMessagesApi } = useApi();
  const { logError } = useLogger('useDialogMessagesApi');
  const { scrollToMessage } = useChatScrollerActions();

  const messages = useDialogMessages(dialogId);
  const messagesSearchState = useDialogMessagesSearchState(dialogId);

  const { animatorId, attendeeId } = parseDialogId(dialogId);

  const messagesCount = messages.length;

  const onSearchedMessageActiveIndexChange = useCallback(
    async (searchedMessages: SearchedDialogMessageDto[], activeIndex: number) => {
      const { n, messageId } = searchedMessages[activeIndex];

      const requiredMessagesCount = n - messagesCount;

      if (requiredMessagesCount > 0) {
        await fetchDialogMessages(requiredMessagesCount, messagesCount);
      }

      scrollToMessage(messageId);
    },
    [fetchDialogMessages, messagesCount, scrollToMessage],
  );

  const switchSearchMessages = useCallback(
    (direction: SearchMessagesDirection) => {
      if (!messagesSearchState) {
        return;
      }

      const { currentSearchedMessageIndex, messages } = messagesSearchState;

      const newIndex = {
        up: currentSearchedMessageIndex + 1,
        down: currentSearchedMessageIndex - 1,
      }[direction];

      dispatch(actions.switchSearchedMessageIndex(dialogId, newIndex));

      return onSearchedMessageActiveIndexChange(messages, newIndex);
    },
    [dialogId, dispatch, messagesSearchState, onSearchedMessageActiveIndexChange],
  );

  const searchMessages = useCallback(
    async (phrase: string) => {
      if (phrase.length < MIN_SEARCH_PHRASE_LENGTH) {
        dispatch(actions.clearSearchedMessages(dialogId));
        return;
      }

      try {
        const response = await dialogsMessagesApi.searchDialogMessages(
          animatorId,
          attendeeId,
          phrase,
        );
        dispatch(actions.apiSearchMessagesSuccess(dialogId, phrase, response));

        if (response.messages.length) {
          await onSearchedMessageActiveIndexChange(response.messages, 0);
        }
      } catch (error) {
        logError('Failed to search dialog messages', { error, phrase });
      }
    },
    [
      dialogsMessagesApi,
      animatorId,
      dialogId,
      dispatch,
      onSearchedMessageActiveIndexChange,
      attendeeId,
      logError,
    ],
  );

  return {
    searchMessages,
    switchSearchMessages,
  };
};
