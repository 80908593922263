import { AppConfig } from 'modules/config';

import { HttpTransport, IHttpTransport } from './transport';
import { IdentityDomain, IIdentityDomain } from './domains/identity';
import { AnnalsDomain, IAnnalsDomain } from './domains/annals';
import { DialogsDomain, IDialogsDomain } from './domains/dialogs';
import { DialogsMessagesDomain, IDialogsMessagesDomain } from './domains/dialogs/messages';
import { OperatorsDomain, IOperatorsDomain } from './domains/operators';
import {
  IOperatorsFastAnswersDomain,
  OperatorsFastAnswersDomain,
} from './domains/operators/fast-answers';
import { IUsersDomain, UsersDomain } from './domains/users';
import { IUsersTagsDomain, UsersTagsDomain } from './domains/users/tags';
import { IUsersMediaDomain, UsersMediaDomain } from './domains/users/media';
import {
  DialogsMessagesAutomationDomain,
  IDialogsMessagesAutomationDomain,
} from './domains/dialogs/messages/automation';
import { IDialogsAudioDomain, DialogsAudioDomain } from './domains/dialogs/audio';
import { FeaturesDomain, IFeaturesDomain } from './domains/features';
import { EventsDomain, IEventsDomain } from './domains/events';
import { AnimatorsDomain, IAnimatorsDomain } from './domains/animators';
import { DialogsNotesDomain, IDialogsNotesDomain } from './domains/dialogs/notes';
import { DictionariesDomain, IDictionariesDomain } from './domains/dictionaries';
import { GiftsDomain, IGiftsDomain } from './domains/gifts';
import { IShardsDomain, ShardsDomain } from './domains/shards';
import { ISpotifyDomain, SpotifyDomain } from './domains/spotify';
import { DialogsMediaDomain, IDialogsMediaDomain } from './domains/dialogs/media';

export class Api {
  private readonly httpTransport: IHttpTransport;
  readonly host: string;
  readonly realm: string;

  readonly identity: IIdentityDomain;
  readonly annals: IAnnalsDomain;
  readonly dialogs: IDialogsDomain;
  readonly dialogsMessages: IDialogsMessagesDomain;
  readonly dialogsMessagesAutomation: IDialogsMessagesAutomationDomain;
  readonly dialogsAudio: IDialogsAudioDomain;
  readonly dialogsNotes: IDialogsNotesDomain;
  readonly dialogsMedia: IDialogsMediaDomain;
  readonly operators: IOperatorsDomain;
  readonly operatorsFastAnswers: IOperatorsFastAnswersDomain;
  readonly animators: IAnimatorsDomain;
  readonly users: IUsersDomain;
  readonly usersTags: IUsersTagsDomain;
  readonly usersMedia: IUsersMediaDomain;
  readonly features: IFeaturesDomain;
  readonly events: IEventsDomain;
  readonly dictionaries: IDictionariesDomain;
  readonly gifts: IGiftsDomain;
  readonly shards: IShardsDomain;
  readonly spotify: ISpotifyDomain;

  constructor(config: AppConfig) {
    this.host = 'https://' + config.api;
    this.realm = config.backendAlias;
    this.httpTransport = new HttpTransport(this.host);

    this.identity = new IdentityDomain(this.httpTransport);
    this.annals = new AnnalsDomain(this.httpTransport);
    this.dialogs = new DialogsDomain(this.httpTransport, this.realm);
    this.dialogsMessages = new DialogsMessagesDomain(this.httpTransport);
    this.dialogsMessagesAutomation = new DialogsMessagesAutomationDomain(
      this.httpTransport,
      this.realm,
    );
    this.dialogsAudio = new DialogsAudioDomain(this.httpTransport);
    this.dialogsNotes = new DialogsNotesDomain(this.httpTransport);
    this.dialogsMedia = new DialogsMediaDomain(this.httpTransport);
    this.operators = new OperatorsDomain(this.httpTransport, this.realm);
    this.operatorsFastAnswers = new OperatorsFastAnswersDomain(this.httpTransport, this.realm);
    this.animators = new AnimatorsDomain(this.httpTransport);
    this.users = new UsersDomain(this.httpTransport);
    this.usersTags = new UsersTagsDomain(this.httpTransport);
    this.usersMedia = new UsersMediaDomain(this.httpTransport);
    this.features = new FeaturesDomain(this.httpTransport);
    this.events = new EventsDomain(this.httpTransport);
    this.dictionaries = new DictionariesDomain(this.httpTransport, this.realm);
    this.gifts = new GiftsDomain(this.httpTransport, this.realm);
    this.shards = new ShardsDomain(this.httpTransport);
    this.spotify = new SpotifyDomain(this.httpTransport);
  }

  setAuthToken(token: string) {
    this.httpTransport.setAuthToken(token);
  }

  clearAuthToken() {
    this.httpTransport.clearAuthToken();
  }
}
