import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';

import { AuthActions } from 'modules/domain/auth/actions';
import { MultiChatReducer } from 'modules/domain/common/types';

import { FastAnswersActions, SetFastAnswersPayload } from './actions';
import { FastAnswersModelState } from './types';
import { namespace } from './constants';

type CommonPayload = SetFastAnswersPayload;

interface CustomReducerMap extends ReducerMap<FastAnswersModelState, CommonPayload> {
  [FastAnswersActions.SetFastAnswers]: ReducerMapValue<
    FastAnswersModelState,
    SetFastAnswersPayload
  >;
}

const initialState: FastAnswersModelState = {
  data: null,
};

const reducerMapping: CustomReducerMap = {
  [AuthActions.Logout]: state => update(state, { $set: initialState }),
  [FastAnswersActions.SetFastAnswers]: (state, { payload }) =>
    update(state, {
      $set: { data: payload.data },
    }),
};

export const reducer: MultiChatReducer<FastAnswersModelState, CommonPayload> = {
  [namespace]: handleActions(reducerMapping, initialState),
};
