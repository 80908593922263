import React, { ComponentProps } from 'react';

import { FastAnswersList } from 'modules/domain/fast-answers/types';

import { FastAnswersFormErrorValues, FastAnswersFormValues } from './types';
import View from './view';

type Props = {
  fastAnswers: FastAnswersList;
  onSubmit: (fastAnswers: FastAnswersList) => Promise<void>;
  onCancel: () => void;
};

const MIN_CHARACTERS = 20;

export const FastAnswerModalForm = (props: Props) => {
  const { fastAnswers, onSubmit, onCancel } = props;

  const handleSubmit: ComponentProps<typeof View>['onSubmit'] = async (
    values: FastAnswersFormValues,
  ) => {
    const updatedValues = fastAnswers.map(fastAnswer => ({
      ...fastAnswer,
      phrase: values[fastAnswer.shortcut].trim(),
    }));

    return onSubmit(updatedValues);
  };

  const validate = (values: FastAnswersFormValues) => {
    return fastAnswers.reduce((errors, fastAnswer) => {
      const value = values[fastAnswer.shortcut].replace(/\s\s+/g, ' ').trim();

      if (value.length > 0 && value.length < MIN_CHARACTERS) {
        errors[fastAnswer.shortcut] = `Min ${MIN_CHARACTERS} symbols`;
      }

      return errors;
    }, {} as FastAnswersFormErrorValues);
  };

  return (
    <View
      fastAnswers={fastAnswers}
      validate={validate}
      onSubmit={handleSubmit}
      onDismiss={onCancel}
    />
  );
};
