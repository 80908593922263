const getFormattedFileType = (fileType: string): string | null => {
  if (/image/.test(fileType)) {
    return 'photo';
  }

  return null;
};

export const getFormData = (files: File | File[]) => {
  const filesArray = Array.isArray(files) ? files : [files];
  const formData = new FormData();

  filesArray.forEach(file => {
    const formattedFileType = getFormattedFileType(file.type);

    if (formattedFileType) {
      formData.append(formattedFileType, new Blob([file], { type: file.type }), file.name);
    }
  });

  return formData;
};
