import { Dialog } from 'modules/domain/dialog/types';
import { useMarkLastMessageAsRead } from 'modules/domain/dialog/hooks';
import { useDialogMediaStatePoller } from 'modules/domain/media/hooks';
import { useGiftsLoader } from 'modules/domain/gifts/hooks';

import { useUnansweredUnpaidMessageLoader } from './use-unanswered-unpaid-message-loader';
import { useMessagesPoller } from './use-messages-poller';
import { useMessagesNextPageLoader } from './use-messages-next-page-loader';

/**
 * Contains all chat/dialog async services, like pollers, loaders, trackers etc.
 */
export const useChatServices = (currentDialog: Dialog) => {
  useGiftsLoader(currentDialog);
  useMessagesPoller(currentDialog.id);
  useMessagesNextPageLoader(currentDialog);
  useDialogMediaStatePoller(currentDialog);
  useMarkLastMessageAsRead(currentDialog);
  useUnansweredUnpaidMessageLoader(currentDialog);
};
