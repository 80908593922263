import { DialogMessageBaseDto } from 'modules/api/dto';
import { generateMessageTag } from 'modules/utils';

export const createBaseMessageDraft = (
  animatorId: string,
  attendeeId: string,
  text = '',
): Omit<DialogMessageBaseDto, 'meta'> => ({
  tag: generateMessageTag(animatorId),
  sender: animatorId,
  recipient: attendeeId,
  text,
  timestamp: new Date().getTime(),
});
