import moment from 'moment';

import { utcDate } from 'modules/utils';

export enum LabelName {
  VIP = 'VIP',
  FirstChat = 'FirstChat',
  Sexting = 'Sexting',
  CurrentTime = 'CurrentTime',
  FirstMessageDate = 'FirstMessageDate',
  Birthday = 'Birthday',
}

type LabelConfig = {
  text: string | ((templateParam: string | number) => string);
  tooltip?: string;
  backgroundColor: string;
  color?: string;
  testNodeId?: string;
};

export const labelsConfig: Record<LabelName, LabelConfig> = {
  [LabelName.VIP]: {
    text: 'vip',
    backgroundColor: '#ffd100',
    tooltip: "You're communicating with a VIP client. Be creative!",
    color: '#000000',
    testNodeId: 'vip-client-short-label',
  },
  [LabelName.FirstChat]: {
    text: 'first chat',
    tooltip:
      'This is the first chat for the User! Make it most involving and impressive so the User wants to use the app further more!',
    backgroundColor: '#9AFF98',
    color: '#000000',
  },
  [LabelName.Sexting]: {
    text: 'sexting',
    tooltip:
      'This user is available for sexting. If he starts sharing\nsexually explicit messages just keep the conversation going',
    backgroundColor: '#eb9cff',
    color: '#000000',
  },
  [LabelName.CurrentTime]: {
    text: timezone => utcDate().utcOffset(timezone).format('h a'),
    backgroundColor: '#2F3133',
    color: '#FFFFFF',
  },
  [LabelName.FirstMessageDate]: {
    text: firstDialogMessageTimeStamp =>
      `First Message: ${moment(firstDialogMessageTimeStamp).format('ll')}`,
    backgroundColor: '#2F3133',
    color: '#FFFFFF',
  },
  [LabelName.Birthday]: {
    text: birthdayDate => `Birthday: ${moment(birthdayDate).format('ll')}`,
    backgroundColor: '#2F3133',
    color: '#FFFFFF',
  },
};
