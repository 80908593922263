import React, { useState } from 'react';
import cn from 'classnames';

import { testId } from 'modules/utils/test-id';
import expandIcon from 'images/expand-icon.svg';

import styles from './styles.module.scss';

type Props = {
  title: string;
  content: React.ReactNode;
  id: string;
  isAnimator: boolean;
};

const STORAGE_KEY_PREFIX = 'notes_expanded_';

export const AccordionSection = (props: Props) => {
  const { title, content, id, isAnimator } = props;

  const storageKey = `${STORAGE_KEY_PREFIX}_${isAnimator ? 'animator' : 'client'}_${id}`;

  const [expanded, setExpanded] = useState<boolean>(() => {
    const storageItem = sessionStorage.getItem(storageKey);
    return storageItem ? JSON.parse(storageItem) : id === 'notes';
  });

  const handleHeaderClick = () => {
    setExpanded(prevExpanded => {
      const newExpanded = !prevExpanded;

      sessionStorage.setItem(storageKey, newExpanded.toString());

      return newExpanded;
    });
  };

  return (
    <div className={styles.accordionSection}>
      <div className={styles.accordionHeader} onClick={handleHeaderClick}>
        <div className={styles.caption} {...testId(`accordion-title-${id}`)}>
          {title}
        </div>
        <img src={expandIcon} alt="expand" className={cn({ [styles.expandedIcon]: expanded })} />
      </div>
      {expanded && (
        <div {...testId(`accordion-body-${id}`)} className={styles.accordionContent}>
          {content}
        </div>
      )}
    </div>
  );
};
