import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';

import { apiUserToUser } from '../user-adapter';
import { actions } from '../actions';

export const useUserApi = () => {
  const dispatch = useDispatch();
  const { users: usersApi } = useApi();
  const { logError } = useLogger('useUserApi');

  const fetchUserData = useCallback(
    async (userId: string) => {
      try {
        const userData = await usersApi.getUserData(userId, true);

        const user = apiUserToUser(userData);
        dispatch(actions.setUser(user));
        return user;
      } catch (error) {
        logError(`fetchUserData error. userId: ${userId}`, { error });
      }
    },
    [dispatch, usersApi, logError],
  );

  return {
    fetchUserData,
  };
};
