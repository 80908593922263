export type DictionaryDto = Record<string, string>;

export enum DictionaryName {
  Interests = 'interests',
  Educations = 'educations-v2',
  Languages = 'languages',
  Relationships = 'relationships',
  Smoke = 'smoke',
  Drinking = 'drinking-v2',
  BodyType = 'bodytype',
  Eyes = 'eyes',
  Hairs = 'hairs',
  CommunicationPurposes = 'users/communication-purposes',
}
