import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';

import { MultiChatReducer } from 'modules/domain/common/types';

import { SpotifyMatchActions, SetMatchResultPayload } from './actions';
import { namespace, SpotifyMatchState } from './types';

type CommonPayload = SetMatchResultPayload;

type SpotifyReducerMapValue<P> = ReducerMapValue<SpotifyMatchState, P>;

interface CustomReducerMap extends ReducerMap<SpotifyMatchState, CommonPayload> {
  [SpotifyMatchActions.SetMatchResult]: SpotifyReducerMapValue<SetMatchResultPayload>;
}

const initialState: SpotifyMatchState = {};

const reducerMapping: CustomReducerMap = {
  [SpotifyMatchActions.SetMatchResult]: (state, { payload }) =>
    update(state, {
      [payload.dialogId]: {
        $set: payload.result,
      },
    }),
};

export const reducer: MultiChatReducer<SpotifyMatchState, CommonPayload> = {
  [namespace]: handleActions(reducerMapping, initialState),
};
