import { useCallback, useEffect, useRef } from 'react';

import { useConfig } from 'modules/config';
import { Dialog } from 'modules/domain/dialog/types';
import { useDialogMessagesApi } from 'modules/domain/dialog/hooks';
import { useChatScrollerContext } from 'modules/components/chat/chat-scroller-context';

export const useMessagesNextPageLoader = (currentDialog: Dialog) => {
  const { id: currentDialogId, messages } = currentDialog;
  const messagesCount = messages.length;

  const { chatPageSize } = useConfig();
  const { fetchDialogMessages } = useDialogMessagesApi(currentDialogId);
  const { scrollPosition } = useChatScrollerContext();

  const isTopPosition = scrollPosition === 'top';
  const isTopPositionPrev = useRef(isTopPosition);

  const loadNextChatPage = useCallback(() => {
    fetchDialogMessages(chatPageSize, messagesCount);
  }, [fetchDialogMessages, messagesCount, chatPageSize]);

  useEffect(() => {
    if (isTopPosition && !isTopPositionPrev.current) {
      loadNextChatPage();
    }
    isTopPositionPrev.current = isTopPosition;
  }, [loadNextChatPage, isTopPosition]);
};
