import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';
import { useDispatchAction } from 'modules/hooks/redux';

import { mapOperatorCommissionDto, mapOperatorDto } from '../adapter';
import {
  setOperatorInfo as setOperatorInfoAction,
  setOperatorCommissionInfo as setOperatorCommissionInfoAction,
} from '../model';

export const useOperatorApi = () => {
  const { operators: operatorsApi } = useApi();
  const { logError } = useLogger('useOperatorApi');

  const setOperatorInfo = useDispatchAction(setOperatorInfoAction);
  const setOperatorCommissionInfo = useDispatchAction(setOperatorCommissionInfoAction);

  const sendActivityStatus = useCallback(
    async (operatorId: string) => {
      try {
        await operatorsApi.sendActivityStatus(operatorId);
      } catch (error) {
        logError('sendActivityStatus error', { error });
      }
    },
    [operatorsApi, logError],
  );

  const loadOperatorInfo = useCallback(
    async (operatorId: string) => {
      try {
        const operatorDto = await operatorsApi.getOperator(operatorId);

        const operator = mapOperatorDto(operatorDto);
        setOperatorInfo(operator);
      } catch (error) {
        logError('Fetch operator info error', { error });
      }
    },
    [operatorsApi, logError, setOperatorInfo],
  );

  const loadOperatorCommissionInfo = useCallback(
    async (operatorId: string) => {
      try {
        const commissionDto = await operatorsApi.getOperatorCommission(operatorId);

        const commission = mapOperatorCommissionDto(commissionDto);
        setOperatorCommissionInfo(commission);
      } catch (error) {
        logError('Fetch operator commission error', { error });
      }
    },
    [operatorsApi, logError, setOperatorCommissionInfo],
  );

  return {
    sendActivityStatus,
    loadOperatorInfo,
    loadOperatorCommissionInfo,
  };
};
