import React, { ComponentProps, memo, useCallback, useMemo } from 'react';

import { SearchFormValues } from './types';
import View from './view';
import { STORAGE_KEY_ANIMATOR, STORAGE_KEY_ATTENDEE } from './constants';

type ViewProps = ComponentProps<typeof View>;

type Props = {
  onSubmit: (attendeeId: string, animatorId: string) => void;
  onClickClose: () => void;
};

export const DialogsSearchForm = memo((props: Props) => {
  const { onSubmit, onClickClose } = props;

  const initialFormState: SearchFormValues = useMemo(
    () => ({
      attendeeId: localStorage.getItem(STORAGE_KEY_ATTENDEE) || '',
      animatorId: localStorage.getItem(STORAGE_KEY_ANIMATOR) || '',
    }),
    [],
  );

  const validate = useCallback((values: SearchFormValues) => {
    const errors: Partial<SearchFormValues> = {};
    if (!values.attendeeId) {
      errors.attendeeId = 'Required';
    }

    if (!values.animatorId) {
      errors.animatorId = 'Required';
    }

    return errors;
  }, []);

  const handleSubmit: ViewProps['handleSubmit'] = useCallback(
    async (values, { setSubmitting }) => {
      const { attendeeId, animatorId } = values;

      onSubmit(attendeeId, animatorId);

      setSubmitting(false);
    },
    [onSubmit],
  );

  return (
    <View
      initialFormState={initialFormState}
      validate={validate}
      handleSubmit={handleSubmit}
      onClickClose={onClickClose}
    />
  );
});
