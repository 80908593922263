import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';

import { actions } from '../actions';

export const useUserInterestsApi = () => {
  const dispatch = useDispatch();
  const { users: usersApi } = useApi();
  const { logError } = useLogger('useUserInterestsApi');

  const fetchUserInterests = useCallback(
    async (userId: string) => {
      try {
        const interests = await usersApi.getUserInterests(userId);
        dispatch(actions.setUserInterests(userId, interests));
      } catch (error) {
        logError('fetchUserInterests error', { userId, error });
      }
    },
    [usersApi, dispatch, logError],
  );

  return {
    fetchUserInterests,
  };
};
