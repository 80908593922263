import { useEffect } from 'react';

export type PollerFn = (stop: () => void) => any;

export const usePoller = (fn: PollerFn, interval: number) => {
  useEffect(() => {
    let intervalTimerId: number | undefined = undefined;

    const stopPoller = () => clearInterval(intervalTimerId);
    const poll = () => fn(stopPoller);

    // todo good enough for now, we can replace it by the poller from some library if needed
    // there might be a race condition, when the request takes more than the interval
    // it is ok for now to have slightly stale data
    intervalTimerId = window.setInterval(poll, interval);

    poll();

    return stopPoller;
  }, [fn, interval]);
};
