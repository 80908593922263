import { getCurrentDialogId, getFoundDialogId } from 'modules/domain/dialog/selectors';
import { useRootSelector } from 'modules/hooks/redux';

export const useCurrentDialogId = () => {
  // TODO: fix the type inside the store. It shouldn't store an empty string
  const currentDialogId = useRootSelector<string>(state => getCurrentDialogId(state));
  const foundDialogId = useRootSelector<string | null>(state => getFoundDialogId(state));

  return foundDialogId || currentDialogId;
};
