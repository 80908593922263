import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { ModalModel } from 'modules/domain/modals';
import { ModalNames } from 'modules/domain/modals/types';
import { DialogProps } from 'modules/domain/complaint/types';
import { FormValues } from 'modules/components/dialogs/send-complaint/form';
import { getDialogId, useLogger, toast } from 'modules/utils';
import { useOperatorId } from 'modules/domain/auth';

export const useComplaint = () => {
  const operatorId = useOperatorId();
  const { annals } = useApi();
  const { openModal: openModalBase, closeModal } = ModalModel.hooks.useModal();
  const { activeModalData } = ModalModel.hooks.useSelectors<DialogProps>();
  const { logError } = useLogger('dialog notes');

  const openModal = useCallback(
    async (data: DialogProps) => {
      openModalBase(ModalNames.SendComplaint, data);
    },
    [openModalBase],
  );

  const sendReport = useCallback(
    async (values: FormValues) => {
      if (!activeModalData || !values.complaint) {
        logError('There is no values.complaint or activeModalData is empty', {
          values,
        });
        return;
      }
      const animatorId = activeModalData?.outgoing
        ? activeModalData.senderId
        : activeModalData?.recipientId;
      const clientId = activeModalData?.outgoing
        ? activeModalData.recipientId
        : activeModalData?.senderId;
      const reason = values.complaint !== 'Other' ? values.complaint : values.userText;

      const payload = {
        'user-id': clientId,
        'animator-id': animatorId,
        'operator-id': operatorId,
        'message-from': activeModalData.senderId,
        message: typeof activeModalData.content === 'string' ? activeModalData.content : '',
        reason: reason,
        content: `/multichat/dialogs/messages/${getDialogId(animatorId, clientId)}/message/${
          activeModalData.id
        }`,
      };

      try {
        await annals.send(operatorId, 'user-report', payload);
        closeModal();
        toast.showMessage('Your report has been sent.');
      } catch (error: any) {
        logError(error.message, { values, payload, stack: error.stack });
      }
    },
    [activeModalData, annals, closeModal, logError, operatorId],
  );

  return {
    sendReport,
    openModal,
  };
};
