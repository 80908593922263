import { useMemo } from 'react';

import { UserTargetedTag } from 'modules/api/dto';
import { useAttendeeTargetedTags } from 'modules/domain/users/hooks';

export const useIsAttendeeFirstChat = (attendeeId: string, animatorId: string) => {
  const tags = useAttendeeTargetedTags(attendeeId, animatorId);

  const isFirstChat = useMemo(() => tags.includes(UserTargetedTag.DialogsFirstChat), [tags]);

  return isFirstChat;
};
