import React, {
  ChangeEvent,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useDialogLatestMessage, useDialogTypedMessage } from 'modules/domain/dialog/hooks';
import { MediaSelectorButton } from 'modules/components/chat/media-selector/button';
import { SmilePicker } from 'modules/components/common';
import { GiftsPicker } from 'modules/components/gift-picker';
import { useDialogMediaEnabled } from 'modules/domain/media/hooks';
import { useAudioMessagesAvailable, useIcebreakersEnabled } from 'modules/domain/features';
import { getDialogId, testId } from 'modules/utils';
import { AudioSelectorButton } from 'modules/components/chat/audio/selector/button';
import { IcebreakerPicker } from 'modules/components/icebreaker-picker';

import { TypedMessageIndicator } from './typed-message-indicator';
import { uncapsTheLastWord } from './utils';
import {
  useSendTextMessage,
  useCopilotOptionClicked,
  useTypedMessageIndicator,
  useChatInputTypingActions,
} from './hooks';
import styles from './index.module.scss';

type Props = {
  animatorId: string;
  attendeeId: string;
  someMessagesLoaded: boolean;
};

const SEND_BTN_KEY = 'Enter';

export const ChatForm = memo((props: Props) => {
  const { animatorId, attendeeId, someMessagesLoaded } = props;

  const [smilesPickerIsVisible, setSmilesPickerVisible] = useState(false);

  const dialogId = getDialogId(animatorId, attendeeId);

  const {
    typedTextMessage,
    setTypedTextMessage,
    setAdditionalMessagePart,
    setIsDialogInputFocused,
  } = useDialogTypedMessage();
  const latestMessage = useDialogLatestMessage(dialogId);
  const indicator = useTypedMessageIndicator(typedTextMessage, latestMessage);
  const mediaInChatEnabled = useDialogMediaEnabled(dialogId);
  const icebreakersEnabled = useIcebreakersEnabled(dialogId);
  const audioMessagesEnabled = useAudioMessagesAvailable();
  const handleTypingChange = useChatInputTypingActions(dialogId, SEND_BTN_KEY);
  const sendTextMessage = useSendTextMessage(animatorId, attendeeId, indicator.status);

  useEffect(() => {
    setTypedTextMessage('');
  }, [dialogId, setTypedTextMessage]);

  const copilotOptionClicked = useCopilotOptionClicked(typedTextMessage);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (someMessagesLoaded || copilotOptionClicked) {
      inputRef.current?.focus();
    }
  }, [someMessagesLoaded, copilotOptionClicked]);

  const handleEnterPress = () => {
    const trimmedMessage = typedTextMessage.trim();

    if (trimmedMessage) {
      const success = sendTextMessage(trimmedMessage);

      if (success) {
        setTypedTextMessage('');
      }
    }
  };

  const handleInputKeyPress = (
    event: KeyboardEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>,
  ) => {
    handleTypingChange(event);

    /*
     * It is kinda redundant for the current UI, the smiles popup will be closed on losing focus anyway
     * However, it could make sense if UI changes
     * */
    setSmilesPickerVisible(false);

    switch (event.key) {
      case ' ':
        setTypedTextMessage(uncapsTheLastWord(typedTextMessage));
        break;

      case 'Enter':
        handleEnterPress();
        break;
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTypedTextMessage(event.target.value);
  };

  const onSmileSelect = useCallback(
    (smile: string) => {
      setAdditionalMessagePart(smile);
    },
    [setAdditionalMessagePart],
  );

  const toggleSmilesVisibility = useCallback(
    (visible: boolean) => setSmilesPickerVisible(!visible),
    [],
  );

  return (
    <div className={styles.container}>
      <SmilePicker
        isVisible={smilesPickerIsVisible}
        onSelect={onSmileSelect}
        onToggleVisibility={toggleSmilesVisibility}
      />
      <input
        {...testId('send-message-input')}
        ref={inputRef}
        className={styles.input}
        type="text"
        placeholder="Type a message..."
        onKeyPress={handleInputKeyPress}
        onChange={handleInputChange}
        onFocus={() => setIsDialogInputFocused(true)}
        onBlur={() => setIsDialogInputFocused(false)}
        value={typedTextMessage}
      />
      <div className={styles.buttons}>
        {audioMessagesEnabled && <AudioSelectorButton dialogId={dialogId} />}
        <GiftsPicker dialogId={dialogId} />
        {mediaInChatEnabled && <MediaSelectorButton dialogId={dialogId} />}
        {icebreakersEnabled && <IcebreakerPicker dialogId={dialogId} />}
      </div>
      <TypedMessageIndicator status={indicator.status} percent={indicator.percent} />
    </div>
  );
});
