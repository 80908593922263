import { useCallback } from 'react';

import { GiftDto } from 'modules/api/dto';

import { isInPriceLimit } from '../helpers';

import { useGiftsApi } from './use-gifts-api';
import { useGiftsPriceLimitChecker } from './use-gifts-price-limit-checker';

export const useSendGift = (dialogId: string) => {
  const { sendGift: apiSendGift } = useGiftsApi(dialogId);

  const checkPriceLimit = useGiftsPriceLimitChecker(dialogId);

  const sendGift = useCallback(
    async (gift: GiftDto) => {
      const priceLimit = await checkPriceLimit();

      if (!isInPriceLimit(gift, priceLimit)) {
        return false;
      }

      const success = await apiSendGift(gift);

      if (success) {
        // TODO: a temporary solution until we don't have events logic implemented.
        // Operator won't be able to send another gift anyway.
        // It's needed to refresh gifts state almost immediately not relying on a poller
        setTimeout(checkPriceLimit, 1000);
      }

      return success;
    },
    [apiSendGift, checkPriceLimit],
  );

  return sendGift;
};
