import React, { memo } from 'react';

import { AccordionTabs } from 'modules/components/accordion-tabs';
import { UserChatRole } from 'modules/domain/users/types';

import { UserLabels } from './labels';
import styles from './index.module.scss';

type Props = {
  type: UserChatRole;
  animatorId: string;
  attendeeId: string;
};

export const UserPanel = memo(function UserPanel(props: Props) {
  const { animatorId, attendeeId, type } = props;

  return (
    <div className={styles.container}>
      <div className={styles.userContainer}>
        <UserLabels animatorId={animatorId} attendeeId={attendeeId} type={type} />
      </div>
      <AccordionTabs animatorId={animatorId} attendeeId={attendeeId} type={type} />
    </div>
  );
});
