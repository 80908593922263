import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PollerFn, usePoller } from 'modules/hooks';
import { useConfig } from 'modules/config';
import { Dialog } from 'modules/domain/dialog/types';

import { resetDialogMediaState } from '../actions';
import { checkMediaAllowed } from '../helpers';

import { useDialogMediaEnabled } from './use-selectors';
import { useDialogMediaApi } from './use-dialog-media-api';

export const useDialogMediaStatePoller = (currentDialog: Dialog) => {
  const { id: dialogId } = currentDialog;

  const dispatch = useDispatch();
  const { loadDialogMediaState } = useDialogMediaApi();
  const { chatMediaStatePollingInterval } = useConfig();

  const allowed = checkMediaAllowed(currentDialog);
  const enabled = useDialogMediaEnabled(dialogId);

  // No need to poll media state if the media is enabled/available in the dialog
  const shouldPoll = allowed && !enabled;

  const checkChatMediaState = useCallback<PollerFn>(
    async stopPoller => {
      if (!shouldPoll) {
        stopPoller();

        return;
      }

      // keep checking state until the chat media enabled
      loadDialogMediaState(dialogId);
    },
    [dialogId, shouldPoll, loadDialogMediaState],
  );

  usePoller(checkChatMediaState, chatMediaStatePollingInterval);

  useEffect(
    () => () => {
      // reset the dialog media state when leaving the dialog
      dispatch(resetDialogMediaState(dialogId));
    },
    [dispatch, dialogId],
  );
};
