import { UserGender } from 'modules/api/dto';
import fallbackMaleImage from 'images/fallback-male.svg';
import fallbackFemaleImage from 'images/fallback-female.svg';
import fallbackUnknownImage from 'images/fallback-unknown-gender.svg';
import fallbackNonImage from 'images/fallback-non.svg';

export const getUserFallbackImage = (gender: UserGender | undefined) => {
  if (!gender) {
    return fallbackUnknownImage;
  }

  switch (gender) {
    case 'mal':
      return fallbackMaleImage;
    case 'fem':
      return fallbackFemaleImage;
    default:
      return fallbackNonImage;
  }
};
