import React, { memo, useCallback } from 'react';

import { testId } from 'modules/utils';
import { useDialogExplicitStatus } from 'modules/domain/dialog/hooks';
import { useOperatorInfo } from 'modules/domain/operator/hooks';

import styles from './styles.module.scss';

type Props = {
  status: boolean;
  attendeeId: string;
  animatorId: string;
};

export const ExplicitBanner = memo((props: Props) => {
  const { status, animatorId, attendeeId } = props;
  const { setDialogExplicitStatus } = useDialogExplicitStatus();
  const { isReadyForExplicit } = useOperatorInfo() || {};

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDialogExplicitStatus(event.target.checked, animatorId, attendeeId);
    },
    [animatorId, attendeeId, setDialogExplicitStatus],
  );

  if (isReadyForExplicit && !status) {
    return null;
  }

  return (
    <div {...testId('explicit-banner-container')} className={styles.container}>
      <label className={styles.label}>
        <input className={styles.input} type="checkbox" checked={status} onChange={onChange} />
        <span>Explicit chat</span>
      </label>
    </div>
  );
});
