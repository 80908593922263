import { AudioFile } from './audio-file';
import { AudioEvent, IAudioFile, IAudioPlayer, IPlayerControls, LoadFileFn } from './contracts';
import { PlayerControls } from './player-controls';

export class AudioPlayer implements IAudioPlayer {
  private static instance: IAudioPlayer;

  static shared() {
    if (!this.instance) {
      this.instance = new AudioPlayer();
    }
    return this.instance;
  }

  private controls: IPlayerControls = new PlayerControls();
  private audioFiles: Record<string, IAudioFile> = {};

  private constructor() {
    //
  }

  addAudio(basename: string, loadFile: LoadFileFn): IAudioFile {
    if (!this.audioFiles[basename]) {
      const audioFile = new AudioFile(basename, loadFile, this.controls);

      audioFile.addEventListener(AudioEvent.PlayPressed, () => {
        this.onFilePlayPressed(basename);
      });

      this.audioFiles[basename] = audioFile;
    }
    return this.audioFiles[basename];
  }

  private onFilePlayPressed = (basename: string) => {
    Object.keys(this.audioFiles).forEach(otherFileBasename => {
      if (basename !== otherFileBasename) {
        // stop other files since there can be only one file playing at a time
        this.audioFiles[otherFileBasename].stop();
      }
    });
  };
}
