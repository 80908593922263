import { useGetPhotoUrl } from 'modules/hooks';

export type ImageParams = {
  basename: string;
  recipient: string;
  sender: string;
  size?: number;
};

export const useImageUri = (params: ImageParams) => {
  const { basename: basenameParam, recipient, sender, size } = params;
  const getPhotoUrl = useGetPhotoUrl();

  const basename = basenameParam.startsWith('/')
    ? basenameParam
    : `/dialogs/usermedia/${recipient}/${sender}/${basenameParam}`;

  return getPhotoUrl({ baseUrl: basename, isPrivate: true, size });
};
