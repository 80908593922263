import { DialogMessage } from 'modules/domain/dialog/types';
import { DialogMessageMetaDto } from 'modules/api/dto';

export type ClientComplaints =
  | 'Disappointed with the product'
  | 'Insulted an animator'
  | 'Reported a technical issue'
  | 'User is a scammer'
  | 'Other';

export type AnimatorComplaints =
  | 'Poor grammar'
  | 'Inappropriate content'
  | 'Out of context'
  | 'Suggestion to meet'
  | 'Contact details'
  | 'Other';

export enum ComplaintOn {
  Animator,
  Client,
}

export type Report =
  | { complaintOn: ComplaintOn.Animator; complaints: AnimatorComplaints[] }
  | { complaintOn: ComplaintOn.Client; complaints: ClientComplaints[] };

export type ReportPayload = {
  'user-id': string;
  'animator-id': string;
  'operator-id': string;
  'message-from': string;
  message: string;
  reason: ClientComplaints | AnimatorComplaints | string;
  content: string;
};

export type DialogProps = Pick<
  DialogMessage,
  'outgoing' | 'recipientId' | 'senderId' | 'content' | 'id'
> & {
  meta?: DialogMessageMetaDto;
};
