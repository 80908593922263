import React, { memo } from 'react';

import { ImageOverlay } from 'modules/components/image-overlay';
import { testId } from 'modules/utils';
import { Image } from 'modules/components/image';

import { useUserTabPhotos } from './use-user-tab-photos';
import styles from './index.module.scss';

type Props = {
  ownerId: string;
};

export const AccordionTabPhotos = memo((props: Props) => {
  const { ownerId } = props;

  const { privatePhotos, privateOverlays, publicPhotos, publicOverlays } =
    useUserTabPhotos(ownerId);

  const renderPhotos = (type: 'public' | 'private') => {
    const isPublic = type === 'public';

    const photos = isPublic ? publicPhotos : privatePhotos;
    const overlays = isPublic ? publicOverlays : privateOverlays;
    const title = isPublic ? 'Photos' : 'Private Photos';

    if (!photos.length) {
      return null;
    }

    return (
      <div className={styles.photosBlock}>
        <div {...testId('user-tab-photo-block-title')} className={styles.title}>
          {title}
        </div>
        <div {...testId('user-tab-photo-block-gallery')} className={styles.photosGrid}>
          {photos.map(({ id, url }, i) => {
            return (
              <ImageOverlay selectedItem={i} key={id} src={overlays}>
                <Image a11yLabel={`gallery-photo-${id}`} src={url} alt={id} />
              </ImageOverlay>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div {...testId(`user-tab-photo-${ownerId}`)} className={styles.containerInner}>
        {renderPhotos('public')}
        {renderPhotos('private')}
      </div>
    </div>
  );
});
