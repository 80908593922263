import { useCallback, useMemo } from 'react';
import useWebSocket from 'react-use-websocket';

import { useShardsApi } from 'modules/domain/shards/hooks';
import { useConfig } from 'modules/config';
import { useOperatorId } from 'modules/domain/auth';

import { ApiEventsType, EventDto } from '../dto';
import { WsEventDto } from '../ws/dto';

const parseWsMessage = (messageData: string) => {
  const parsedData = JSON.parse(messageData) as EventDto;
  const type = (parsedData.label || parsedData.type) as ApiEventsType;
  const payload = parsedData.payload || parsedData.details;

  return { type, payload } as WsEventDto;
};

export const useWsApi = () => {
  const operatorId = useOperatorId();
  const { getShards } = useShardsApi();
  const { sharding: shardingConfig } = useConfig();

  const getSocketUrl = useCallback(async () => {
    const { shard, key } = await getShards(operatorId);

    return `wss://rt${shard}.${shardingConfig.host}/${key}`;
  }, [operatorId, getShards, shardingConfig.host]);

  const { lastMessage } = useWebSocket(getSocketUrl, {
    shouldReconnect: () => true,
  });

  const parsedLastMessage = useMemo(() => {
    if (lastMessage?.data) {
      return parseWsMessage(lastMessage.data);
    }
    return null;
  }, [lastMessage?.data]);

  return { lastMessage: parsedLastMessage };
};
