import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ModalNames } from 'modules/domain/modals/types';

import * as actions from '../actions';

export const useModal = () => {
  const dispatch = useDispatch();

  const openModal = useCallback(
    <T>(modalName: ModalNames, data?: T) => {
      dispatch(actions.openModal(modalName, data));
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    dispatch(actions.closeModal());
  }, [dispatch]);

  return {
    openModal,
    closeModal,
  };
};
