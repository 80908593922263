import { UserMedia, DialogMediaState } from './types';

export enum MediaActions {
  SetUserPhotos = 'media.set-user-photos',
  SetDialogMediaState = 'media.set-dialog-media-state',
  ResetDialogMediaState = 'media.reset-dialog-media-state',
}

export type SetUserPhotosPayload = { userId: string; photos: UserMedia[] };

export type SetUserPhotosAction = { type: MediaActions; payload: SetUserPhotosPayload };

export function setUserPhotos(userId: string, photos: UserMedia[]): SetUserPhotosAction {
  return { type: MediaActions.SetUserPhotos, payload: { userId, photos } };
}

export type SetDialogMediaStatePayload = {
  dialogId: string;
  input: DialogMediaState;
};

export type SetDialogMediaStateAction = { type: MediaActions; payload: SetDialogMediaStatePayload };

export function setDialogMediaState(
  dialogId: string,
  input: DialogMediaState,
): SetDialogMediaStateAction {
  return { type: MediaActions.SetDialogMediaState, payload: { dialogId, input } };
}

export type ResetDialogMediaStatePayload = {
  dialogId: string;
};

export type ResetDialogMediaStateAction = {
  type: MediaActions;
  payload: ResetDialogMediaStatePayload;
};

export function resetDialogMediaState(dialogId: string): ResetDialogMediaStateAction {
  return { type: MediaActions.ResetDialogMediaState, payload: { dialogId } };
}
