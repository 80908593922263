import { RootState } from 'modules/App/store/root-state';

import { SpotifyMatchResult, SpotifyMatchState, namespace } from './types';

const getSpotifyMatchState = (state: RootState): SpotifyMatchState => state[namespace];

export const getSpotifyMatchResult = (
  state: RootState,
  dialogId: string,
): SpotifyMatchResult | undefined => getSpotifyMatchState(state)[dialogId];
