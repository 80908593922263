import { IHttpTransport } from 'modules/api/transport';
import { calculateProgress } from 'modules/api/utils';

import { IDialogsMediaDomain } from './contracts';
import { DialogMediaStateDto, UploadDialogMediaResponse } from './dto';
import { getFormData } from './helpers';

export class DialogsMediaDomain implements IDialogsMediaDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getDialogMediaBaseUrl(recipientId: string, senderId: string): string {
    return `/dialogs/usermedia/${recipientId}/${senderId}`;
  }

  getDialogMediaState(animatorId: string, attendeeId: string): Promise<DialogMediaStateDto> {
    return this.transport
      .get<DialogMediaStateDto>(`/multichat/dialogs/${animatorId}/${attendeeId}/media/allowed`)
      .then(response => response.data);
  }

  async uploadDialogMedia(
    animatorId: string,
    attendeeId: string,
    file: File,
    onUploadProgress?: (progress: number) => void,
  ): Promise<string> {
    const { data: dto } = await this.transport.post<UploadDialogMediaResponse>(
      this.getDialogMediaBaseUrl(attendeeId, animatorId),
      getFormData(file),
      {
        onUploadProgress: event => {
          onUploadProgress?.(calculateProgress(event));
        },
      },
    );

    // @ts-expect-error just parsing dto, don't want to deal with types much
    const basename = dto.basename || (dto.basenames || dto)[0];

    return basename;
  }
}
