import { getDialogId, getTimestamp } from 'modules/utils';
import { DialogQueueType, DialogsMetricsDto, DialogState } from 'modules/api/dto';

import { Dialog, Metrics, DialogMessagesAllowed } from '../types';

export const mapDialogsDtoToDialogs = (response: DialogsMetricsDto): Dialog[] | null => {
  const dialogs = response.Dialogs;

  if (!dialogs.length) {
    return null;
  }

  return dialogs.map(dto => {
    const animatorId = dto.Animator.SiteId;
    const attendeeId = dto.Client.SiteId;
    const id = getDialogId(animatorId, attendeeId);

    const dialog: Dialog = {
      serverId: dto.Id,
      id,
      animatorId,
      attendeeId,
      messages: [],
      answered: dto.State === DialogState.UnansweredMessageNew,
      unansweredUnpaid: {
        hasMessages: dto.QueueTypeId === DialogQueueType.Blue,
      },
      messagesAllowed:
        dto.QueueTypeId === DialogQueueType.Grey
          ? DialogMessagesAllowed.TextAndAudio
          : DialogMessagesAllowed.All,
      // the `dto.Timestamp` is actually an ISO formatted date string
      timestamp: getTimestamp(dto.Timestamp),
      isExplicit: dto.IsExplicit || false,
      isExplicitPossibleForClient: dto.ExplicitPossibleForClient || false,
      chatStartTimestamp: dto.ChatStartTimestamp,
    };

    return dialog;
  });
};

export const mapDialogsDtoToMetrics = (response: DialogsMetricsDto): Metrics => {
  return {
    unansweredCount: response?.Unanswered?.Count || 0,
    urgentUnansweredCount: response?.UrgentUnanswered?.Count || 0,
    urgentUnansweredPeriod: response?.UrgentUnanswered?.Period || 0,
  };
};
