import React, { memo } from 'react';

import { Image } from 'modules/components/image';
import { ImageOverlay } from 'modules/components/image-overlay';
import { useUserThumbnailUrl } from 'modules/hooks';
import styles from 'modules/components/user/user-panel/index.module.scss';

import { useUserPhotosOverlays } from './use-avatar-overlays-urls';

export type Props = {
  userId: string;
};

export const Avatar = memo((props: Props) => {
  const { userId } = props;

  const { url: avatarUrl, fallback, alt } = useUserThumbnailUrl(userId);

  const overlays = useUserPhotosOverlays(userId);

  return (
    <ImageOverlay src={overlays}>
      <Image
        a11yLabel={`user-avatar-${userId}`}
        className={styles.thumbnail}
        fallback={fallback}
        src={avatarUrl}
        alt={alt}
      />
    </ImageOverlay>
  );
});
