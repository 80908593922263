import React, { memo } from 'react';
import cn from 'classnames';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

import icon from 'images/icon-smile.svg';
import { testId } from 'modules/utils/test-id';
import { useClickOutside } from 'modules/domain/common/hooks';

import styles from './index.module.scss';

type Emoji = {
  native: string;
};

type Props = {
  onSelect: (emoji: string) => void;
  isVisible: boolean;
  onToggleVisibility: (currentVisibility: boolean) => void;
};

export const SmilePicker = memo((props: Props) => {
  const { isVisible, onSelect, onToggleVisibility } = props;

  const wrapperRef = useClickOutside<HTMLDivElement>({
    condition: isVisible,
    action: () => onToggleVisibility(true),
  });

  const handleEmojiSelect = (emoji: Emoji) => {
    onSelect(emoji.native);
  };

  return (
    <div className={styles.container} ref={wrapperRef}>
      <div className={styles.button}>
        <img
          {...testId('show-smile-picker-button')}
          src={icon}
          alt=""
          onClick={() => onToggleVisibility(isVisible)}
        />
      </div>
      <div
        {...testId('smile-picker-container')}
        className={cn(styles.modal, { hidden: !isVisible })}
      >
        <Picker theme="light" data={data} onEmojiSelect={handleEmojiSelect} />
      </div>
    </div>
  );
});
