import { useEffect } from 'react';

import { Dialog } from 'modules/domain/dialog/types';
import { useDialogMessagesApi } from 'modules/domain/dialog/hooks';

export const useUnansweredUnpaidMessageLoader = (currentDialog: Dialog) => {
  const { unansweredUnpaid, id: currentDialogId } = currentDialog;

  const { fetchUnansweredUnpaidDialogMessage } = useDialogMessagesApi(currentDialogId);

  const needToLoadUnansweredUnpaidMessage =
    unansweredUnpaid?.hasMessages && !unansweredUnpaid.messageLoaded;

  useEffect(() => {
    if (needToLoadUnansweredUnpaidMessage) {
      fetchUnansweredUnpaidDialogMessage();
    }
  }, [fetchUnansweredUnpaidDialogMessage, needToLoadUnansweredUnpaidMessage]);
};
