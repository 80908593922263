import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addLogoutReducer } from 'modules/domain/auth/actions';

import { DatesRange, EarningsInfo, EarningsModelState, KpiFullInfo } from './types';

type SetEarningsAction = PayloadAction<{
  earnings: EarningsInfo;
  range: DatesRange;
}>;

type SetKpiAction = PayloadAction<{
  kpi: KpiFullInfo;
  range: DatesRange;
}>;

const initialState: EarningsModelState = {};

export const earningsModel = createSlice({
  name: 'earnings',
  initialState,
  reducers: {
    setEarnings: (state, { payload: { earnings, range } }: SetEarningsAction) => {
      state[range] = {
        ...state[range],
        earnings,
      };
    },
    setKpi: (state, { payload: { kpi, range } }: SetKpiAction) => {
      state[range] = {
        ...state[range],
        kpi,
      };
    },
  },
  extraReducers: addLogoutReducer(() => initialState),
  selectors: {
    getEarnings: (state, range: DatesRange) => state[range]?.earnings,
    getKpi: (state, range: DatesRange) => state[range]?.kpi,
  },
});
