import { AppConfig } from 'modules/config';
import { HttpTransport } from 'modules/api';

import { getShardUri } from './sharding';

export type GetPhotoUrlParams = {
  shardingConfig: AppConfig['sharding'];
  baseUrl: string;
  size?: number;
  token?: string;
};

export const getAuthQuery = (token: string) => {
  const authHeader = HttpTransport.getAuthHeader(token);
  return `?authorization=${encodeURIComponent(authHeader)}`;
};

export const getPhotoUrl = (params: GetPhotoUrlParams) => {
  const { baseUrl, size, token, shardingConfig } = params;

  let url = baseUrl;

  if (typeof size === 'number') {
    url += `.${size}x${size}.thumb-fd`;
  }

  if (token) {
    url += getAuthQuery(token);
  }

  return getShardUri(url, shardingConfig);
};
