import React, { memo } from 'react';

import { useUserField } from 'modules/domain/users/hooks';

import { LabelName } from '../label-item/constants';
import { LabelItemProps, LabelsList } from '../labels-list';

type Props = {
  animatorId: string;
};

export const AnimatorLabels = memo((props: Props) => {
  const { animatorId } = props;

  const animatorBirthday = useUserField(animatorId, 'birthday');

  const animatorLabels: LabelItemProps[] = [
    {
      id: LabelName.Birthday,
      enabled: !!animatorBirthday,
      textTemplateParam: animatorBirthday,
    },
  ];

  return <LabelsList labels={animatorLabels} />;
});
