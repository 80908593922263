import { Entity } from 'modules/domain/common/types/entity';
import { AnimatorPreferencesDto, DialogMessageDto } from 'modules/api/dto';

import { AudioMessageTemplate } from '../audio';

import { SearchedDialogMessagesState } from './dialog-message';
import { DialogNotes } from './notes';

export type DialogMediaMessage = {
  pending: boolean;
  error?: string;
  progress?: number;
};
export type DialogMediaMessages = Record<string, DialogMediaMessage>;

export type UnansweredUnpaidDialogState = {
  hasMessages?: boolean;
  messageLoaded?: boolean;
  messageAnswered?: boolean;
};

export enum DialogMessagesAllowed {
  All = 'all',
  TextAndAudio = 'TextAndAudio',
}

export type Dialog = Entity & {
  serverId: number; // an original dialog's server id (needed to fetch next dialogs properly)
  animatorId: string;
  attendeeId: string;
  messages: DialogMessageDto[];
  mediaMessages?: DialogMediaMessages;
  answered: boolean;
  unansweredUnpaid?: UnansweredUnpaidDialogState;
  messagesAllowed: DialogMessagesAllowed;
  timestamp: number;
  animatorStoryTold?: boolean;
  notes?: DialogNotes;
  animatorPreferences?: AnimatorPreferencesDto;
  searchMode?: boolean;
  searchedMessages?: SearchedDialogMessagesState;
  isExplicit: boolean;
  isExplicitPossibleForClient: boolean;
  chatStartTimestamp?: string;
  someMessagesLoaded?: boolean;
  allMessagesLoaded?: boolean;
  audioMessages?: AudioMessageTemplate[];
};

export type Metrics = {
  unansweredCount: number;
  urgentUnansweredCount: number;
  urgentUnansweredPeriod: number;
};
