import React from 'react';

import { useCurrentDialogId } from 'modules/domain/dialog/hooks';
import { NextChatButton } from 'modules/components/chat/next-chat-button';
import { FastAnswers } from 'modules/components/fast-answers';

import styles from './index.module.scss';

export const MultichatFooter = () => {
  const currentDialogId = useCurrentDialogId();

  const fastAnswersVisible = !!currentDialogId;

  return (
    <div className={styles.container}>
      {fastAnswersVisible && <FastAnswers />}
      <NextChatButton />
    </div>
  );
};
