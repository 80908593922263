import { Dictionary } from './model';

export const interestsDictionaryAddition: Dictionary = {
  bi: 'Bi',
  'bi-curious': 'Bi-curious',
  daddy: 'Daddy',
  discreet: 'Discreet',
  fem: 'Fem',
  gay: 'Gay',
  jock: 'Jock',
  leather: 'Leather',
  masc: 'Masc',
  'non-binary': 'Non-binary',
  straight: 'Straight',
  student: 'Student',
  trans: 'Trans',
};
