import { useEffect } from 'react';

export function useWindowEventHandler<T extends keyof WindowEventMap>(
  type: T,
  handler: (event: WindowEventMap[T]) => void,
) {
  useEffect(() => {
    window.addEventListener(type, handler);

    return () => {
      window.removeEventListener(type, handler);
    };
  }, [type, handler]);
}
