import React, { useCallback } from 'react';
import isEqual from 'lodash/isEqual';

import { useCurrentDialogId, useDialogNotes, useDialogNotesApi } from 'modules/domain/dialog/hooks';
import { DialogNotes, DialogNotesList } from 'modules/domain/dialog/types';
import { useConfig } from 'modules/config';

import View from './view';

type Props = {
  type: keyof DialogNotes;
};

export const AccordionTabNotes = (props: Props) => {
  const { type } = props;

  const { updateDialogNotes } = useDialogNotesApi();
  const dialogId = useCurrentDialogId();
  const notes = useDialogNotes(dialogId);
  const { maxNotesInTopic } = useConfig();

  const userNotesList = notes?.[type];

  const onSave = useCallback(
    (notesList: DialogNotesList) => {
      if (!isEqual(notesList, userNotesList)) {
        updateDialogNotes(dialogId, { ...notes!, [type]: notesList });
      }
    },
    [dialogId, notes, userNotesList, type, updateDialogNotes],
  );

  if (!userNotesList) {
    return null;
  }

  return <View notes={userNotesList} onSave={onSave} maxNotesInTopic={maxNotesInTopic} />;
};
