import { createContext } from 'react';

import { AuthStorage } from './types';

export type AuthContextType = {
  auth: AuthStorage;
  setAuthInfo: (id: string, email: string, token: string, isPermanent?: boolean) => void;
  clearAuthInfo: () => void;
};

const initialValue = {
  auth: {
    id: undefined,
    email: undefined,
    token: undefined,
    appName: undefined,
  },
  setAuthInfo: () => {},
  clearAuthInfo: () => {},
};

export const AuthContext = createContext<AuthContextType>(initialValue);
