import { EarningsInfo } from 'modules/domain/earnings/model';
import {
  calculateTotalBonusIncome,
  calculateTotalIncome,
  countAllBonuses,
} from 'modules/domain/earnings/utils';

type IncomeData = {
  repliesNumber: number;
  repliesPayment: number;
  benefitsNumber: number;
  benefitsPayment: number;
  kpiEffectPayment: number;
  totalIncome: number;
  penalties: number;
};

export const calculateIncomeData = (earnings: EarningsInfo | undefined): IncomeData => {
  if (!earnings) {
    return {
      repliesNumber: 0,
      repliesPayment: 0,
      benefitsNumber: 0,
      benefitsPayment: 0,
      kpiEffectPayment: 0,
      totalIncome: 0,
      penalties: 0,
    };
  }

  const totalIncome = calculateTotalIncome(earnings);
  const benefitsPayment = calculateTotalBonusIncome(earnings);
  const penalties = earnings.trapPenalties?.totalBonusValue || 0;
  const kpiEffectPayment = earnings.repliesPaymentWithKpi - earnings.repliesPayment;

  return {
    repliesNumber: earnings.replies,
    repliesPayment: earnings.repliesPayment,
    benefitsNumber: countAllBonuses(earnings),
    benefitsPayment,
    kpiEffectPayment,
    totalIncome,
    penalties,
  };
};
