import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { IShardsDomain } from './contracts';
import { ShardsDto } from './dto';

export class ShardsDomain implements IShardsDomain {
  constructor(private readonly transport: IHttpTransport) {}

  getShards(userId: string): Promise<ShardsDto> {
    return this.transport.get<ShardsDto>(`/shards/${userId}`).then(response => response.data);
  }
}
