import { useEffect, useState } from 'react';

import { useConfig } from 'modules/config';
import { HttpTransport } from 'modules/api';
import { useLogger } from 'modules/utils';

type AnimationData = object;

const transport = new HttpTransport('');

export const useAnimationData = (animationPath: string) => {
  const { api: apiHost } = useConfig();
  const { logError } = useLogger('useAnimationData');

  const [animationData, setAnimationData] = useState<AnimationData | null>(null);

  useEffect(() => {
    transport
      .get<AnimationData>(`//${apiHost}${animationPath}`)
      .then(response => setAnimationData(response.data))
      .catch(error => {
        logError('Failed to get animated gift animation json', { error, animationPath });
      });
  }, [animationPath, apiHost, logError]);

  return animationData;
};
