import {
  DialogSentMediaDto,
  DialogSentMediaType,
  UserMediaDto,
  UserMediaTags,
} from 'modules/api/dto';

import { UserMedia, DialogMedia } from './types';

const mapUserMediaDto = (dto: UserMediaDto): UserMedia => ({
  basename: dto.basename,
  isPrivate: dto.tags.includes(UserMediaTags.Private),
});

export const mapUserMediaListDto = (dto: UserMediaDto[]): UserMedia[] => dto.map(mapUserMediaDto);

const getUserChatPhotoBaseUrl = (userPhotosUrl: string, basename: string) =>
  `${userPhotosUrl}/chat/file/${basename}`;

export const mapChatMediaListDto = (userPhotosUrl: string, media: string[]): DialogMedia[] => {
  return media.map(basename => ({
    basename,
    baseUrl: getUserChatPhotoBaseUrl(userPhotosUrl, basename),
  }));
};

export const mapSentMediaListDto = (
  userPhotosUrl: string,
  dialogMediaUrl: string,
  sentMedia: DialogSentMediaDto[],
): DialogMedia[] => {
  return sentMedia.map(sentMediaItem => {
    const { baseName, type } = sentMediaItem;

    const baseUrl =
      type === DialogSentMediaType.chat
        ? getUserChatPhotoBaseUrl(userPhotosUrl, baseName)
        : `${dialogMediaUrl}/${baseName}`;

    return {
      basename: baseName,
      baseUrl,
    };
  });
};
