import { createContext } from 'react';

import { AppConfig } from 'modules/config';
import { Api } from 'modules/api';

export type AppContextType = {
  config: AppConfig;
  api: Api;
};

// proper values will be set during the context initialization
const initialValue: AppContextType = {
  config: {} as AppConfig,
  api: {} as Api,
};

export const AppContext = createContext<AppContextType>(initialValue);

export const AppContextProvider = AppContext.Provider;
