import { DialogActions } from './common';

export type SwitchDialogPayload = { id: string };
export type SwitchDialogAction = { type: DialogActions.SwitchDialog; payload: SwitchDialogPayload };

export function switchDialog(id: string): SwitchDialogAction {
  return { type: DialogActions.SwitchDialog, payload: { id } };
}

export type SetFoundDialogPayload = { id: string };
export type SetFoundDialogAction = {
  type: DialogActions.SetFoundDialog;
  payload: SetFoundDialogPayload;
};

export function setFoundDialog(id: string): SetFoundDialogAction {
  return { type: DialogActions.SetFoundDialog, payload: { id } };
}

export type ResetFoundDialogAction = { type: DialogActions.ResetFoundDialog };

export function resetFoundDialog(): ResetFoundDialogAction {
  return { type: DialogActions.ResetFoundDialog };
}

export const entityActions = {
  switchDialog,
  setFoundDialog,
  resetFoundDialog,
};
