import React, { memo, useMemo } from 'react';

import { DialogMessage } from 'modules/domain/dialog/types';
import { MessageRow } from 'modules/components/chat';
import { useDialogMessagesSearchState } from 'modules/domain/dialog/hooks';

import styles from './styles.module.scss';

type Props = {
  messages: DialogMessage[];
  currentDialogId: string;
};

export const ChatMessagesList = memo((props: Props) => {
  const { messages, currentDialogId } = props;

  const {
    messages: searchedMessages,
    searchedPhrase,
    currentSearchedMessageIndex = 0,
  } = useDialogMessagesSearchState(currentDialogId) || {};
  const currentSearchedMessage = searchedMessages?.[currentSearchedMessageIndex];

  const lastReadOutgoingMessage = useMemo(
    () => messages.find(message => message.outgoing && message.read),
    [messages],
  );

  return (
    <div className={styles.messagesContainer}>
      {messages.map((message: DialogMessage) => {
        const isSearchedMessage = searchedMessages?.some(m => m.messageId === message.id);
        const highlightedText = isSearchedMessage ? searchedPhrase : undefined;
        const isInFocus =
          !!currentSearchedMessage?.messageId && currentSearchedMessage.messageId === message.id;

        return (
          <MessageRow
            key={message.tag}
            {...message}
            isLastReadOutgoingMessage={message.tag === lastReadOutgoingMessage?.tag}
            highlight={highlightedText}
            inFocus={isInFocus}
            dialogId={currentDialogId}
          />
        );
      })}
    </div>
  );
});
