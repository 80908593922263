import React, { useState, useCallback, Ref } from 'react';
import cn from 'classnames';

import purpurPickerIcon from 'images/purpur-picker-icon.svg';
import purpurLogo from 'images/purpur-logo-big.svg';
import purpurImageGeneral from 'images/purpur-image-general.svg';
import purpurImageErotic from 'images/purpur-image-erotic.svg';
import { testId } from 'modules/utils';
import { DialogModel } from 'modules/domain/dialog';
import { useClickOutside } from 'modules/domain/common/hooks';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { IconButton } from 'modules/components/common/button';
import { IcebreakerType } from 'modules/api/dto';

import styles from './index.module.css';

type Props = {
  dialogId: string;
  btnRef?: Ref<HTMLButtonElement>;
  btnClassName?: string;
  modalClassName?: string;
};

export const IcebreakerPicker = (props: Props) => {
  const { dialogId, btnRef, btnClassName, modalClassName } = props;

  const [pickerOpen, setPickerOpen] = useState(false);

  const { sendMessage } = DialogModel.hooks.useDialogMessagesApi(dialogId);

  const pickerModalRef = useClickOutside<HTMLDivElement>({
    condition: pickerOpen,
    action: () => setPickerOpen(false),
  });

  const onPickIcebreaker = useCallback(
    (icebreakerType: IcebreakerType) => {
      setPickerOpen(false);

      sendMessage({
        text: 'Sent you a PurPur Card',
        meta: { icebreakerType },
      }).catch(() => {
        showChatErrorToast(
          'Failed to send a PurPur Card. Please check your connection and try again later',
        );
      });
    },
    [sendMessage],
  );

  return (
    <div className={styles.pickerContainer}>
      {pickerOpen && (
        <div className={cn(styles.tooltip, modalClassName)} ref={pickerModalRef}>
          <div className={styles.title}>
            Let&apos;s play <img src={purpurLogo} alt="purpur" />
          </div>
          <div className={styles.subtitle}>
            Send a PurPur Card to ask your partner a question and answer yourself once you get their
            reply
          </div>
          <div className={styles.images}>
            <div className={styles.eroticCard} onClick={() => onPickIcebreaker('erotic')}>
              <img
                className={cn(styles.purpurImage, styles.eroticImage)}
                src={purpurImageErotic}
                alt="erotic"
              />
            </div>
            <div className={styles.generalCard} onClick={() => onPickIcebreaker('general')}>
              <img
                className={cn(styles.purpurImage, styles.generalImage)}
                src={purpurImageGeneral}
                alt="general"
              />
            </div>
          </div>
          <div className={styles.legend}>
            <div>
              Spicy
              <br />
              questions
            </div>
            <div>
              Everything
              <br />
              questions
            </div>
          </div>
        </div>
      )}
      <IconButton
        ref={btnRef}
        className={btnClassName}
        {...testId('icebreaker-picker')}
        onClick={() => setPickerOpen(pickerOpen => !pickerOpen)}
      >
        <img src={purpurPickerIcon} />
      </IconButton>
    </div>
  );
};
