import React, { memo } from 'react';

import scrollToBottomIcon from 'images/icon-scroll-to-bottom.svg';
import { RoundButton } from 'modules/components/common/button/round';
import { useChatScrollerContext } from 'modules/components/chat/chat-scroller-context';

import styles from './styles.module.scss';

export const ScrollToBottomButton = memo(() => {
  const { scrollPosition, scrollToBottom } = useChatScrollerContext();

  if (scrollPosition === 'bottom') {
    return null;
  }

  return (
    <RoundButton className={styles.button} size="sm" theme="darker" onClick={scrollToBottom}>
      <img src={scrollToBottomIcon} alt="" />
    </RoundButton>
  );
});
