import { createContext, RefObject } from 'react';

export type ScrollPosition = 'bottom' | 'top' | 'middle';

export type ChatScrollerActions = {
  scrollToBottom: () => void;
  scrollToMessage: (messageId: number) => void;
};

export type ChatScrollerState = {
  scrollPosition: ScrollPosition;
  firstInvisibleMessageId: number | null;
};

export type ChatScroller = ChatScrollerActions &
  ChatScrollerState & {
    setScrollContainerRef: (ref: HTMLDivElement | null) => void;
    setScrollContentRef: (ref: HTMLDivElement | null) => void;
    setMessageRef: (messageId: number) => RefObject<HTMLDivElement>;
  };

// proper values will be set during the context initialization
const initialValue: ChatScroller = {
  firstInvisibleMessageId: null,
  isBottomPosition: true,
  isTopPosition: false,
  scrollToBottom: () => {},
  scrollToMessage: () => {},
  setScrollContainerRef: () => {},
  setScrollContentRef: () => {},
  // @ts-expect-error no need to fix types here
  setMessageRef: () => {},
};

export const ChatScrollerContext = createContext<ChatScroller>(initialValue);
